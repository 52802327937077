import { Button, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useGetAccountDeletions } from 'hooks/useGetAccountDeletions';
import { useRequestAccountDeletion } from 'hooks/useRequestAccountDeletion';
import { AuthedLayout } from 'layouts/authed';

export function DeleteAccountPage() {
    const deletions = useGetAccountDeletions();
    const requestDeletion = useRequestAccountDeletion();

    const submit = async () => {
        await requestDeletion.mutateAsync();
        await deletions.refetch();
        notifications.show({
            title: 'Success!',
            message: 'Your request has been sent to our team.',
            color: 'green.8'
        });
    };

    const requestInProgress = deletions?.data?.[0];

    return (
        <AuthedLayout showBack showHeader headerText="Delete Account">
            <Text>
                By law, we are required to retain data relating to our users and certain prescribed actions with our
                systems.
            </Text>
            <Text>
                You may request to delete your account and associated data held by Easy Manage at any time. We will
                action your account deletion as soon as reasonably possible, and will remove all data that we are not
                required by law to retain.
            </Text>
            <Text>
                Once you submit your request, we will contact you within 3 business days to confirm removal of your
                data.
            </Text>
            {requestInProgress && (
                <Text fw="bold">
                    We are processing your request to delete your account, made on{' '}
                    {new Date(requestInProgress.created_at).toLocaleDateString()}. We will contact you soon.
                </Text>
            )}
            <Button loading={requestDeletion.isPending} onClick={submit} disabled={!!requestInProgress}>
                {requestInProgress ? 'We are processing your request' : 'Confirm account deletion request'}
            </Button>
        </AuthedLayout>
    );
}
