import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { RootLayout } from 'layouts/root';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { supabase } from 'utils/supabase';
import { z } from 'zod';

const schema = z.object({
    email: z.string()
});

type Schema = z.infer<typeof schema>;

export function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState, reset } = useForm<Schema>({
        resolver: zodResolver(schema)
    });

    const onSubmit = async (form: Schema) => {
        const { email } = form;
        const { data, error } = await supabase.auth.resetPasswordForEmail(email);

        if (!error) {
            // notifications.show({
            //     title: 'Success!',
            //     message: 'Check your email for further instructions.',
            //     color: 'green.8',
            // });

            // reset();

            navigate(routes.resetPassword, { state: { email } });
        } else {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red.8'
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <RootLayout showBack>
                <Text ta="center">
                    Enter your email address below. If you have an Easy Manage account, we will send you an email to
                    reset your password.
                </Text>
                <TextInput {...register('email')} label="Email address" placeholder="you@email.com" />
                <Button type="submit" mt="lg" loading={formState.isSubmitting}>
                    Submit
                </Button>
            </RootLayout>
        </form>
    );
}
