import { useQuery } from '@tanstack/react-query';
import { supabase } from 'utils/supabase';

export function useGetTenantAccounts(propertyId: number | null) {
    const query = async () => {
        if (!propertyId) {
            return null;
        }

        const { data } = await supabase
            .from('tenant_account')
            .select('*, tenancy(*)')
            .eq('tenancy.property_id', propertyId);
        return data;
    };

    return useQuery({ queryKey: ['tenant_accounts', propertyId], queryFn: query });
}
