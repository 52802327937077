import { css } from '@emotion/react';
import { Container, Stack, Text, rem } from '@mantine/core';
import { Tex } from 'tabler-icons-react';

interface Props {
    icon: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
}

export function SummaryTile(props: Props) {
    const { icon, title, subtitle } = props;

    return (
        <Stack
            css={css`
                flex: 1;
                border: 1px solid #453366;
                background-color: #241a33;
                border-radius: ${rem(8)};
                gap: ${rem(4)};
            `}
            p="md"
        >
            <div
                css={css`
                    color: white;
                `}
            >
                {icon}
            </div>
            <Text fw="bold">{title}</Text>
            <Text size="sm" color="#A694C7">
                {subtitle}
            </Text>
        </Stack>
    );
}
