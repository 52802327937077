import { TextInput, Divider } from '@mantine/core';
import { IconMinus } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';

interface Props {
    index: number;
    onRemove?: (index: number) => void;
    showRemove?: boolean;
}

export function AddTenantForm(props: Props) {
    const { index, onRemove, showRemove } = props;

    return (
        <>
            <TextInput label={`Tenant ${index + 1} - First name`} placeholder="John" />
            <TextInput label={`Tenant ${index + 1} - Last name`} placeholder="Smith" />
            <TextInput
                label={`Tenant ${index + 1} - Email address or Phone number`}
                placeholder="johnsmith@gmail.com"
            />
            {showRemove && (
                <>
                    <SummaryPill
                        small
                        iconLeft={<IconMinus />}
                        title="Remove tenant"
                        onClick={() => onRemove?.(index)}
                    />
                    <Divider w="50%" />
                </>
            )}
        </>
    );
}
