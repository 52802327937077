import { css } from '@emotion/react';
import { RootLayout } from 'layouts/root';
import { supabase } from 'utils/supabase';
import { useEffect } from 'react';
import { MessageType, registerListener } from 'utils/webview';
import { useThemeStore } from 'store/zustand';
import { Session } from '@supabase/supabase-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { SignInForm } from './sign-in';

type Msg = {
    session: Session;
};

export function SignInPage() {
    const [setOAuthSession, setUserId] = useThemeStore((state) => [state.setOAuthSession, state.setCurrentUserId]);
    const navigate = useNavigate();
    const location = useLocation();
    const showBack = location.state?.showBack;

    useEffect(() => {
        const setSupaSess = async (msg: Msg) => {
            console.log('sess from native', msg);

            const res = await supabase.auth.setSession({
                access_token: msg.session.access_token,
                refresh_token: msg.session.refresh_token
            });

            setUserId(res.data.user?.id ?? null);
            // const res = await supabase.auth.getUser(msg.session.access_token)

            console.log('supa manual sess res', res);

            navigate(routes.carousel.dashboard);
        };

        registerListener(MessageType.oauth, (msg: Msg) => {
            try {
                if (msg.session) {
                    setSupaSess(msg);
                }

                setOAuthSession(msg.session);
            } catch (error) {
                console.log(error);
            }
        });
    }, [navigate, setOAuthSession, setUserId]);

    const isNativeApp = window.emConfig.isNative;
    // const isNativeApp = false;

    return (
        <RootLayout showBack={showBack}>
            <SignInForm />
            <div
                css={css`
                    text-align: center;
                    font-size: 0.75rem;
                    position: absolute;
                    bottom: 0px;
                `}
            >
                <pre>
                    [{import.meta.env.VITE_GIT_SHA}-{import.meta.env.VITE_STAGE}][
                    {window.emConfig.isNative
                        ? `${window.emConfig.device?.nativeAppVersion}-${window.emConfig.device?.nativeBuildVersion}-${window.emConfig.device?.easEnv}`
                        : 'not_native'}
                    ]
                </pre>
            </div>
        </RootLayout>
    );
}
