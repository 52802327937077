import { css } from '@emotion/react';

export function FullWidthImage(props: React.ImgHTMLAttributes<HTMLImageElement>) {
    return (
        <img
            // straight up I have no idea why this works
            css={css`
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;

                object-fit: cover;
            `}
            {...props}
        />
    );
}
