import { Textarea } from '@mantine/core';

interface Props {
    placeholder?: string;
    onChange: (val: string) => void;
    value: string | undefined;
}

export function ChatInput(props: Props) {
    const { placeholder, onChange, value } = props;

    return (
        <Textarea
            w="100%"
            placeholder={placeholder ?? 'Start typing...'}
            autosize
            minRows={1}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            id="chat-textarea"
        />
    );
}
