import { css } from '@emotion/react';
import { FileButton, Flex, Text } from '@mantine/core';

interface Props {
    icon: React.ReactNode;
    children: React.ReactNode;
}

export function DocumentUploadPill(props: Props) {
    const { children, icon } = props;

    return (
        <FileButton onChange={console.log}>
            {(fileButtonProps) => (
                <Flex justify="flex-start" align="center" gap="md" onClick={fileButtonProps.onClick}>
                    <div
                        css={css`
                            color: white;
                            padding: 8px;
                            background-color: #302447;
                            height: 3rem;
                            width: 3rem;
                            border-radius: 8px;

                            svg {
                                height: 100%;
                                width: 100%;
                                stroke-width: 1;
                            }
                        `}
                    >
                        {icon}
                    </div>
                    <Text>{children}</Text>
                </Flex>
            )}
        </FileButton>
    );
}
