import { Route, Routes } from 'react-router-dom';
import { chatSubroutes } from './routes';
import { ChatMessaging } from './messaging';
import { NewMessage } from './new-message';

export function Chat() {
    return (
        <Routes>
            <Route
                path={`${chatSubroutes.messaging}/*`}
                element={<ChatMessaging />}
            />
            <Route
                path={chatSubroutes.new}
                element={<NewMessage />}
            />
        </Routes>
    )
}