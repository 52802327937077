import { Stack, Text } from '@mantine/core';
import { TestButton } from 'components/TestButton';
import { format } from 'date-fns';
import { useSupabaseConversations } from 'hooks/useSupabaseConversations';
import { ChatRow, ChatRowTitle, ChatRowBody } from 'pages/Chat/ChatRow';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';

export function AllChats() {
    const navigate = useNavigate();
    const supaConvos = useSupabaseConversations();

    return (
        <Stack>
            <TestButton onClick={() => navigate(`${routes.tasks.messaging}/123`)}>Go to chat screen</TestButton>
            {supaConvos?.map((c) => (
                <ChatRow
                    image={<></>}
                    chatId={String(c.id)}
                    time={
                        c.tenancy_conversation_messages?.[0]?.created_at
                            ? format(new Date(c.tenancy_conversation_messages?.[0]?.created_at), 'h:mm aaa')
                            : ''
                    }
                >
                    <ChatRowTitle>Chat for tenancy [{c.tenancy_id}]</ChatRowTitle>
                    <ChatRowBody>{c.tenancy_conversation_messages?.[0]?.contents}</ChatRowBody>
                </ChatRow>
            ))}
            {supaConvos?.length === 0 && (
                <Text ta="center">
                    No chats available.
                    <br />
                    <br />
                    Chats will appear automatically once you have a lease in place.
                </Text>
            )}
        </Stack>
    );
}
