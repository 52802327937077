import { css } from '@emotion/react';
import { Container, Stack } from '@mantine/core';
import easymanageLogo from 'assets/Logo-Purple-Full.svg';
import { AppHeader } from 'components/AppHeader';
import { useWatchAuth } from 'hooks/useWatchAuth';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';

interface Props {
    children: React.ReactNode;
    showBack?: boolean;
    overrideBackLocation?: string;
    onHeaderClick?: () => void;
    headerText?: React.ReactNode;
}

export function RootLayout(props: Props) {
    const { children, showBack, overrideBackLocation, onHeaderClick, headerText } = props;
    const navigate = useNavigate();

    useWatchAuth();

    return (
        <Stack
            justify="flex-start"
            align="center"
            w="100%"
            css={css`
                height: 100%;
                overflow: auto;
            `}
        >
            <AppHeader
                overrideBackLocation={overrideBackLocation}
                showBack={showBack}
                onClick={() => onHeaderClick?.()}
            >
                {headerText ?? (
                    <Container h="3.5rem" p="0.5rem">
                        <img
                            src={easymanageLogo}
                            css={css`
                                height: 100%;
                                min-height: 100%;
                                width: 100%;
                            `}
                        />
                    </Container>
                )}
            </AppHeader>

            <Stack p="sm" spacing="lg" w="100%" justify="center" align="center">
                {children}
            </Stack>
        </Stack>
    );
}
