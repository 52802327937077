import { Divider, Title } from '@mantine/core';
import { SummaryPill, SummaryPillImage } from 'components/Onboarding/SummaryPill';
import { AuthedLayout } from 'layouts/authed';
import PipeImg from 'assets/tenant-dashboard/pipe.jpeg';
import { IconChevronRight } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { useGetMaintenanceRequests } from 'hooks/tenants/useGetMaintenanceRequests';

export function TenantMaintenance() {
    const navigate = useNavigate();
    const { data } = useGetMaintenanceRequests();
    const location = useLocation();
    const tenancyId = location.pathname.split('/').pop();

    return (
        <AuthedLayout showHeader headerText="Maintenance Tracker">
            <SummaryPill
                title="Submit new issue"
                onClick={() => navigate(`${routes.tenant.newMaintenance}/${tenancyId}`)}
            >
                <IconChevronRight />
            </SummaryPill>
            <Divider />
            <Title size="h3">Active issues</Title>
            {data?.map((r) => (
                <SummaryPill
                    title={r.title}
                    subtitle={`Submitted ${new Date(r.created_at).toLocaleDateString()}`}
                    onClick={() => navigate(`${routes.tenant.maintenanceDetailed}/${r.id}`)}
                >
                    <SummaryPillImage>{PipeImg}</SummaryPillImage>
                </SummaryPill>
            ))}
        </AuthedLayout>
    );
}
