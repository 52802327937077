import { Button, Title, Text, Grid } from '@mantine/core';
import { AuthedLayout } from 'layouts/authed';
import house3 from 'assets/house3.jpeg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { PropertySummary } from 'pages/Properties/components/PropertySummary';
import NoPropertiesBannerImage from 'assets/pikaso_texttoimage_modern-flat-A-panoramic-shot-of-a-middleaged-woman.jpeg';
import { FullWidthImage } from 'components/FullWidthImage';
import { useState } from 'react';
import { FAB } from 'components/FAB';
import { IconPlus } from '@tabler/icons-react';
import { css } from '@emotion/react';
import { useGetAllProperties } from 'hooks/useGetAllProperties';

export function CarouselProperties() {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetAllProperties();
    const [showNoProps, setShowNoProps] = useState(false);

    const handleClick = (propertyId: string) => () => {
        navigate({
            pathname: routes.properties.detailed,
            search: createSearchParams({
                propertyId
            }).toString()
        });
    };

    const handleAdd = () => {
        navigate(routes.ownerOnboarding.address);
    };

    return (
        <AuthedLayout showHeader={true} headerText="Your properties" onHeaderClick={() => setShowNoProps((s) => !s)}>
            {(data?.length === 0 || showNoProps) && (
                <>
                    <FullWidthImage
                        src={NoPropertiesBannerImage}
                        css={css`
                            height: 25vh;
                        `}
                    />
                    <Title ta="center">Welcome to the landlord experience</Title>
                    <Text ta="center">
                        Start by adding your first property and we'll guide you through the process. You can also invite
                        tenants, set up payments, and more.
                    </Text>
                    <Button onClick={() => navigate(routes.ownerOnboarding.address)}>Add your first property</Button>
                </>
            )}
            <Grid>
                {!showNoProps &&
                    data?.map((prop) => {
                        if (!prop) {
                            return;
                        }

                        const { id, streetName, suburb, state, niceName } = prop;

                        return (
                            <Grid.Col span={6}>
                                <PropertySummary
                                    key={id}
                                    imageUrl={house3}
                                    addressLine1={streetName}
                                    addressLine2={`${suburb}, ${state.toUpperCase()}`}
                                    income="$12,345"
                                    expenses="$321"
                                    label={niceName ?? ''}
                                    onClick={handleClick(String(id))}
                                />
                            </Grid.Col>
                        );
                    })}
            </Grid>
            {!showNoProps && Number(data?.length) > 0 && (
                <FAB onClick={handleAdd}>
                    <IconPlus />
                </FAB>
            )}
            {!showNoProps && data?.length !== 0 && import.meta.env.VITE_STAGE !== 'prod' && (
                <Text ta="center" size="sm">
                    [TEST MODE ONLY]
                    <br />
                    Tap the heading at the top of the page to toggle between this screen and 'no-properties' screen
                </Text>
            )}
        </AuthedLayout>
    );
}
