import { supabase } from 'utils/supabase';
import { useUserId } from './useUserId';
import { useMutation } from '@tanstack/react-query';

interface Args {
    optIn: boolean;
}

export function useSetMarketingStatus() {
    const userId = useUserId();

    const req = async ({ optIn }: Args) => {
        if (!userId) {
            return;
        }

        const { data } = await supabase
            .from('user_updateable')
            .update({ marketing_opt_in: optIn })
            .eq('user_id', userId);

        return data;
    };

    return useMutation({ mutationFn: req });
}
