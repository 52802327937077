import { Stack, Title, Text, TitleProps, TextProps } from '@mantine/core';

export function OnboardingHeaderTitle(props: TitleProps) {
    return props.children ? <Title order={1} size="h2" mt="sm" {...props} /> : null;
}

export function OnboardingHeaderSubtitle(props: TextProps) {
    return props.children ? <Text {...props} /> : null;
}

export function OnboardingHeader(props: React.PropsWithChildren) {
    const { children } = props;

    return <Stack>{children}</Stack>;
}
