import { css } from '@emotion/react';
import { Button, Text, Title } from '@mantine/core';
import { AuthedLayout } from 'layouts/authed';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { supabase } from 'utils/supabase';

export function AccountNotEnabledPage() {
    const navigate = useNavigate();

    const onLogout = () => {
        supabase.auth.signOut();
        navigate(routes.signIn);
    };

    return (
        <AuthedLayout>
            <Title ta="center">Thanks for signing up!</Title>
            <Text ta="center">Your account is pending approval and will be activated soon.</Text>
            <Text ta="center">
                We appreciate your patience and will notify you once everything is set up and ready to go. Stay tuned!
            </Text>
            <Text ta="center">
                Any questions?
                <br />
                Email us at{' '}
                <a
                    href="mailto:support@easymanage.com.au"
                    css={css`
                        color: #85d0ff;
                    `}
                >
                    support@easymanage.com.au
                </a>
            </Text>
            <Button mt="lg" variant="secondary" onClick={onLogout}>
                Log out
            </Button>
        </AuthedLayout>
    );
}
