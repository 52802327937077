import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { useThemeStore } from 'store/zustand';

export function BottomNavBorder() {
    const theme = useMantineTheme();
    const emTheme = useThemeStore((state) => state.emTheme);

    const bg = useMemo(() => {
        if (emTheme === 'light') {
            return css`
                background-color: ${theme.colors.blue[3]};
            `;
        } else if (emTheme === 'dark') {
            return css`
                background-color: #3d3d3d;
            `;
        } else if (emTheme === 'dark-plus') {
            return css`
                background-color: #7c259e;
            `;
        }
    }, [emTheme, theme.colors.blue]);

    return (
        <div
            className="menu-border"
            css={css`
                height: 2rem;
                width: 1px;
                ${bg}
                position: absolute;
                right: 0;
                z-index: 1200;
            `}
        />
    );
}
