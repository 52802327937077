import { css } from '@emotion/react';
import { Carousel, Embla } from '@mantine/carousel';
import { BottomNav } from 'components/BottomNav';
import { ManageHome } from 'pages/ManageHome';
import { Dashboard } from 'pages/Dashboard';
import { Settings } from 'pages/Settings';
import { useEffect, useMemo, useState } from 'react';
import { routes } from 'routes/router';
import { throttle } from 'throttle-debounce';
import { CarouselProperties } from './properties';
import { useLocation, useNavigate } from 'react-router-dom';
import { CarouselTasks } from './tasks';
import { MantineTheme, useMantineTheme } from '@mantine/core';
import { IconTimeline, IconChecks, IconStack2, IconSettings } from '@tabler/icons-react';
import { Home } from 'tabler-icons-react';

const makeCarouselSlideStyles = (theme: MantineTheme) => css`
    /* min-height: calc(100vh); */
    min-height: calc(100vh - ${theme.other.bottomNavHeight});

    & > div {
        /* min-height: calc(100vh); */
        min-height: calc(100vh - ${theme.other.bottomNavHeight});
        position: relative;
    }
`;

export function AppCarousel() {
    const location = useLocation();
    const pages = Object.values(routes.carousel);
    const [route, setRoute] = useState<string>(location.pathname);
    const [embla, setEmbla] = useState<Embla | null>(null);
    const [scrollPos, setScrollPos] = useState<number>(pages.indexOf(location.pathname) / 4);
    const navigate = useNavigate();
    const initial = useMemo(() => pages.indexOf(location.pathname), []);
    const { bottom, top } = window.emConfig.safeAreaInsets;
    const theme = useMantineTheme();
    const carouselSlideStyles = makeCarouselSlideStyles(theme);

    const onSlideChange = (index: number) => {
        console.log('AppCarousel setting', index, pages[index]);
        navigate(pages[index], { state: { ...location.state } });
    };

    useEffect(() => {
        setRoute(location.pathname);
    }, [location.pathname]);

    const onRouteClick = (route: string) => {
        if (!embla) {
            console.log('wtf 1');
            return;
        }

        const p = pages.indexOf(route);
        embla.scrollTo(p, false);
    };

    const throttledSetScrollPos = useMemo(() => throttle(10, (pos: number) => setScrollPos(pos)), []);

    useEffect(() => {
        if (!embla) {
            return;
        }

        embla.reInit({ startIndex: initial });

        embla.on('scroll', (e: { scrollProgress: () => any }) => {
            const pos = e.scrollProgress();
            throttledSetScrollPos(pos);
        });
    }, [embla, initial, throttledSetScrollPos]);

    return (
        <div
            css={css`
                /* overflow-y: scroll; */
                /* max-height: calc(100vh - 3.5rem + ${bottom}px); */
                height: 100%;
                width: 100%;
            `}
        >
            <Carousel
                height="100%"
                // styles={{ root: { minHeight: '100%', height: '100%' } }}
                withControls={false}
                onSlideChange={onSlideChange}
                getEmblaApi={setEmbla}
                initialSlide={initial}
                id="main-app-carousel"
            >
                <Carousel.Slide
                    css={css`
                        ${carouselSlideStyles}
                    `}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dashboard />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide
                    css={css`
                        ${carouselSlideStyles}
                    `}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <CarouselTasks />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide
                    css={css`
                        ${carouselSlideStyles}
                    `}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <CarouselProperties />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide
                    css={css`
                        ${carouselSlideStyles}
                    `}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <ManageHome />
                    </div>
                </Carousel.Slide>
                <Carousel.Slide
                    css={css`
                        ${carouselSlideStyles}
                    `}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <Settings />
                    </div>
                </Carousel.Slide>
            </Carousel>
            <BottomNav.Root scrollPos={scrollPos}>
                <BottomNav.Tile
                    onClick={() => onRouteClick(routes.carousel.dashboard)}
                    active={route === routes.carousel.dashboard}
                >
                    <IconTimeline />
                </BottomNav.Tile>
                <BottomNav.Tile
                    onClick={() => onRouteClick(routes.carousel.tasks)}
                    active={route === routes.carousel.tasks}
                >
                    <IconChecks />
                </BottomNav.Tile>
                <BottomNav.Tile
                    onClick={() => onRouteClick(routes.carousel.properties)}
                    active={route === routes.carousel.properties}
                >
                    <Home />
                </BottomNav.Tile>
                <BottomNav.Tile
                    onClick={() => onRouteClick(routes.carousel.manage)}
                    active={route === routes.carousel.manage}
                >
                    <IconStack2 />
                </BottomNav.Tile>
                <BottomNav.Tile
                    onClick={() => onRouteClick(routes.carousel.settings)}
                    active={route === routes.carousel.settings}
                >
                    <IconSettings />
                </BottomNav.Tile>
            </BottomNav.Root>
        </div>
    );
}
