import { css } from '@emotion/react';
import { Button, useMantineTheme } from '@mantine/core';

interface Props {
    children: React.ReactNode;
    onClick?: () => void;
}

// eslint-disable-next-line react-refresh/only-export-components
export function FAB(props: Props) {
    const { children, onClick } = props;
    const theme = useMantineTheme();

    return (
        <Button
            css={css`
                position: absolute;
                z-index: 100;
                bottom: calc(${theme.other.bottomNavHeight} - 1rem);
                right: 1rem;
                border-radius: 1000px;
                height: 3.5rem;
                width: 3.5rem;
                background-color: ${theme.colors.teal[7]};

                display: flex;
                justify-content: center;
                align-items: center;

                & > * {
                    height: 2rem;
                    width: 2rem;
                }
            `}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}
