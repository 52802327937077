import { css } from '@emotion/react';
import { Text, Stack, Flex, useMantineTheme } from '@mantine/core';
import { ChevronsUp, ChevronsDown } from 'tabler-icons-react';

interface Props {
    imageUrl: string;
    addressLine1: string;
    addressLine2?: string;
    income: string;
    expenses: string;
    label: string;
    onClick?: () => void;
}

export function PropertySummary(props: Props) {
    const { imageUrl, addressLine1, addressLine2, income, expenses, label, onClick } = props;
    const theme = useMantineTheme();

    return (
        <Stack align="center" onClick={onClick} h="100%" spacing="0">
            <Stack justify="flex-start" spacing="0" h="100%">
                <div>
                    <img
                        css={css`
                            width: 100%;
                            aspect-ratio: 1 / 1;
                            object-fit: cover;
                            border-radius: 8px;
                        `}
                        src={imageUrl}
                    />
                </div>
                <Stack spacing="0" w="100%" mt="xs">
                    <Stack spacing="0" w="100%">
                        <Text size="md" weight={500}>
                            {label}
                        </Text>
                        <Text variant="secondary">
                            {addressLine1}, {addressLine2}
                        </Text>
                    </Stack>
                    <Flex
                        w="100%"
                        justify="start"
                        css={css`
                            align-self: center;
                        `}
                        gap="xs"
                    >
                        <Flex align="center">
                            <Text size="xs" weight="bold" color="green.7">
                                {income}
                            </Text>
                            <ChevronsUp size="1rem" color={theme.colors.green[7]} />
                        </Flex>
                        <Flex align="center">
                            <Text size="xs" weight="bold" color="red.7">
                                {expenses}
                            </Text>
                            <ChevronsDown size="1rem" color={theme.colors.red[7]} />
                        </Flex>
                    </Flex>
                </Stack>
            </Stack>
            <div
                css={(t) =>
                    css`
                        margin: 0.75rem 0;
                        width: 50%;
                        border-bottom: 1px solid ${t.colors['em-purple'][5]};
                    `
                }
            />
        </Stack>
    );
}
