import { useMutation } from '@tanstack/react-query';
import { useUserId } from 'hooks/useUserId';
import { TablesInsert } from 'types/supabase-types';
import { supabase } from 'utils/supabase';

export function useCreateMaintenanceRequest() {
    const userId = useUserId();

    const createRequest = async (request: Omit<TablesInsert<'tenant_maintenance_request'>, 'requester_id'>) => {
        if (!userId) {
            return;
        }

        const query = await supabase.from('tenant_maintenance_request').insert({ ...request, requester_id: userId });
        return query.data;
    };

    return useMutation({ mutationFn: createRequest });
}
