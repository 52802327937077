import { Button, FileButton, Notification } from '@mantine/core';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { AddTenantForm } from 'components/AddTenant';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { RadioAlt } from 'components/RadioAlt';
import { OnboardingLayout } from 'layouts/Onboarding';
import { useState } from 'react';
import { routes } from 'routes/router';

const { Root, Header, HeaderTitle, Body, Section, Footer, FooterButton, AltButton } = OnboardingLayout;

export function OwnerOnboardingTenants() {
    const [hasLease, setHasLease] = useState<'yes' | 'no'>();
    const [numTenants, setNumTenants] = useState<number>(1);
    const [file, setFile] = useState<File | null>(null);

    const removeTenant = (index: number) => {
        // this won't work properly when form controls are added
        // need to remove specific tenant at index, instead of just decrementing
        setNumTenants((n) => n - 1);
    };

    const showContinue = () => {
        if (hasLease === 'yes') {
            return !!file;
        }

        return hasLease === 'no';
    };

    return (
        <Root headerProps={{ children: 'Add Tenants', backButton: true }} footerProps={{ atBottom: true }}>
            <Section>
                <Header>
                    <HeaderTitle ta="center">Do you already have a lease in place?</HeaderTitle>
                </Header>
                <Body>
                    <RadioAlt
                        options={[
                            {
                                label: 'Yes, I have a lease',
                                value: 'yes'
                            },
                            {
                                label: 'No, help me generate one',
                                value: 'no'
                            }
                        ]}
                        radioGroupProps={{
                            onChange: (v) => setHasLease(v as 'yes' | 'no'),
                            value: hasLease
                        }}
                    />
                </Body>
                <Body>
                    <AltButton
                        title="Skip for now"
                        subtitle="Add lease info later"
                        dest={routes.ownerOnboarding.payment}
                    />
                </Body>
                {hasLease === 'yes' && (
                    <Body>
                        <FileButton onChange={setFile}>
                            {(props) => <Button {...props}>Upload Lease</Button>}
                        </FileButton>
                        {file && (
                            <Notification icon={<IconCheck />} color="green.8" withCloseButton={false}>
                                Upload successful
                            </Notification>
                        )}
                    </Body>
                )}
                {hasLease === 'no' && (
                    <>
                        {new Array(numTenants).fill(0).map((_, idx) => (
                            <Body align="center" spacing="sm">
                                <AddTenantForm index={idx} onRemove={removeTenant} showRemove />
                            </Body>
                        ))}
                        <SummaryPill
                            iconLeft={<IconPlus />}
                            title="Add another tenant"
                            onClick={() => setNumTenants((n) => n + 1)}
                            mb="lg"
                        />
                    </>
                )}
            </Section>
            <Footer>
                {showContinue() && <FooterButton dest={routes.ownerOnboarding.leaseGenerator}>Continue</FooterButton>}
            </Footer>
        </Root>
    );
}
