import { QueryData } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { supabase } from 'utils/supabase';
import { useUserId } from './useUserId';

const query = () => supabase.from('tenancy_conversation').select('*, tenancy_conversation_messages(*)');
type QueryReturn = QueryData<ReturnType<typeof query>>;

export function useSupabaseConversations() {
    const [hookData, setHookData] = useState<QueryReturn | null>();
    const userId = useUserId();

    useEffect(() => {
        const getData = async () => {
            if (!userId) {
                return;
            }

            const convoQuery = await query()
                .limit(1, { foreignTable: 'tenancy_conversation_messages' })
                .order('created_at', { referencedTable: 'tenancy_conversation_messages', ascending: false });

            setHookData(convoQuery.data);
        };

        getData();
    }, [userId]);

    return hookData;
}
