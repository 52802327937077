import {
    Accordion,
    FileInput,
    MultiSelect,
    NumberInput,
    SegmentedControl,
    Text,
    TextInput,
    Textarea
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { AutocompleteAddress } from 'components/AutocompleteAddress';
import { EMSegmentedControl } from 'components/EMSegmentedControl';
import { addWeeks, addYears } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { Bone, HomeCheck, Man, PigMoney, ShieldCheck, Tool, Pool } from 'tabler-icons-react';
import { makeRegisters } from 'utils/form-utils';
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { DEFAULT_WEEKLY_RENT } from './defaults';
import { ExistingPropertyLeaseSchema, NewPropertyLeaseSchema } from './schema';

const ACCORDION_ITEM_NAMES = ['property', 'rent', 'bond', 'landlord', 'pets', 'maintenance', 'special'];

interface Props {
    existingProperty?: boolean;
}

export function LeaseGeneratorForm(props: Props) {
    const { existingProperty } = props;
    const formMethods = useFormContext<NewPropertyLeaseSchema | ExistingPropertyLeaseSchema>();
    const { watch, getFieldState, setValue, formState } = formMethods;
    const { register, registerDateInput, registerNumberInput, registerSegmentedControl, registerAddressAutocomplete } =
        makeRegisters<NewPropertyLeaseSchema | ExistingPropertyLeaseSchema>(formMethods, null);
    const [accordionState, setAccordionState] = useState<string | null>();
    const rentPeriod = watch('rent.period');

    useEffect(() => {
        const rentAmountState = getFieldState('rent.amount');

        if (!rentAmountState.isDirty) {
            if (rentPeriod === 'week') {
                setValue('rent.amount', DEFAULT_WEEKLY_RENT);
            } else if (rentPeriod === 'fortnight') {
                setValue('rent.amount', DEFAULT_WEEKLY_RENT * 2);
            } else if (rentPeriod === 'month') {
                setValue('rent.amount', (DEFAULT_WEEKLY_RENT * 52) / 12);
            }
        }
    }, [getFieldState, rentPeriod, setValue, watch]);

    // const onError = (errors: typeof formState.errors) => {
    //     console.log('vals', watch());
    //     console.log('errors', errors);

    //     const errorIndexes = Object.keys(errors).map((k) => FIELDS_BY_ACCORDION_SECTION[k as keyof LeaseGenSchema]);
    //     const minErrorIndex = Math.min(...errorIndexes);

    //     console.log({ errorIndexes, minErrorIndex }, ACCORDION_ITEM_NAMES[minErrorIndex]);
    //     setAccordionState(ACCORDION_ITEM_NAMES[minErrorIndex]);
    // };

    useEffect(() => {
        const FIELDS_BY_ACCORDION_SECTION: Record<keyof NewPropertyLeaseSchema, number> =
            {
                startDate: 0,
                endDate: 0,
                propertyAddress: 0,
                bodyCorporate: 0,
                rent: 1,
                bond: 2,
                landlord: 3,
                pets: existingProperty ? 3 : 4,
                maintenance: existingProperty ? 3 : 5,
                specialTerms: existingProperty ? 2 : 6
            };

        const errorIndexes = Object.keys(formState.errors).map(
            (k) => FIELDS_BY_ACCORDION_SECTION[k as keyof NewPropertyLeaseSchema]
        );
        const minErrorIndex = Math.min(...errorIndexes);

        console.log({ errorIndexes, minErrorIndex }, ACCORDION_ITEM_NAMES[minErrorIndex]);
        setAccordionState(ACCORDION_ITEM_NAMES[minErrorIndex]);
    }, [existingProperty, formState.errors]);

    return (
        <Accordion
            w="100%"
            value={accordionState}
            onChange={setAccordionState}
            css={css`
                .mantine-Accordion-content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.85rem;
                }

                .mantine-Accordion-control {
                    padding: 0;
                }
            `}
            // variant="filled"
        >
            <Accordion.Item value="property">
                <Accordion.Control icon={<HomeCheck />}>Property</Accordion.Control>
                <Accordion.Panel>
                    <DateInput
                        {...registerDateInput('startDate')}
                        label="When do you want the lease to start?"
                        placeholder={new Date().toLocaleDateString()}
                    />
                    <DateInput
                        {...registerDateInput('endDate')}
                        label="When do you want the lease to end?"
                        placeholder={addYears(new Date(), 1).toLocaleDateString()}
                    />
                    {!existingProperty && (
                        <>
                            <AutocompleteAddress
                                {...registerAddressAutocomplete('propertyAddress')}
                                label="Rental property address"
                            />
                            <EMSegmentedControl
                                {...registerSegmentedControl('bodyCorporate.applies')}
                                label="Do any body-corporate rules apply to the property?"
                                w="100%"
                                data={[
                                    { label: 'No', value: 'no' },
                                    { label: 'Yes', value: 'yes' }
                                ]}
                            />
                            {watch('bodyCorporate.applies') === 'yes' && (
                                <FileInput
                                    // {...register('bodyCorporate.file')}
                                    // @ts-ignore
                                    placeholder="Tap here to upload body corporate bylaws"
                                    color="blue.1"
                                    css={css`
                                        .mantine-FileInput-input {
                                            text-align: center;
                                        }

                                        .mantine-FileInput-placeholder {
                                            color: #c1c2c5;
                                        }
                                    `}
                                />
                            )}
                        </>
                    )}
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="rent">
                <Accordion.Control icon={<PigMoney />}>Rent</Accordion.Control>
                <Accordion.Panel>
                    <div>
                        <Text size="sm" fw={500}>
                            How often do you want to collect rent?
                        </Text>
                        <SegmentedControl
                            {...registerSegmentedControl('rent.period')}
                            w="100%"
                            data={[
                                {
                                    label: 'Weekly',
                                    value: 'week'
                                },
                                { label: 'Fortnightly', value: 'fortnight' },
                                { label: 'Monthly', value: 'month' }
                            ]}
                        />
                    </div>
                    <NumberInput
                        {...registerNumberInput('rent.amount')}
                        label={`Rent amount per ${watch('rent.period')}`}
                        placeholder="600"
                        icon="$"
                    />
                    <DateInput
                        {...registerDateInput('rent.firstPaymentDue')}
                        label="When is the first payment due?"
                        placeholder={addWeeks(new Date(), 2).toLocaleDateString()}
                    />
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="bond">
                <Accordion.Control icon={<ShieldCheck />}>Bond</Accordion.Control>
                <Accordion.Panel>
                    <NumberInput
                        {...registerNumberInput('bond.amount')}
                        label="How much is the bond?"
                        placeholder="2000"
                        icon="$"
                    />
                    <DateInput
                        {...registerDateInput('bond.dueOn')}
                        label="When is bond due?"
                        placeholder={addWeeks(new Date(), 2).toLocaleDateString()}
                    />
                </Accordion.Panel>
            </Accordion.Item>
            {!existingProperty && (
                <>
                    {' '}
                    <Accordion.Item value="landlord">
                        <Accordion.Control icon={<Man />}>Landlord</Accordion.Control>
                        <Accordion.Panel>
                            <TextInput
                                {...register('landlord.name')}
                                label="Landlord's full name"
                                placeholder="John Smith"
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                    <Accordion.Item value="pets">
                        <Accordion.Control icon={<Bone />}>Pets</Accordion.Control>
                        <Accordion.Panel>
                            <EMSegmentedControl
                                label="Are pets permitted at the property?"
                                {...registerSegmentedControl('pets.permitted')}
                                w="100%"
                                data={[
                                    { label: 'No', value: 'no' },
                                    { label: 'Yes', value: 'yes' }
                                ]}
                            />
                            {watch('pets.permitted') === 'yes' && (
                                <TextInput
                                    {...register('pets.description')}
                                    label="Description of approved pets"
                                    placeholder="e.g. 2 cats"
                                />
                            )}
                        </Accordion.Panel>
                    </Accordion.Item>
                    <Accordion.Item value="maintenance">
                        <Accordion.Control icon={<Tool />}>Maintenance</Accordion.Control>
                        <Accordion.Panel>
                            <EMSegmentedControl
                                label="Do you have preferred maintenance contacts?"
                                {...registerSegmentedControl('maintenance.preferredProvider')}
                                w="100%"
                                data={[
                                    { label: 'No', value: 'no' },
                                    { label: 'Yes', value: 'yes' }
                                ]}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </>
            )}
            <Accordion.Item value="special">
                <Accordion.Control icon={<Pool />}>Special Terms</Accordion.Control>
                <Accordion.Panel>
                    <div>
                        <Text size="sm" fw={500}>
                            Do you have any extra/special terms to include?
                            <br />
                        </Text>
                        <Text size="xs" color="#909296">
                            {' '}
                            E.g. pool maintenance, rules for subtenants
                        </Text>
                        <SegmentedControl
                            {...registerSegmentedControl('specialTerms.included')}
                            w="100%"
                            data={[
                                { label: 'No', value: 'no' },
                                { label: 'Yes', value: 'yes' }
                            ]}
                        />
                        {/* <TextInput label="Description of approved pets" placeholder="e.g. 2 cats" /> */}
                    </div>
                    {watch('specialTerms.included') === 'yes' && (
                        <>
                            <MultiSelect
                                data={['Pool or Spa', 'Subtenancy', 'Thing 2', 'Thing 3', 'Thing 4']}
                                label="Select all that apply"
                                searchable
                                clearable
                                nothingFound="Nothing found, manually add a custom item below"
                                description="Start typing to search, or scroll through the list"
                            />
                            <div>
                                <Text size="sm" fw={500}>
                                    Do you want to include custom terms?
                                </Text>
                                <SegmentedControl
                                    {...registerSegmentedControl('specialTerms.customIncluded')}
                                    w="100%"
                                    data={[
                                        { label: 'No', value: 'no' },
                                        { label: 'Yes', value: 'yes' }
                                    ]}
                                />
                                {/* <TextInput label="Description of approved pets" placeholder="e.g. 2 cats" /> */}
                            </div>
                            {watch('specialTerms.customIncluded') === 'yes' && (
                                <Textarea
                                    label="Describe what you'd like to add"
                                    description="The AI Toolbox will generate terms for you to review"
                                    rows={3}
                                    placeholder="Custom designer water fountain in the front yard must be cleaned every 3 months."
                                />
                            )}
                        </>
                    )}
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}
