import { AllChats } from 'components/Chat/all-chats';
import { AuthedLayout } from 'layouts/authed';

export function TenantChat() {
    return (
        <AuthedLayout showHeader headerText="Chat">
            <AllChats />
        </AuthedLayout>
    );
}
