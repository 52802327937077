import { useRouteError } from 'react-router-dom';

export function RouterError() {
    const error = useRouteError();
    console.error(error);

    return (
        <div>
            <h1>oops</h1>
            <pre style={{ overflow: 'scroll' }}>
                {JSON.stringify(error, null, 2)}
            </pre>
        </div>
    )
}
