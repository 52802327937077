import { Title, Text, Button } from '@mantine/core';
import { PaymentOptions } from 'components/Onboarding/PaymentOptions';
import { RadioAlt } from 'components/RadioAlt';
import { AuthedLayout } from 'layouts/authed';

export function TenantManualRentPayment() {
    return (
        <AuthedLayout showHeader headerText="Make a payment" showBack>
            <Text>Rent Due: $2,900</Text>
            <Title size="h3">Select payment method</Title>
            <PaymentOptions radioAltProps={{ sameLine: true }} />
            <Text variant="secondary">
                By scheduling this payment, you authorise us to initiate a one-time debit from your bank account. You
                may revoke your authorization at any time by contacting us.
            </Text>
            <Text>Would you like to enable automatic rental payments using this method?</Text>
            <RadioAlt
                sameLine
                options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                ]}
                radioGroupProps={{ defaultValue: 'no' }}
            />
            <Button>Pay $2,900 now</Button>
        </AuthedLayout>
    );
}
