import { css } from '@emotion/react';
import { LoadingOverlay, MantineTheme, Stack, StackProps, useMantineTheme } from '@mantine/core';
import { AppHeader } from 'components/AppHeader';
import { useAccount } from 'hooks/useAccount';
import { useWatchAuth } from 'hooks/useWatchAuth';
import { AuthedSplashModal } from 'pages/Splash/authed-modal';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { useThemeStore } from 'store/zustand';
import { supabase } from 'utils/supabase';

interface Props {
    children: React.ReactNode;
    showHeader?: boolean;
    headerText?: string;
    stickyHeader?: boolean;
    disableContainerScroll?: boolean;
    showGuidedHeader?: boolean;
    guidedHeaderOnClick?: () => void;
    _css?: ReturnType<typeof css> | ((theme: MantineTheme) => ReturnType<typeof css>);
    overrideBackLocation?: string;
    showBack?: boolean;
    onHeaderClick?: () => void;
    stackProps?: StackProps;
}

export function AuthedLayout(props: Props) {
    const {
        children,
        showHeader,
        headerText,
        stickyHeader,
        showGuidedHeader,
        guidedHeaderOnClick,
        _css,
        overrideBackLocation,
        showBack,
        onHeaderClick,
        stackProps
    } = props;
    useWatchAuth();
    const theme = useMantineTheme();
    const self = useAccount();
    const navigate = useNavigate();
    const location = useLocation();
    const setUserId = useThemeStore((s) => s.setCurrentUserId);

    // useEffect(() => {
    //     const doThing = async () => {
    //         const refetchSelf = () => self.refetch().then((r) => {
    //             console.log('self refetched', r);
    //         });

    //         // dumb hack???
    //         if (self && !self.isLoading && !self.data) {
    //             const authed = await supabase.auth.getUser();

    //             if (!authed) {
    //                 const user = await supabase.auth.refreshSession();
    //                 setUserId(user.data.user?.id ?? null);
    //                 console.log('reauthing and setting userid')
    //             }

    //             refetchSelf();
    //         }
    //     };

    //     doThing()
    // }, [self, setUserId]);

    useEffect(() => {
        const t = setTimeout(() => {
            if (!self.data) {
                supabase.auth.signOut().then(() => {
                    navigate(routes.signIn);
                });
            }
        }, 10_000);

        console.log('self data in hoo9k', self.data);
        if (self.data) {
            clearTimeout(t);
        }

        return () => clearTimeout(t);
    }, [navigate, self.data]);

    const hasNotCompletedOrSkippedOnboarding =
        self.data &&
        !self?.data?.user_updateable?.[0]?.onboarding_skipped_at &&
        !self.data?.user_updateable?.[0]?.onboarding_done;

    // console.log('selfdata', self.data)

    return (
        <Stack
            justify="flex-start"
            align="center"
            w="100%"
            css={(t) => css`
                height: 100%;
                ${typeof _css === 'function' ? _css(t) : _css}
            `}
            {...stackProps}
        >
            <AuthedSplashModal
                opened={!!hasNotCompletedOrSkippedOnboarding && location.pathname !== routes.notEnabled}
                onClose={() => {}}
            />
            <LoadingOverlay
                overlayBlur={1}
                visible={!self.data}
                css={css`
                    position: fixed;
                    top: 0;
                    height: 100vh;
                    z-index: 9999999;
                `}
            />
            {showHeader && (
                <AppHeader
                    sticky={stickyHeader}
                    showGuidedMode={showGuidedHeader}
                    guidedModeOnClick={guidedHeaderOnClick}
                    overrideBackLocation={overrideBackLocation}
                    showBack={showBack}
                    onClick={() => onHeaderClick?.()}
                >
                    {headerText}
                </AppHeader>
            )}
            <Stack
                p="sm"
                spacing="lg"
                w="100%"
                className="auth-layout-inner-stack"
                css={css`
                    height: calc(
                        100vh - (${theme.other.bottomNavHeight} + ${showHeader ? theme.other.appHeaderBottom : '0px'})
                    );
                    overflow-y: scroll;
                `}
            >
                {children}
            </Stack>
        </Stack>
    );
}
