import { Modal, ModalProps, Stack, Text } from '@mantine/core';

export function NoCodeModal(props: ModalProps) {
    return (
        <Modal {...props} title="Sorry!" centered>
            <Stack spacing="lg">
                <Text>
                    At the moment, we're only accepting tenants who have received an invite from their landlord.
                </Text>
                <Text>We'll be launching our independent tenant-only product soon.</Text>
                <Text>
                    If your landlord has asked you to use Easy Manage, you should have received an invitation email from
                    us. If not, ask your landlord for the invite code.
                </Text>
            </Stack>
        </Modal>
    );
}
