import { Route, Routes } from 'react-router-dom';
import { PropertiesDetailed } from './detailed';
import { propertiesSubroutes } from './routes';
import { PropertyBills } from './bills';
import { ManageTenantsLease } from './ManageTenants/Lease';
import { ManageTenantsRentLedger } from './ManageTenants/RentLedger';
import { NewLeaseGenerator } from './ManageTenants/Lease/new-lease-generator';
import { NewLeaseConfirm } from './ManageTenants/Lease/new-lease-confirm';
import { ManageTenantsTenants } from './ManageTenants/Lease/tenants';
import { AddTenant } from './ManageTenants/Lease/add-tenant';
import { LeaseDetails } from './ManageTenants/Lease/lease-details';
import { Leases } from './ManageTenants/Lease/leases';
import { TenantDetails } from './ManageTenants/Lease/tenant-details';

export function Properties() {
    return (
        <Routes>
            <Route path={`${propertiesSubroutes.detailed}/*`} element={<PropertiesDetailed />} />
            <Route path={propertiesSubroutes.bills} element={<PropertyBills />} />
            <Route path={propertiesSubroutes.lease} element={<ManageTenantsLease />} />
            <Route path={propertiesSubroutes.newLeaseGenerator} element={<NewLeaseGenerator />} />
            <Route path={propertiesSubroutes.newLeaseConfirm} element={<NewLeaseConfirm />} />
            <Route path={propertiesSubroutes.ledger} element={<ManageTenantsRentLedger />} />
            <Route path={propertiesSubroutes.manageTenants} element={<ManageTenantsTenants />} />
            <Route path={propertiesSubroutes.addTenant} element={<AddTenant />} />
            <Route path={propertiesSubroutes.leases} element={<Leases />} />
            <Route path={propertiesSubroutes.leaseDetails} element={<LeaseDetails />} />
            <Route path={propertiesSubroutes.tenantDetails} element={<TenantDetails />} />
        </Routes>
    );
}
