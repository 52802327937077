import { css } from '@emotion/react';
import { Flex, Radio, RadioGroupProps, RadioProps, Stack, rem, useMantineTheme } from '@mantine/core';

interface Props {
    options: RadioProps[];
    radioGroupProps?: Omit<RadioGroupProps, 'children'>;
    sameLine?: boolean;
}

export type RadioAltProps = Props;

export function RadioAlt(props: Props) {
    const { options, radioGroupProps, sameLine } = props;
    const theme = useMantineTheme();

    const Container = sameLine ? Flex : Stack;
    const spacingProps = sameLine ? { gap: 'sm' } : { spacing: 'sm' };

    return (
        <Radio.Group {...radioGroupProps}>
            <Container {...spacingProps}>
                {options.map((p) => (
                    <Radio
                        {...p}
                        css={css`
                            width: fit-content;
                            border: 1px solid #453366;
                            border-radius: ${rem(12)};

                            &[data-checked='true'] {
                                background-color: ${theme.colors['em-purple'][3]};
                            }

                            .mantine-Radio {
                                &-inner {
                                    display: none;
                                }

                                &-label {
                                    padding-right: 0;
                                }

                                &-body {
                                    width: fit-content;
                                    height: ${rem(45)};
                                    padding: 0 1rem;
                                    border: none;
                                }
                            }

                            .mantine-Radio-root {
                                background-color: green;
                            }
                        `}
                    />
                ))}
            </Container>
        </Radio.Group>
    );
}
