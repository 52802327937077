import { RouteMap } from './router'

type Return<K, V> = Record<keyof K, V>;

export const makeFullRoutes = <T extends RouteMap>(rootPath: string, routes: T) => {
    return {
        ...Object.keys(routes).reduce((acc, cur: string) => {
            return {
                ...acc,
                [cur]: `${rootPath}/${routes[cur as keyof typeof routes]}`
            }
        }, {})
    } as Return<T, string>
}
