import { css } from '@emotion/react';
import { Text } from '@mantine/core';
import { useThemeStore } from 'store/zustand';

interface Props {
    name: string;
    children: React.ReactNode;
}

export function Metric(props: Props) {
    const { children, name } = props;
    const emTheme = useThemeStore(state => state.emTheme);
    
    return (
        <div css={css`
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
        `}>
            <div>
                { children }
            </div>
            <Text color={emTheme === 'light' ? 'gray.7' : 'gray.3'} size="sm" align="center">
                { name }
            </Text>
        </div>
    );
}
