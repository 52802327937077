import { css } from '@emotion/react';
import { Divider, LoadingOverlay } from '@mantine/core';
import { AuthedLayout } from 'layouts/authed';
import { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { AlertCircle, FileText, HomeSearch, Report, ReportMoney } from 'tabler-icons-react';
import house1 from 'assets/house1.jpeg';
import { useGetAllProperties } from 'hooks/useGetAllProperties';
import { PropertySelect } from 'components/PropertySelect';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';

interface ManageTileProps {
    icon: React.ReactNode;
    colour: string;
    children: React.ReactNode;
    onClick?: () => void;
    title: string;
}

function ManageTile(props: ManageTileProps) {
    const { children, icon, colour, onClick, title } = props;

    const handleClick = () => {
        !!onClick && onClick();
    };

    return (
        <div
            onClick={handleClick}
            css={css`
                display: flex;
                flex-direction: column;
            `}
        >
            <div
                css={css`
                    display: flex;
                    border-radius: 8px;
                    align-items: center;
                    height: 4.5rem;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <div
                        css={css`
                            background-color: ${colour};
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            height: 4rem;
                            width: 4rem;
                            border-radius: 100rem;
                            z-index: 1000;
                        `}
                    >
                        <div
                            css={css`
                                height: 60%;
                                width: 60%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                & > * {
                                    height: 100%;
                                    width: 100%;
                                    stroke-width: 1.25;
                                }
                            `}
                        >
                            {icon}
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        text-align: center;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;

                        line-height: 22px;
                        /* margin-left: -2.5rem; */
                        padding-left: 1rem;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                        border-left: none;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;

                        position: relative;

                        & > * {
                            text-align: left;
                        }
                    `}
                >
                    <div
                        css={css`
                            line-height: 16px;
                            color: white;
                            font-weight: bold;
                            margin-bottom: 0.25rem;
                        `}
                    >
                        {title}
                    </div>
                    <div
                        css={css`
                            font-size: 0.8rem;
                            line-height: 14px;
                        `}
                    >
                        {children}
                    </div>
                </div>
            </div>
            <Divider
                css={css`
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    width: 100%;
                    border-color: ${colour};
                `}
            />
        </div>
    );
}

export function ManageHome() {
    const { data, isLoading } = useGetAllProperties();
    const location = useLocation();
    const propertyId: string | undefined = location.state?.propertyId;
    const navigate = useNavigate();
    const [selectedPropertyId, setSelectedPropertId] = useState<string | null>(propertyId ?? null);
    const properties = useGetAllProperties();
    const showInspections = useFeatureFlagEnabled(FeatureFlag.Manage.BreachNotices);
    const showEntryExitReports = useFeatureFlagEnabled(FeatureFlag.Manage.EntryExitReports);
    const showBreachNotices = useFeatureFlagEnabled(FeatureFlag.Manage.BreachNotices);

    const property = !!selectedPropertyId && data?.find((p) => String(p?.id) === selectedPropertyId);

    useEffect(() => {
        const firstPropertyId = data?.[0]?.id;

        if (!propertyId && !!firstPropertyId) {
            setSelectedPropertId(String(firstPropertyId));
        }
    }, [data, propertyId]);

    return (
        <AuthedLayout
            _css={css`
                height: 100vh;
                min-height: 100vh;
            `}
        >
            <LoadingOverlay visible={isLoading} />
            <div
                css={css`
                    width: 100%;
                    max-height: calc(100% - 5rem);
                    height: calc(100% - 5rem);
                    display: flex;
                    flex-direction: column;
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    {selectedPropertyId && !!property ? (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 0.5rem;
                                color: white;
                            `}
                        >
                            <div
                                css={css`
                                    height: 4rem;
                                    border-radius: 4px;
                                    display: flex;
                                    gap: 1rem;
                                    align-items: center;
                                `}
                            >
                                <div
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <img
                                        css={css`
                                            height: 100%;
                                            border-radius: 8px;
                                        `}
                                        src={house1}
                                    />
                                </div>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                    `}
                                >
                                    <div>{property.niceName}</div>
                                    <div
                                        css={css`
                                            font-size: 0.8rem;
                                        `}
                                    >
                                        {property.streetName}, {property.suburb} {property.state}
                                    </div>
                                </div>
                            </div>
                            <div
                                css={css`
                                    color: aqua;
                                    font-size: 0.8rem;
                                `}
                                onClick={() => setSelectedPropertId(null)}
                            >
                                Change property
                            </div>
                        </div>
                    ) : (
                        <div
                            css={css`
                                min-height: 5rem;
                            `}
                        >
                            {data?.length ? (
                                <PropertySelect
                                    properties={properties.data ?? []}
                                    value={selectedPropertyId}
                                    onChange={(id) => setSelectedPropertId(id)}
                                />
                            ) : (
                                "You don't have any properties yet."
                            )}
                        </div>
                    )}
                </div>
                <Divider
                    css={css`
                        margin: 1rem 0;
                        width: 100%;
                    `}
                />
                {selectedPropertyId && (
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            justify-items: stretch;
                            align-items: stretch;
                            padding: 0 0.5rem;

                            pointer-events: ${selectedPropertyId ? 'all' : 'none'};
                            opacity: ${selectedPropertyId ? 1 : 0.5};

                            height: calc(100% - 4.5rem);
                            overflow-y: scroll;
                        `}
                    >
                        <ManageTile
                            icon={<FileText color="#e100ff" />}
                            colour="#f1c4f1"
                            title="Lease Management"
                            onClick={() =>
                                navigate({
                                    pathname: routes.properties.lease,
                                    search: createSearchParams({ propertyId: selectedPropertyId }).toString()
                                })
                            }
                        >
                            Access and modify current leases, change tenant information, and access the AI lease
                            generator.
                        </ManageTile>
                        <ManageTile
                            icon={<ReportMoney color="#1900ff" />}
                            colour="#b7b6f5"
                            onClick={() =>
                                navigate({
                                    pathname: routes.properties.ledger,
                                    search: createSearchParams({ propertyId: selectedPropertyId }).toString()
                                })
                            }
                            title="Rent Ledger"
                        >
                            Automatically keep track of rental payments from all tenants, updated automatically for
                            financial tracking.
                        </ManageTile>
                        {showInspections && (
                            <ManageTile
                                icon={<HomeSearch color="#118b79" />}
                                colour="#00ffbd"
                                onClick={() => {
                                    alert('coming soon!');
                                }}
                                title="Inspections"
                            >
                                Start a tenant-led inspection, hire a qualified Easy Manage Inspector to run a one-off
                                inspection, record inspection results.
                            </ManageTile>
                        )}
                        {showEntryExitReports && (
                            <ManageTile
                                icon={<Report color="#797f1b" />}
                                colour="#e2f47f"
                                onClick={() => {
                                    alert('coming soon!');
                                }}
                                title="Entry / Exit Report"
                            >
                                Create detailed entry and exit reports.
                                <br />
                                Thoroughly document property conditions, simplify security and bond decisions, maintain
                                property standards.
                            </ManageTile>
                        )}
                        {showBreachNotices && (
                            <ManageTile
                                icon={<AlertCircle color="#ff0037" />}
                                colour="#e6bdbd"
                                onClick={() => {
                                    alert('coming soon!');
                                }}
                                title="Breach Notices"
                            >
                                Issue and manage breach notices for any lease violations. Document incidents, send
                                automated notifications, and track resolutions.
                            </ManageTile>
                        )}
                    </div>
                )}
            </div>
        </AuthedLayout>
    );
}
