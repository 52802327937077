import { useMutation } from '@tanstack/react-query';
import { useAccount } from 'hooks/useAccount';
import { useUserId } from 'hooks/useUserId';
import { supabase } from 'utils/supabase';

export function useSetOnboardingDone() {
    const userId = useUserId();
    const account = useAccount();

    const setDone = async (done: boolean) => {
        if (!userId) {
            return null;
        }

        const query = await supabase
            .from('user_updateable')
            .update({
                onboarding_done: done
            })
            .eq('user_id', userId);

        await account.refetch();

        return query.data;
    };

    return useMutation({ mutationFn: setDone });
}
