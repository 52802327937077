export const FeatureFlag = {
    TestFlag: 'test-flag',
    YC: {
        WorkOrders: 'yc_work-orders',
        LeaseCreator: 'yc_lease-creator',
        UserModeSetting: 'yc_user-mode-setting'
    },
    ShowTestButtonsInTest: 'show-test-buttons-in-test',
    Manage: {
        BreachNotices: 'manage_breach-notices',
        EntryExitReports: 'manage_entry-exit-reports',
        Inspections: 'manage_inspections',
        AddTenantCreateNewLease: 'manage_add-tenant-create-new-lease',
        TenantReminders: 'manage_tenant-reminders',
        Documents: 'manage_documents',
        Maintenance: 'manage_maintenance',
    },
    SignIn: {
        WithApple: 'sign-in_with-apple'
    },
    Onboarding: {
        SkipToSignUp: 'onboarding_skip-to-signup'
    }
} as const;
