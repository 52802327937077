import { useLocation } from 'react-router-dom';
import { AuthedLayout } from 'layouts/authed';
import { routes } from 'routes/router';

export function NewLeaseConfirm() {
    const location = useLocation();
    console.log('state', location.state);
    const leaseTerms = location.state.formData;

    return (
        <AuthedLayout showHeader showBack overrideBackLocation={routes.carousel.dashboard}>
            Lease will be sent to the tenant for signing <br />
            <br />
            {JSON.stringify(leaseTerms)}
        </AuthedLayout>
    );
}
