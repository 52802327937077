import { supabase } from 'utils/supabase';
import { useUserId } from './useUserId';
import { useQuery } from '@tanstack/react-query';

export function useAccount() {
    const userId = useUserId();

    const getSelf = async () => {
        if (!userId) {
            console.log('[useAccount] userId null')
            return null;
        }

        const { data } = await supabase.from('User').select('*, user_updateable(*)').eq('id', userId).single();
        return data;
    };

    return useQuery({ queryKey: ['self', userId], queryFn: getSelf, gcTime: 0 });
}
