import { zodResolver } from '@hookform/resolvers/zod';
import { Button, LoadingOverlay, Select, Text, TextInput } from '@mantine/core';
import { EMSegmentedControl } from 'components/EMSegmentedControl';
import { useCreateTenantInvite } from 'hooks/owners/useCreateTenantInvite';
import { useGetTenancies } from 'hooks/useGetTenancies';
import { AuthedLayout } from 'layouts/authed';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { makeRegisters } from 'utils/form-utils';
import { z } from 'zod';
import { LeaseGeneratorForm } from 'components/LeaseGenerator';
import { leaseGenDefaults } from 'components/LeaseGenerator/defaults';
import { existingPropertySchema } from 'components/LeaseGenerator/schema';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';

const schema = z
    .object({
        tenantName: z.string(),
        tenantEmail: z.string(),
        existingLease: z.string().optional(),
        existingLeaseId: z.string().optional()
    })
    .merge(existingPropertySchema);

type Schema = z.infer<typeof schema>;

export function AddTenant() {
    const createInvite = useCreateTenantInvite();
    const canCreateNewLease = useFeatureFlagEnabled(FeatureFlag.Manage.AddTenantCreateNewLease);
    const formMethods = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            ...leaseGenDefaults,
            landlord: {
                name: 'test'
            },
            propertyAddress: {
                street: 'test',
                suburb: 'test',
                state: 'test'
            },
            existingLease: undefined
        }
    });
    const { handleSubmit, formState, watch, setValue } = formMethods;
    const { register, registerSegmentedControl, registerSelect } = makeRegisters<Schema>(formMethods, {
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });
    const { data: tenancies, isLoading: tenanciesLoading } = useGetTenancies();
    const propertyId = useSearchParams()[0].get('propertyId');

    useEffect(() => {
        if (tenancies && tenancies.length > 0) {
            setValue('existingLeaseId', String(tenancies[0].id));
        }
    }, [setValue, tenancies]);

    const onSubmit = async (form: Schema) => {
        console.log(form);

        if (form.existingLeaseId) {
            await createInvite.mutateAsync({
                sent_to_name: form.tenantName,
                sent_to_email: form.tenantEmail,
                tenancy_id: Number(form.existingLeaseId),
                invite_code: `A${Math.floor(Math.random() * 10000) + 1000}`
            });

            notifications.show({
                title: 'Success!',
                message: 'Invite sent to new tenant',
                color: 'green.8'
            });
        } else {
            // create lease in draft mode
            // create tenant invite
        }
    };

    const existingTenanciesForProperty = tenancies?.filter((t) => t.property_id === Number(propertyId)) ?? [];

    if (!propertyId) {
        return;
    }

    if (!canCreateNewLease && existingTenanciesForProperty.length === 0) {
        return (
            <AuthedLayout showBack showHeader headerText="Add tenant">
                You have no existing leases for this property. You must create a lease before you can invite tenants.
            </AuthedLayout>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <AuthedLayout showBack showHeader headerText="Add tenant">
                <LoadingOverlay visible={tenanciesLoading} />
                <TextInput {...register('tenantName')} label="Tenant Name" />
                <TextInput {...register('tenantEmail')} label="Tenant Email" />
                {existingTenanciesForProperty.length > 0 && (
                    <>
                        {canCreateNewLease && (
                            <EMSegmentedControl
                                {...registerSegmentedControl('existingLease')}
                                label="Add to existing lease?"
                                defaultValue={null}
                                data={[
                                    { value: 'no', label: 'No' },
                                    { value: 'yes', label: 'Yes' }
                                ]}
                            />
                        )}
                        {(watch('existingLease') === 'yes' || !canCreateNewLease) && (
                            <Select
                                {...registerSelect('existingLeaseId')}
                                label="Select existing lease"
                                data={existingTenanciesForProperty.map((t) => ({
                                    value: String(t.id),
                                    label: `Lease ${t.id}`
                                }))}
                            />
                        )}
                    </>
                )}
                {canCreateNewLease &&
                    (existingTenanciesForProperty.length === 0 || watch('existingLease') === 'no') && (
                        <FormProvider {...formMethods}>
                            <Text>Complete the sections below to create a new lease</Text>
                            <LeaseGeneratorForm existingProperty />
                        </FormProvider>
                    )}
                <Button type="submit" mt="lg" loading={formState.isSubmitting}>
                    Add
                </Button>
            </AuthedLayout>
        </form>
    );
}
