import BannerImage from 'assets/tenant-onboarding/pikaso_texttoimage_modern-flat-panoramic-shot-of-a-woman-standing-out.jpeg';
import { routes } from 'routes/router';
import { OnboardingLayout } from 'layouts/Onboarding';

const { Root, Header, HeaderTitle, HeaderSubtitle, Footer, FooterButton } = OnboardingLayout;

export function TenantOnboardingDone() {
    return (
        <Root bannerImage={<img src={BannerImage} />}>
            <Header>
                <HeaderTitle>Congrats, you're all set</HeaderTitle>
                <HeaderSubtitle>Your profile is complete.</HeaderSubtitle>
            </Header>
            <Footer>
                <FooterButton dest={routes.carousel.dashboard}>Go to Dashboard</FooterButton>
            </Footer>
        </Root>
    );
}
