import { AuthedLayout } from 'layouts/authed';
import { css } from '@emotion/react';
import { Button, Title } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetTenantAccounts } from 'hooks/owners/useGetTenantAccounts';
import { useGetTenancies } from 'hooks/useGetTenancies';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/router';

export function Leases() {
    const propertyId = useSearchParams()[0].get('propertyId');
    const { data: tenancies } = useGetTenancies();
    const { data: tenants } = useGetTenantAccounts(propertyId ? Number(propertyId) : null);
    const navigate = useNavigate();

    return (
        <AuthedLayout showHeader showBack headerText="Leases">
            <Title size="h2">Your leases</Title>
            {tenancies
                ?.filter((t) => String(t.property_id) === propertyId)
                ?.map((t) => (
                    <SummaryPill
                        title={`Lease ${t.id}`}
                        subtitle={tenants?.find((te) => te.tenancy_id === t.id)?.display_name}
                    >
                        <Button
                            variant="secondary"
                            css={css`
                                height: 2.5rem;
                                min-height: 2.5rem;
                                font-size: 0.8rem;
                                width: 7rem;
                            `}
                            onClick={() => navigate({
                                pathname: routes.properties.leaseDetails,
                                search: createSearchParams({ tenancyId: String(t.id) }).toString()
                            })}
                        >
                            View lease
                        </Button>
                    </SummaryPill>
                ))}
        </AuthedLayout>
    );
}
