import { Route, Routes } from 'react-router-dom';
import { ownerOnboardingSubroutes } from './routes';
import { OwnerOnboardingIntro } from './pages/intro';
import { OwnerOnboardingAddress } from './pages/address';
import { OwnerOnboardingPropertyDetails } from './pages/property-details';
import { OwnerOnboardingTenants } from './pages/tenants';
import { OwnerOnboardingPayment } from './pages/payment';
import { OwnerOnboardingLeaseSummary } from './pages/lease-summary';
import { OwnerOnboardingDone } from './pages/done';
import { OwnerOnboardingLeaseGen } from './pages/lease-gen';

export function OwnerOnboarding() {
    return (
        <Routes>
            <Route path={ownerOnboardingSubroutes.intro} element={<OwnerOnboardingIntro />} />
            <Route path={ownerOnboardingSubroutes.address} element={<OwnerOnboardingAddress />} />
            <Route path={ownerOnboardingSubroutes.propertyDetails} element={<OwnerOnboardingPropertyDetails />} />
            <Route path={ownerOnboardingSubroutes.tenants} element={<OwnerOnboardingTenants />} />
            <Route path={ownerOnboardingSubroutes.leaseGenerator} element={<OwnerOnboardingLeaseGen />} />
            <Route path={ownerOnboardingSubroutes.payment} element={<OwnerOnboardingPayment />} />
            <Route path={ownerOnboardingSubroutes.leaseSummary} element={<OwnerOnboardingLeaseSummary />} />
            <Route path={ownerOnboardingSubroutes.done} element={<OwnerOnboardingDone />} />
        </Routes>
    );
}
