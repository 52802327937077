import { routes } from 'routes/router';
import { OnboardingLayout } from 'layouts/Onboarding';
import { PaymentOptions } from 'components/Onboarding/PaymentOptions';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body, Footer, FooterButton } = OnboardingLayout;

export function TenantOnboardingPayment() {
    return (
        <Root headerProps={{ children: 'Rent Payments', backButton: true }}>
            <Header>
                <HeaderTitle>Choose how you'd like to pay rent</HeaderTitle>
                <HeaderSubtitle>
                    Easy Manage does not charge you a fee to pay your rent, we also do not pass on any third-party fees.
                </HeaderSubtitle>
            </Header>
            <Body>
                <PaymentOptions showVerificationMessage />
            </Body>
            <Footer>
                <FooterButton dest={routes.tenantOnboarding.done}>Continue</FooterButton>
            </Footer>
        </Root>
    );
}
