import { QueryData } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useThemeStore } from 'store/zustand';
import { supabase } from 'utils/supabase';

const query = () => supabase.from('Property').select('*');
type QueryReturn = QueryData<typeof query>;

export function useGetAllProperties() {
    const userId = useThemeStore((s) => s.currentUserId);

    const getProperties = async () => {
        if (!userId) {
            throw new Error('no user id')
        }

        const { data, error } = await query().eq('userId', userId);

        if (error) {
            throw new Error(error.message);
        }

        return data;
    };

    return useQuery({ queryKey: ['properties'], queryFn: getProperties });
}
