import { SummaryPill, SummaryPillImage } from 'components/Onboarding/SummaryPill';
import { AuthedLayout } from 'layouts/authed';
import LeaseImg from 'assets/tenant-dashboard/lease.svg';
import MaintenanceImg from 'assets/tenant-dashboard/pikaso_texttoimage_modern-flat-a-plumber-working-under-a-sink-vector-.jpg';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { PropertySelect } from 'components/PropertySelect';
import { TenantHooks } from 'hooks/tenants';
import { useEffect, useState } from 'react';
import { Tables } from 'types/supabase-types';
import { LoadingOverlay } from '@mantine/core';

const makePath = (propertyId: string) => (path: string) => `${path}/${propertyId}`;

export function TenantDashboard() {
    const navigate = useNavigate();
    const { data, isLoading } = TenantHooks.useGetTenancy();
    const [selectedProperty, setSelectedProperty] = useState<string | null>();
    const getPath = makePath(selectedProperty ?? '');

    useEffect(() => {
        const id = data?.[0]?.Property?.id;

        if (id) {
            setSelectedProperty(String(id));
        }
    }, [data]);

    return (
        <AuthedLayout headerText="Dashboard" showHeader>
            <LoadingOverlay visible={isLoading} />
            <PropertySelect
                properties={(data?.map((d) => d.Property) as Tables<'Property'>[]) ?? []}
                label="Select a property"
                onChange={(v) => setSelectedProperty(v)}
                value={selectedProperty}
            />
            {selectedProperty && (
                <>
                    <SummaryPill
                        title={<b>Lease</b>}
                        subtitle="Review your lease details"
                        onClick={() => navigate(getPath(routes.tenant.lease))}
                    >
                        <SummaryPillImage>{LeaseImg}</SummaryPillImage>
                    </SummaryPill>
                    <SummaryPill
                        title={<b>Maintenance</b>}
                        subtitle="Review your maintenance requests"
                        onClick={() => navigate(routes.tenant.maintenance)}
                    >
                        <SummaryPillImage>{MaintenanceImg}</SummaryPillImage>
                    </SummaryPill>
                </>
            )}
        </AuthedLayout>
    );
}
