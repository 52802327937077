import { css } from '@emotion/react';
import { Flex } from '@mantine/core';
import { useMemo } from 'react';
import { useThemeStore } from 'store/zustand';
import { BottomNavBorder } from './border';

interface Props {
    children: React.ReactNode;
    active?: boolean;
    onClick?: () => void;
}

export function BottomNavTile(props: Props) {
    const { children, active, onClick } = props;
    const { bottom } = window.emConfig.safeAreaInsets;
    const emTheme = useThemeStore((state) => state.emTheme);

    const handleClick = () => {
        onClick && onClick();
    };

    const colour = useMemo(() => {
        // if (emTheme === 'light') {
        //     if (active) {
        //         return css`color: #ebebeb;`;
        //     }

        //     return css`color: #ebebeb;`;
        // }

        if (emTheme === 'dark') {
            return css`
                color: #ebebeb;
            `;
        }

        if (emTheme === 'dark-plus') {
            return css`
                color: #f0f0f0;
            `;
        }
    }, [emTheme]);

    return (
        <Flex
            justify="center"
            align="center"
            onClick={handleClick}
            css={css`
                height: 100%;
                min-height: 100%;
                width: 100%;
                position: relative;
                padding-bottom: ${bottom}px;
                ${colour};

                & > * {
                    z-index: 1300;
                }

                &:last-child .menu-border {
                    display: none;
                }
            `}
        >
            {children}
            {(!active || emTheme !== 'light') && <BottomNavBorder />}
        </Flex>
    );
}
