import { supabase } from 'utils/supabase';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'routes/router';
import { useOnboardingStore, useThemeStore } from 'store/zustand';
import { useAccount } from './useAccount';
import { MessageType } from 'utils/webview';
import { useSupabaseUser } from './useSupabaseUser';
import { PostHog } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';

export function useWatchAuth() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentUserId, setCurrentUserId] = useThemeStore((state) => [state.currentUserId, state.setCurrentUserId]);
    const self = useAccount();
    const [setOnboarding, onboardingUsername] = useOnboardingStore((s) => [s.setAll, s.userName]);
    const hasEverSignedIn = window.emConfig.launchOptions?.hasSignedIn;
    const supabaseUser = useSupabaseUser();
    const posthog = usePostHog();

    // useEffect(() => {
    //     const userDataLoaded = supabaseUser.data && self.data;
    //     const userDataLoading = supabaseUser.isFetching || self.isFetching;

    //     if (!userDataLoaded && !userDataLoading) {
    //         supabaseUser.refetch().then(() => self.refetch());
    //     }

    // }, [currentUserId, self, setCurrentUserId, supabaseUser]);

    useEffect(() => {
        const path = window.emConfig.launchOptions?.deeplinkPath;
        // const path = 'https://easymanage.com.au/app/tenant-referral?tenant=tim&landlord=matt&code=389045';

        console.log('deeplink path', path);

        if (!path || path === '') {
            return;
        }

        const url = new URL(path);
        const params = url.searchParams;

        console.log('uu', url.pathname);

        const action = url.pathname.replace('/app', '');

        if (action === '/tenant-referral') {
            console.log('REF', path);
            const tenant = params.get('tenant');
            const landlord = params.get('landlord');
            const code = params.get('code');

            if (!tenant || !landlord || !code) {
                console.log({ tenant, landlord, code });
                return;
            }

            console.log('navving');

            // supabase.auth.signOut();

            setOnboarding({
                referredByName: landlord,
                userName: tenant,
                code,
                userType: 'tenant'
            });

            navigate(routes.tenantOnboarding.intro, { replace: true });
        }
    }, [navigate, setOnboarding]);

    useEffect(() => {
        const exclude = [routes.notEnabled, routes.signIn, routes.signUp, routes.root];

        if (self.data && !self?.isLoading && !self.data?.account_enabled && !exclude.includes(location.pathname)) {
            console.log('showing account-not-enabled page');
            navigate(routes.notEnabled);
        }
    }, [location.pathname, navigate, self, self.data, self?.isLoading]);

    useEffect(() => {
        // const tryAuth = () => {
        //     console.log('[useWatchAuth] attempting re-auth')
        //     supabase.auth.refreshSession().then((res) => {
        //         console.log('reauth', res);
        //     });
        // }

        if (!self.isFetched) {
            // tryAuth();
            console.log('[useWatchAuth] no self, exiting');
            return;
        } else if (!self?.data?.user_updateable?.[0]?.onboarding_skipped_at) {
            if (!self.data?.user_updateable?.[0]?.onboarding_done) {
                console.log(['[useWatchAuth] onboarding not done and not skipped']);
                // navigate(routes.root)
                return;
            }
        }

        // console.log('watch auth hook running');
        const sub = supabase.auth.onAuthStateChange((event, sess) => {
            // console.log('onAuthStateChange', event, sess);

            if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION') {
                // console.log('cuid1', currentUserId);
                // console.log({ event });
                // console.log({ sess, currentUserId });

                if (!sess?.user) {
                    // navigate(routes.signIn);
                    return;
                }

                posthog.identify(sess.user.id);

                if (!hasEverSignedIn) {
                    try {
                        // @ts-expect-error native
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                type: MessageType.signedIn
                            })
                        );
                    } catch (error) {
                        // console.log(error);
                    }
                }

                // if new session user !== existing session user
                if (sess?.user.id !== currentUserId) {
                    setCurrentUserId(currentUserId);

                    if (['/', '/sign-in'].includes(location.pathname)) {
                        navigate(routes.carousel.dashboard);
                    }
                }
            } else if (event === 'SIGNED_OUT') {
                setCurrentUserId(null);

                if (!onboardingUsername) {
                    navigate(routes.signIn);
                }
            }

            setCurrentUserId(sess?.user.id ?? null);
        });

        return () => {
            // console.log('killing sub');
            sub.data.subscription.unsubscribe();
        };
    }, [
        currentUserId,
        hasEverSignedIn,
        location.pathname,
        navigate,
        onboardingUsername,
        self.data?.user_updateable,
        self.isFetched,
        self.refetch,
        setCurrentUserId
    ]);
}
