import { TextInput } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { IconBriefcase, IconCreditCard, IconIdBadge, IconMail, IconPhone, IconUser } from '@tabler/icons-react';
import { DocumentUploadPill } from 'components/Onboarding/DocumentUploadPill';
import { routes } from 'routes/router';
import { OnboardingLayout } from 'layouts/Onboarding';
import { useSupabaseUser } from 'hooks/useSupabaseUser';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body, Footer, FooterButton } = OnboardingLayout;

export function TenantOnboardingApplicationInvite() {
    const me = useSupabaseUser();

    return (
        <Root headerProps={{ children: 'Invitation to apply', backButton: true }}>
            <Header>
                <HeaderTitle>You're invited to apply for a rental property</HeaderTitle>
                <HeaderSubtitle>
                    This is a secure and private application process. Your information will not be shared with anyone
                    other than the property owner.
                </HeaderSubtitle>
            </Header>
            <Body>
                <SummaryPill title="Property" subtitle="1234 Some Lane, Melbourne, 3213" />
                <SummaryPill title="Application due" subtitle="Saturday, December 12, 2022" />
            </Body>
            <Body>
                <TextInput placeholder="First name" rightSection={<IconUser />} />
                <TextInput placeholder="Last name" rightSection={<IconUser />} />
                <TextInput
                    readOnly
                    placeholder="Email address"
                    rightSection={<IconMail />}
                    value={me.data?.user?.email}
                />
                <TextInput placeholder="Phone number" rightSection={<IconPhone />} />
            </Body>

            <Body>
                <HeaderTitle>Document upload</HeaderTitle>
                <HeaderSubtitle>
                    To ensure a smooth onboarding process, please upload the following documents:
                </HeaderSubtitle>
                <Body>
                    <DocumentUploadPill icon={<IconIdBadge />}>Upload Driver's License</DocumentUploadPill>
                    <DocumentUploadPill icon={<IconCreditCard />}>Upload Bank Statements</DocumentUploadPill>
                    <DocumentUploadPill icon={<IconBriefcase />}>Upload Proof of Employment</DocumentUploadPill>
                </Body>
            </Body>

            <Footer>
                <FooterButton dest={routes.tenantOnboarding.reivewLeaseSummary}>Continue</FooterButton>
            </Footer>
        </Root>
    );
}
