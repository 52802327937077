import { Route, Routes } from 'react-router-dom';
import { TenantOnboardingSubroutes } from './routes';
import { TenantOnboardingIntro } from './Pages/intro';
import { TenantOnboardingApplicationInvite } from './Pages/applicationInvite';
import { TenantOnboardingReviewLeaseSummary } from './Pages/reviewLeaseSummary';
import { TenantOnboardingPayment } from './Pages/payment';
import { TenantOnboardingDone } from './Pages/done';
import { TenantOnboardingSignUp } from './Pages/sign-up';
import { TenantOnboardingConfirmAccount } from './Pages/confirm-account';
import { TenantOnboardingInviteCcode } from './Pages/invite-code';

export function TenantOnboarding() {
    return (
        <Routes>
            <Route path={TenantOnboardingSubroutes.intro} element={<TenantOnboardingIntro />} />
            <Route path={TenantOnboardingSubroutes.signUp} element={<TenantOnboardingSignUp />} />
            <Route path={TenantOnboardingSubroutes.inviteCode} element={<TenantOnboardingInviteCcode />} />
            <Route path={TenantOnboardingSubroutes.confirmAccount} element={<TenantOnboardingConfirmAccount />} />
            <Route path={TenantOnboardingSubroutes.applicationInvite} element={<TenantOnboardingApplicationInvite />} />
            <Route
                path={TenantOnboardingSubroutes.reivewLeaseSummary}
                element={<TenantOnboardingReviewLeaseSummary />}
            />
            <Route path={TenantOnboardingSubroutes.payment} element={<TenantOnboardingPayment />} />
            <Route path={TenantOnboardingSubroutes.done} element={<TenantOnboardingDone />} />
        </Routes>
    );
}
