import { css } from '@emotion/react';
import { Flex, Title, SegmentedControl, useMantineTheme, Group, Button, Stack, Text } from '@mantine/core';
import { IconPencil, IconRocket } from '@tabler/icons-react';
import { FAB } from 'components/FAB';
import { AuthedLayout } from 'layouts/authed';
import { ChatRow, ChatRowBody, ChatRowTitle } from 'pages/Chat/ChatRow';
import { useSupabaseConversations } from 'hooks/useSupabaseConversations';
import { WorkOrders } from 'pages/WorkOrders';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { useThemeStore } from 'store/zustand';
import { format } from 'date-fns';
import { AllChats } from 'components/Chat/all-chats';

enum ControlSection {
    MESSAGES = 'messages',
    TODOS = 'todos',
    WORKORDERS = 'work orders'
}

export function CarouselTasks() {
    const [controlSection, setControlSection] = useState<ControlSection>(ControlSection.MESSAGES);
    const theme = useMantineTheme();
    const emTheme = useThemeStore((state) => state.emTheme);
    const navigate = useNavigate();
    const supaConvos = useSupabaseConversations();

    const onClickControlSection = (controlSection: string) => {
        setControlSection(controlSection as ControlSection);
    };

    const bg = useMemo(() => {
        if (emTheme === 'light') {
            return 'default';
        } else if (emTheme === 'dark') {
            return theme.colors.gray[7];
        } else if (emTheme === 'dark-plus') {
            return theme.fn.gradient({ from: '#2a42bb', to: '#440b5a', deg: 45 });
        }
    }, [emTheme, theme.colors.gray, theme.fn]);

    const onFabClick = () => {
        const actions = {
            [ControlSection.MESSAGES]: () => navigate(routes.tasks.new),
            [ControlSection.TODOS]: () => navigate(routes.tasks.new),
            [ControlSection.WORKORDERS]: () => navigate(routes.workOrders.new)
        };

        actions[controlSection]();
    };

    return (
        <AuthedLayout>
            <Flex justify="center" align="center">
                <IconRocket color="#5d5dff" />
                <Title
                    color="#5d5dff"
                    css={css`
                        font-size: 1.5rem;
                    `}
                    ml="sm"
                >
                    Control Centre
                </Title>
            </Flex>
            <Group spacing={0} position="center" grow w="100%">
                <SegmentedControl
                    data={[
                        { label: 'Messages', value: 'messages' },
                        { label: 'Todos', value: 'todos' },
                        { label: 'Work Orders', value: 'work orders' }
                    ]}
                    onChange={onClickControlSection}
                    value={controlSection}
                    bg={bg}
                    color="default"
                    styles={{
                        label: {
                            color: emTheme === 'light' ? 'default' : 'white'
                        }
                    }}
                    css={(t) => css`
                        .mantine-SegmentedControl-indicator {
                            background-color: ${t.colors['em-purple'][3]};
                        }
                    `}
                />
            </Group>
            
            {controlSection === ControlSection.MESSAGES && <AllChats />}
            {controlSection === ControlSection.TODOS && <Text ta="center">No todos today!</Text>}
            {controlSection === ControlSection.WORKORDERS && <WorkOrders />}
            {/* <FAB onClick={onFabClick}>
                <IconPencil />
            </FAB> */}
        </AuthedLayout>
    );
}
