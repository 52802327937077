import { Button, ButtonProps, Text } from '@mantine/core';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';

type T = import("@mantine/utils").PolymorphicComponentProps<'button', ButtonProps>

export function TestButton(props: T) {
    const showTestButtonInTest = useFeatureFlagEnabled(FeatureFlag.ShowTestButtonsInTest) && import.meta.env.VITE_STAGE !== 'prod';

    if (!showTestButtonInTest) {
        return null;
    }

    return (
        <Button {...props}>
            {props.children}<Text size="xs" ml="xs">[test mode only]</Text>
        </Button>
    );
}
