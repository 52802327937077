import { useQuery } from '@tanstack/react-query';
import { supabase } from 'utils/supabase';

export function useGetTenantAccount(tenantId: number | null) {
    const query = async () => {
        if (!tenantId) {
            return null;
        }

        const { data } = await supabase.from('tenant_account').select('*, tenancy(*)').eq('id', tenantId).single();
        return data;
    };

    return useQuery({ queryKey: ['tenant_account', tenantId], queryFn: query });
}
