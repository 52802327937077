import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { Group, SelectProps, Select, SelectItem } from '@mantine/core';
import house1 from 'assets/house1.jpeg';
import { Tables } from 'types/supabase-types';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

const PropertySelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <img
                        src={image}
                        css={css`
                            height: 4rem;
                        `}
                    />
                </div>
                <div>
                    <div>{label}</div>
                    <div>{description}</div>
                </div>
            </Group>
        </div>
    )
);

interface Props extends Omit<SelectProps, 'data'> {
    properties: Tables<'Property'>[];
}

export const PropertySelect = (props: Props) => {
    const { properties, ...rest } = props;

    const selectData = useMemo(() => {
        if (!properties?.length) {
            return [];
        }

        return properties.filter(Boolean).map((p) => ({
            image: house1,
            label: p.niceName,
            description: `${p.streetNumber} ${p.streetName}`.trim(),
            value: String(p.id)
        })) as SelectItem[];
    }, [properties]);

    return (
        <Select
            label="Select a property to manage"
            placeholder="Tap here..."
            itemComponent={PropertySelectItem}
            {...rest}
            data={selectData}
        />
    );
};
