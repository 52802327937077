import { Flex } from '@mantine/core';
import { IconCalendar, IconDroplet, IconPower, IconWallet, IconWifi } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { SummaryTile } from 'components/Onboarding/SummaryTile';
import { OnboardingLayout } from 'layouts/Onboarding';
import { routes } from 'routes/router';

const { Root, Header, HeaderTitle, Body, Footer, FooterButton } = OnboardingLayout;

export function OwnerOnboardingLeaseSummary() {
    return (
        <Root headerProps={{ children: 'Lease summary', backButton: true }}>
            <Header>
                <HeaderTitle>Lease details</HeaderTitle>
            </Header>
            <Body>
                <Flex gap="md">
                    <SummaryTile icon={<IconCalendar />} title="12 months" subtitle="Rental Term" />
                    <SummaryTile icon={<IconWallet />} title="$4,000" subtitle="Monthly Rent" />
                </Flex>
            </Body>
            <Body>
                <HeaderTitle>Lease summary</HeaderTitle>
                <SummaryPill title="Lease term">12 months</SummaryPill>
                <SummaryPill title="Utilities" subtitle="Water, gas, electricity, and internet not provided.">
                    Tenant pays
                </SummaryPill>
                <SummaryPill title="Rent adjustments" subtitle="Increases at landlords discretion.">
                    Up to 10% per year
                </SummaryPill>
                <SummaryPill title="Entry" subtitle="Landlord has the right to enter the property with 3 day notice.">
                    3 day notice
                </SummaryPill>
            </Body>
            <Body>
                <HeaderTitle>Utilities and services</HeaderTitle>
                <Flex gap="md">
                    <SummaryTile icon={<IconDroplet />} title="$50" subtitle="Water" />
                    <SummaryTile icon={<IconPower />} title="$100" subtitle="Electricity" />
                    <SummaryTile icon={<IconWifi />} title="$50" subtitle="Internet" />
                </Flex>
            </Body>
            <Body>
                <HeaderTitle>Lease agreement</HeaderTitle>
                <SummaryPill
                    title="The unit is furnished. The landlord agrees to provide the following furniture and appliances:"
                    subtitle="Sofa, dining table, double bed, TV, washer, dryer, refrigerator, microwave."
                />
                <SummaryPill
                    title="Tenant's right to terminate"
                    subtitle="In the event of a sale of the property, tenant may terminate this lease by providing landlord at least 30 days prior written notice."
                />
            </Body>
            <Footer>
                <FooterButton dest={routes.ownerOnboarding.done}>Send lease to tenants</FooterButton>
            </Footer>
        </Root>
    );
}
