import { LoadingOverlay, Stack } from '@mantine/core';
import { IconArrowRight, IconPencil } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { TestButton } from 'components/TestButton';
import { useGetTenantAccounts } from 'hooks/owners/useGetTenantAccounts';
import { useGetTenancies } from 'hooks/useGetTenancies';
import { useGetProperty } from 'hooks/useProperty';
import { AuthedLayout } from 'layouts/authed';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/router';
import { FeatureFlag } from 'utils/feature-flags';

export function ManageTenantsLease() {
    const ycLeaseCreator = useFeatureFlagEnabled(FeatureFlag.YC.LeaseCreator);
    const tenantRemindersActive = useFeatureFlagEnabled(FeatureFlag.Manage.TenantReminders);
    const navigate = useNavigate();
    const propertyId = useSearchParams()[0].get('propertyId');
    const { data, isLoading } = useGetProperty(Number(propertyId));
    const { data: tenants } = useGetTenantAccounts(propertyId ? Number(propertyId) : null);
    const { data: tenancies } = useGetTenancies();

    if (!propertyId) {
        throw new Error();
    }

    return (
        <AuthedLayout showHeader showBack headerText={data?.niceName ?? ''}>
            <LoadingOverlay visible={isLoading} />
            {!!data && (
                <Stack>
                    <SummaryPill
                        title="Manage Tenants"
                        subtitle={`${tenants?.length} tenants`}
                        onClick={() =>
                            navigate({
                                pathname: routes.properties.manageTenants,
                                search: createSearchParams({ propertyId }).toString()
                            })
                        }
                    >
                        <IconPencil />
                    </SummaryPill>
                    <SummaryPill
                        title="Lease details"
                        subtitle={`${
                            tenancies?.filter((t) => String(t.property_id) === propertyId)?.length
                        } leases for this property`}
                        onClick={() =>
                            navigate({
                                pathname: routes.properties.leases,
                                search: createSearchParams({ propertyId }).toString()
                            })
                        }
                    >
                        <IconPencil />
                    </SummaryPill>
                    <SummaryPill title="Communicate with tenants" onClick={() => navigate({
                        pathname: routes.carousel.tasks
                    })}>
                        <IconArrowRight />
                    </SummaryPill>
                    {tenantRemindersActive && (
                        <SummaryPill title="Send a reminder to tenants">
                            <IconArrowRight />
                        </SummaryPill>
                    )}
                </Stack>
            )}
            {ycLeaseCreator && (
                <>
                    <TestButton onClick={() => navigate(routes.properties.newLeaseGenerator)}>
                        Create New Lease
                    </TestButton>
                </>
            )}
        </AuthedLayout>
    );
}
