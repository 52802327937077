import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetTenantAccounts } from 'hooks/owners/useGetTenantAccounts';
import { useGetTenancies } from 'hooks/useGetTenancies';
import { AuthedLayout } from 'layouts/authed';
import { useSearchParams } from 'react-router-dom';
import { moneyFormatter } from 'utils/money-formatter';

export function LeaseDetails() {
    const tenancyId = useSearchParams()[0].get('tenancyId');
    const { data: tenancies } = useGetTenancies();
    const tenancy = tenancies?.find((t) => t.id === Number(tenancyId));
    const tenantsAtProperty = useGetTenantAccounts(tenancy?.Property?.id ?? null);
    const tenantsOnLease = tenantsAtProperty?.data?.filter((t) => t.tenancy_id === Number(tenancyId));

    return (
        <AuthedLayout showHeader showBack headerText="Lease Details">
            {tenantsOnLease?.map((t, idx) => (
                <SummaryPill title={tenantsOnLease.length > 1 ? `Tenant ${idx}` : 'Tenant'}>
                    {t.display_name}
                </SummaryPill>
            ))}
            <SummaryPill title="Start date">{tenantsOnLease?.[0]?.start_date}</SummaryPill>
            <SummaryPill title="End date">{tenantsOnLease?.[0]?.end_date}</SummaryPill>
            <SummaryPill title="Rent amount">
                {moneyFormatter.format(
                    (tenantsOnLease?.reduce((acc, cur) => (cur.monthly_rent_cents ?? 0) + acc, 0) ?? 0) / 100
                )}
            </SummaryPill>
            <SummaryPill title="Bond amount">
                {moneyFormatter.format(
                    (tenantsOnLease?.reduce((acc, cur) => (cur.bond_amount_cents ?? 0) + acc, 0) ?? 0) / 100
                )}
            </SummaryPill>
            <SummaryPill title="Next payment date">-</SummaryPill>
            <SummaryPill title="Overdue fees">$0</SummaryPill>
        </AuthedLayout>
    );
}
