import { css } from '@emotion/react';
import { Stack, Text } from '@mantine/core';
import { IconSend } from '@tabler/icons-react';
import { RecipientChatBubble } from 'components/Chat/Bubble/recipient';
import { SenderChatBubble } from 'components/Chat/Bubble/sender';
import { ChatInput } from 'components/Chat/input';
import { AuthedLayout } from 'layouts/authed';
import { ChatDate } from 'components/Chat/date';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { isSameDay } from 'date-fns';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MessageType, registerListener, unregisterListener } from 'utils/webview';
import { ChatBubble } from 'components/Chat/Bubble';
import { PaymentSystemBubble } from 'components/Chat/Bubble/payment-system';
import { ChevronLeft } from 'tabler-icons-react';
import { useSupabaseMessages } from '../../hooks/useSupabaseMessages';
import { useThemeStore } from 'store/zustand';
import { supabase } from 'utils/supabase';

const AI_TOOLBOX_ID = '999';
const PAYMENT_SYSTEM_ID = '998';

export function ChatMessaging() {
    const location = useLocation();
    const conversationId = Number(location.pathname.split(routes.tasks.messaging).filter(Boolean)[0].replace('/', ''));
    const [chatVal, setChatVal] = useState<string>();
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const isNativeApp = window.emConfig.isNative;
    const { top, bottom } = window.emConfig.safeAreaInsets;
    const [jumpToBottomDone, setJumpToBottomDone] = useState(false);

    const userId = useThemeStore((s) => s.currentUserId);

    const supaMessages = useSupabaseMessages({ chatId: conversationId });

    console.log(chatContainerRef);

    useLayoutEffect(() => {
        console.log('last running', { supaMessages, jumpToBottomDone });

        if (!supaMessages?.length || jumpToBottomDone) {
            return;
        }

        // bad, but we're gonna do it anyway
        const t = setTimeout(() => {
            const bubbles = Array.from(document.querySelectorAll('.chat-bubble'));
            const last = bubbles?.pop() as HTMLDivElement;
            last?.scrollIntoView();
            console.log('last', last);
            last.style.background = 'red !important';
            setJumpToBottomDone(true);
        }, 200);

        return () => clearTimeout(t);
    }, [supaMessages, jumpToBottomDone]);

    const handleMessageSend = async (e: any) => {
        // alert('recv')
        e.preventDefault();
        inputContainerRef.current?.querySelector('textarea')?.focus();

        if (!chatVal || !userId) {
            return;
        }

        // const res = await sendMessageMutation({
        //     variables: {
        //         input: {
        //             content: chatVal,
        //             conversationId
        //         }
        //     }
        // });

        setChatVal('');

        // console.log(res);

        await supabase
            .from('tenancy_conversation_messages')
            .insert({ tenancy_conversation_id: conversationId, contents: chatVal, sent_by: userId });

        // document.querySelector('.auth-layout-inner-stack')?.scroll({ top: 999999 })
        setTimeout(() => {
            document.querySelector('.auth-layout-inner-stack')?.scrollTo({
                // top: (chatContainerRef.current?.getBoundingClientRect().bottom ?? 0),
                top: 10000000,
                behavior: 'smooth'
            });
        }, 250);
    };

    useEffect(() => {
        try {
            // @ts-expect-error native
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: MessageType.header,
                    data: {
                        action: 'show',
                        text: `Tenancy Chat [${conversationId}]`
                    }
                })
            );
        } catch (error) {
            console.log(error);
        }

        const handler = (e: any) => {
            if (e.action === 'goback') {
                navigate(-1);
            }
        };

        registerListener(MessageType.header, handler);

        return () => {
            try {
                // @ts-expect-error native
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: MessageType.header,
                        data: { action: 'hide' }
                    })
                );
            } catch (error) {
                console.log(error);
            }

            unregisterListener(MessageType.header, handler);
        };
    }, [navigate]);

    return (
        <AuthedLayout
            showBack
            showHeader={false}
            headerText="Chat Heading"
            _css={(t) => css`
                margin: 2px 4px;
                margin-top: ${-1 * top}px;
                height: calc(100% - 0.5rem);

                &:focus-within {
                    height: calc(100% + ${bottom}px - 2.5rem);
                }

                & .auth-layout-inner-stack {
                    height: 100%;
                }
            `}
            stackProps={{ id: 'chat-stack' }}
        >
            {!isNativeApp && (
                <div
                    css={css`
                        width: 100%;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: flex;
                    `}
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeft />
                    Back
                </div>
            )}
            <Stack w="100%" h="100%" spacing="0" ref={chatContainerRef} id="msg-stack">
                {supaMessages.length ? (
                    <div
                        css={(t) => css`
                            height: 100%;
                        `}
                    >
                        <ChatDate date={new Date(supaMessages?.[0]?.created_at)} showYear />
                        {supaMessages.map((m, idx) => {
                            const showFullDate =
                                supaMessages?.[idx + 1] &&
                                !isSameDay(new Date(supaMessages[idx + 1].created_at), new Date(m.created_at));

                            return (
                                <>
                                    {m.sent_by === AI_TOOLBOX_ID && (
                                        <ChatBubble
                                            time={new Date(m.created_at)}
                                            rootCss={css`
                                                width: 100%;
                                            `}
                                            contentCss={css`
                                                background-color: #007c74;
                                                color: white;
                                                text-align: left;
                                                width: 100%;
                                                text-align: center;
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                padding-bottom: 0.75rem;
                                            `}
                                            topAnnotation={
                                                <>
                                                    {' '}
                                                    - ✨ AI Toolbox ✨{' '}
                                                    {/* {m.onlySender && '- Only you can see this message'} */}
                                                </>
                                            }
                                        >
                                            {m.contents}
                                        </ChatBubble>
                                    )}
                                    <div
                                        css={css`
                                            width: 100%;
                                            display: flex;
                                            justify-content: flex-end;
                                        `}
                                    >
                                        {m.sent_by === userId && (
                                            <SenderChatBubble
                                                topAnnotation={` - ${m.sent_by?.slice(0, 7)}`}
                                                time={new Date(m.created_at)}
                                            >
                                                {m.contents}
                                            </SenderChatBubble>
                                        )}
                                    </div>
                                    {![userId, AI_TOOLBOX_ID, PAYMENT_SYSTEM_ID].includes(m.sent_by) && (
                                        <RecipientChatBubble
                                            topAnnotation={` - ${m.sent_by?.slice(0, 7)}`}
                                            time={new Date(m.created_at)}
                                        >
                                            {m.contents}
                                        </RecipientChatBubble>
                                    )}
                                    {m.sent_by === PAYMENT_SYSTEM_ID && (
                                        <PaymentSystemBubble time={new Date(m.created_at)}>
                                            {m.contents}
                                        </PaymentSystemBubble>
                                    )}
                                    {showFullDate && <ChatDate date={new Date(supaMessages[idx + 1].created_at)} />}
                                </>
                            );
                        })}
                    </div>
                ) : (
                    <Text align="center">No messages yet!</Text>
                )}

                <div
                    ref={inputContainerRef}
                    css={(t) => css`
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        display: flex;
                        align-items: center;
                        background: #491555;
                        touch-action: none;
                        height: ${t.other.chatInputTop};

                        & > * {
                            touch-action: none;
                        }

                        &:focus-within {
                            height: ${t.other.chatInputHeight};
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 10;
                            margin-bottom: ${bottom}px;

                            &:focus-within {
                                margin-bottom: 0;
                            }
                        `}
                    >
                        <ChatInput onChange={(val) => setChatVal(val)} value={chatVal} />
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-left: 0.5rem;
                            `}
                            onTouchEnd={handleMessageSend}
                        >
                            <IconSend />
                        </div>
                    </div>
                </div>
            </Stack>
        </AuthedLayout>
    );
}
