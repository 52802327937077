import { useQuery } from '@tanstack/react-query';
import { useThemeStore } from 'store/zustand';
import { supabase } from 'utils/supabase';

export function useSupabaseUser() {
    const setStoreUserId = useThemeStore(s => s.setCurrentUserId);

    const getSelf = async () => {
        const { data, error } = await supabase.auth.getUser();
        
        // if (error) {
        //     console.log(error);
        //     throw new Error(error.message);
        // }

        setStoreUserId(data.user?.id ?? null);

        return data;
    };

    return useQuery({ queryKey: ['supabaseSelf'], queryFn: getSelf });
}
