import { AuthedLayout } from 'layouts/authed';
import { Button, Text } from '@mantine/core';
import { supabase } from 'utils/supabase';
import { useThemeStore } from 'store/zustand';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';
import { useSupabaseUser } from 'hooks/useSupabaseUser';
import { MarketingOptInCheckbox } from 'components/MarketingOptIn';

export function TenantSettings() {
    const [userType, setUserType] = useThemeStore((state) => [state.userType, state.setUserType]);
    const navigate = useNavigate();
    const ycUserModeSetting = useFeatureFlagEnabled(FeatureFlag.YC.UserModeSetting);
    const u = useSupabaseUser().data?.user;

    const onLogOut = () => {
        console.log('logout');
        supabase.auth.signOut();
    };

    const onSwitchMode = () => {
        if (userType === 'owner') {
            setUserType('tenant');
            navigate(routes.tenant.dashboard);
        } else {
            setUserType('owner');
            navigate(routes.carousel.dashboard);
        }
    };

    return (
        <AuthedLayout showHeader headerText="Settings">
            <Text align="center" size="xs">
                <pre>
                    commit-id {import.meta.env.VITE_GIT_SHA}
                    <br />
                    client-version {import.meta.env.VÍITE_CLIENT_VERSION}
                    <br />
                    {window.emConfig.isNative
                        ? `${window.emConfig.device?.nativeAppVersion}-${window.emConfig.device?.nativeBuildVersion}-${window.emConfig.device?.releaseChannel}`
                        : 'not-native-app'}
                    <br />
                    {u?.email}
                    <br />
                    {u?.id}
                </pre>
            </Text>
            {ycUserModeSetting && (
                <Button onClick={onSwitchMode}>
                    Switch to {userType === 'owner' ? 'tenant' : 'owner'} profile [test mode only]
                </Button>
            )}
            <Button onClick={() => navigate(routes.deleteAccount)}>Request account deletion</Button>
            <MarketingOptInCheckbox />
            <Button onClick={onLogOut}>Log Out</Button>
        </AuthedLayout>
    );
}
