import { css } from '@emotion/react';
import { Divider, Grid, Group, Paper, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { IconChevronRight, IconClipboardText, IconTool, IconUsers } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetProperty } from 'hooks/useProperty';
import { AuthedLayout } from 'layouts/authed';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/router';

import { ArrowDownRight, ArrowUpRight, ArrowsExchange, IconProps } from 'tabler-icons-react';
import { FeatureFlag } from 'utils/feature-flags';

interface MetricTileProps {
    title: string;
    status: 'up' | 'down' | 'flat';
    colour: 'green' | 'red' | 'yellow';
    bigData: React.ReactNode;
    smallData: React.ReactNode;
    tag: React.ReactNode;
}

export function MetricTile(props: MetricTileProps) {
    const { title, status, colour, bigData, smallData, tag } = props;
    const theme = useMantineTheme();

    const tint = useMemo(() => {
        switch (colour) {
            case 'green':
                return theme.colors.green[5];
            case 'red':
                return theme.colors.red[5];
            case 'yellow':
                return theme.colors.yellow[5];
        }
    }, [colour, theme.colors.green, theme.colors.red, theme.colors.yellow]);

    const Arrow = useMemo(() => {
        switch (status) {
            case 'up':
                return (props?: IconProps) => <ArrowUpRight {...props} color={tint} />;
            case 'down':
                return (props?: IconProps) => <ArrowDownRight {...props} color={tint} />;
            case 'flat':
                return (props?: IconProps) => <ArrowsExchange {...props} color={tint} width="1rem" height="1rem" />;
        }
    }, [status, tint]);

    return (
        <Paper
            withBorder
            p="xs"
            radius="md"
            w="100%"
            bg="em-purple.8"
            css={(t) => css`
                &&& {
                    border-color: ${t.colors['em-purple'][9]};
                }
            `}
        >
            <Group position="apart">
                <Text
                    size="xs"
                    color="dimmed"
                    css={css`
                        font-weight: 700;
                        text-transform: uppercase;
                        flex: 5;
                        height: 2rem;
                        display: flex;
                        align-items: center;
                    `}
                >
                    {title}
                </Text>
                <Arrow style={{ flex: 1 }} />
            </Group>
            <Group align="flex-end" spacing="xs" mt={8}>
                <Text
                    css={css`
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 1;
                    `}
                >
                    {bigData}
                </Text>
            </Group>
            <Group mt="0.5rem">
                <Text
                    color={tint}
                    fz="sm"
                    fw={500}
                    css={css`
                        line-height: 1;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <span
                        css={css`
                            margin-right: 2px;
                        `}
                    >
                        {smallData}
                    </span>
                    <Arrow height="1.1rem" width="1.1rem" />
                </Text>
            </Group>
            <Text fz="xs" c="dimmed" mt={5} lh={1.2}>
                {tag}
            </Text>
        </Paper>
    );
}

export function PropertiesDetailed() {
    const [seachParams] = useSearchParams();
    const propertyId = Number(seachParams.get('propertyId'));
    const { data: property } = useGetProperty(propertyId);
    const navigate = useNavigate();
    const documentsEnabled = useFeatureFlagEnabled(FeatureFlag.Manage.Documents);
    const maintenanceEnabled = useFeatureFlagEnabled(FeatureFlag.Manage.Maintenance);

    return (
        <AuthedLayout showHeader headerText={property?.niceName ?? ''} showBack>
            <Stack spacing={0}>
                <Title size="h3">{property?.streetName}</Title>
                <Text variant="secondary">
                    {property?.state}, {property?.postcode}
                </Text>
                {/* <Button variant="secondary" mt="lg">
                    Edit Property
                </Button> */}
            </Stack>
            <Grid w="100%" m="0">
                <Grid.Col span={6}>
                    <MetricTile
                        title="Rental Income"
                        status="up"
                        colour="green"
                        bigData="$36,145"
                        smallData="+13%"
                        tag="Compared to previous year"
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <MetricTile
                        title="Rental Yield"
                        status="flat"
                        colour="yellow"
                        bigData="13%"
                        smallData="0%"
                        tag="Compared to previous year"
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <MetricTile
                        title="Maintenance"
                        status="down"
                        colour="green"
                        bigData="$3,123"
                        smallData="-18%"
                        tag="Compared to previous year"
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <MetricTile
                        title="Tenant Score"
                        status="up"
                        colour="green"
                        bigData={
                            <div>
                                8.7{' '}
                                <span
                                    css={css`
                                        font-size: 0.7rem;
                                    `}
                                >
                                    / 10
                                </span>
                            </div>
                        }
                        smallData="+1.8"
                        tag="Compared to previous year"
                    />
                </Grid.Col>
            </Grid>
            <Divider />
            <Stack>
                <SummaryPill
                    onClick={() =>
                        navigate({
                            pathname: routes.properties.lease,
                            search: createSearchParams({ propertyId: String(propertyId) }).toString()
                        })
                    }
                    title="Manage Tenants"
                    iconLeft={<IconUsers />}
                >
                    <IconChevronRight />
                </SummaryPill>
                {documentsEnabled && (
                    <SummaryPill title="Documents" iconLeft={<IconClipboardText />}>
                        <IconChevronRight />
                    </SummaryPill>
                )}
                {maintenanceEnabled && (
                    <SummaryPill title="Maintenance" iconLeft={<IconTool />}>
                        <IconChevronRight />
                    </SummaryPill>
                )}
            </Stack>
        </AuthedLayout>
    );
}
