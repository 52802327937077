import { RouteMap } from 'routes/router';

export const ownerOnboardingSubroutes = {
    intro: 'intro',
    address: 'address',
    propertyDetails: 'property-details',
    tenants: 'tenants',
    leaseGenerator: 'lease-generator',
    payment: 'payment',
    leaseSummary: 'lease-summary',
    done: 'done'
} as const satisfies RouteMap;
