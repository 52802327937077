export const DEFAULT_WEEKLY_RENT = 600;

export const leaseGenDefaults = {
    bodyCorporate: {
        applies: 'no'
    },
    rent: {
        amount: DEFAULT_WEEKLY_RENT,
        period: 'week'
    },
    bond: {
        amount: 2000
    },
    pets: {
        permitted: 'no'
    },
    maintenance: {
        preferredProvider: 'no'
    }
} as const;
