import { supabase } from 'utils/supabase';
import { useUserId } from './useUserId';
import { useQuery } from '@tanstack/react-query';

export function useGetAccountDeletions() {
    const userId = useUserId();

    const req = async () => {
        if (!userId) {
            return;
        }

        const { data } = await supabase.from('account_deletion_request').select('*').eq('user_id', userId);
        return data;
    };

    return useQuery({ queryKey: ['account_deletions', userId], queryFn: req });
}
