import { Loader } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';

interface BaseProps {
    title: string;
    subtitle: string;
    iconLeft?: React.ReactNode;
    loading?: boolean;
}

type WithOnClick = BaseProps & {
    onClick: () => void;
    dest?: never;
};

type WithDest = BaseProps & {
    onClick?: never;
    dest: string;
};

type Props = WithOnClick | WithDest;

export function OnboardingLayoutAltButton(props: Props) {
    const { title, subtitle, iconLeft, onClick, dest, loading } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        onClick ? onClick() : navigate(dest);
    };

    const leftIcon = () => {
        if (loading) {
            return <Loader color="gray" />;
        }

        if (iconLeft) {
            return iconLeft;
        }

        return <ChevronRight />;
    };

    return (
        <SummaryPill onClick={handleClick} title={title} subtitle={subtitle} iconLeft={leftIcon()} />
    );
}
