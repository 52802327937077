import { z } from 'zod';

export const newPropertySchema = z.object({
    startDate: z.date(),
    endDate: z.date(),

    rent: z.object({
        amount: z.number(),
        period: z.enum(['week', 'fortnight', 'month']),
        // dueOn: z.string(), // e.g. 11th of the month
        firstPaymentDue: z.date()
    }),
    bond: z.object({
        amount: z.number(),
        dueOn: z.date()
    }),

    specialTerms: z
        .object({
            included: z.string(),
            customIncluded: z.string()
        })
        .default({ included: 'no', customIncluded: 'no' }),

    propertyAddress: z.object({
        street: z.string(),
        suburb: z.string(),
        state: z.string(),
        postcode: z.string().optional()
    }),
    landlord: z.object({
        name: z.string()
    }),
    bodyCorporate: z
        .object({
            applies: z.string(),
            file: z.instanceof(Blob).optional()
        })
        .refine((val) => val.applies === 'no' || !!val.file, {
            message: 'Upload body corporate terms',
            path: ['file']
        }),
    pets: z
        .object({
            permitted: z.string(),
            description: z.string().optional()
        })
        .refine((val) => val.permitted === 'no' || !!val.description, {
            message: 'Description required',
            path: ['description']
        }),
    maintenance: z.object({
        preferredProvider: z.string()
    })
});

export const existingPropertySchema = z.object({
    propertyAddress: z.object({
        street: z.string(),
        suburb: z.string(),
        state: z.string(),
        postcode: z.string().optional()
    }),
    landlord: z.object({
        name: z.string()
    }),
    bodyCorporate: z
        .object({
            applies: z.string(),
            file: z.instanceof(Blob).optional()
        })
        .refine((val) => val.applies === 'no' || !!val.file, {
            message: 'Upload body corporate terms',
            path: ['file']
        }),
    pets: z
        .object({
            permitted: z.string(),
            description: z.string().optional()
        })
        .refine((val) => val.permitted === 'no' || !!val.description, {
            message: 'Description required',
            path: ['description']
        }),
    maintenance: z.object({
        preferredProvider: z.string()
    })
});

export type NewPropertyLeaseSchema = z.infer<typeof newPropertySchema>;
export type ExistingPropertyLeaseSchema = z.infer<typeof existingPropertySchema>;
