import { OnboardingLayoutBody } from './body';
import { OnboardingLayoutAltButton } from './button';
import { OnboardingFooter, OnboardingFooterButton } from './footer';
import { OnboardingHeader, OnboardingHeaderSubtitle, OnboardingHeaderTitle } from './header';
import { OnboardingLayoutRoot } from './root';
import { OnboardingLayoutSection } from './section';

export const OnboardingLayout = {
    Root: OnboardingLayoutRoot,
    Header: OnboardingHeader,
    HeaderTitle: OnboardingHeaderTitle,
    HeaderSubtitle: OnboardingHeaderSubtitle,
    Body: OnboardingLayoutBody,
    Section: OnboardingLayoutSection,
    Footer: OnboardingFooter,
    FooterButton: OnboardingFooterButton,
    AltButton: OnboardingLayoutAltButton
}
