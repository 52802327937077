import { css } from '@emotion/react';
import { Grid, Stack, Text, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';

interface Props {
    children: React.ReactNode;
    image: React.ReactNode;
    chatId: string;
    time: React.ReactNode;
}

export function ChatRow(props: Props) {
    const navigate = useNavigate();
    const { chatId, children, time } = props;

    return (
        <Grid key={chatId} onClick={() => navigate(`${routes.tasks.messaging}/${chatId}`)}>
            <Grid.Col span={2}>
                <div>
                    <img
                        css={css`
                            height: ${rem(56)};
                            width: ${rem(56)};
                            border-radius: 1000px;
                            background-color: green;
                            border: none;
                        `}
                    />
                </div>
            </Grid.Col>
            <Grid.Col span={8}>
                <Stack spacing={0}>{children}</Stack>
            </Grid.Col>
            <Grid.Col span={2}>
                <Text
                    variant="secondary"
                    css={css`
                        white-space: nowrap;
                    `}
                >
                    {time}
                </Text>
            </Grid.Col>
        </Grid>
    );
}

export function ChatRowTitle({ children }: React.PropsWithChildren) {
    return <Text>{children}</Text>;
}

export function ChatRowBody({ children }: React.PropsWithChildren) {
    return <Text variant="secondary">{children}</Text>;
}
