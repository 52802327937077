import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ButtonProps, Checkbox, LoadingOverlay, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useSetMarketingStatus } from 'hooks/useSetMarketingStatus';
import { useSupabaseUser } from 'hooks/useSupabaseUser';
import { useMemo, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useOnboardingStore, useThemeStore } from 'store/zustand';
import { makeRegisters } from 'utils/form-utils';
import { supabase } from 'utils/supabase';
import { z } from 'zod';

const schema = z.object({
    email: z.string().email(),
    password: z.string().min(6),
    otp: z.string().optional(),
    marketing: z.boolean()
});

type Schema = z.infer<typeof schema>;

interface Props {
    submitButtonProps?: ButtonProps;
    onSuccess?: () => void;
}

export function SignUp(props: Props) {
    const { submitButtonProps, onSuccess } = props;
    const formMethods = useForm<Schema>({
        resolver: zodResolver(schema),
        mode: 'onChange',
        defaultValues: {
            marketing: true
        }
    });
    const { handleSubmit, formState, watch } = formMethods;
    const { register, registerCheckbox } = makeRegisters(formMethods, {
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const setStoreUserId = useThemeStore((s) => s.setCurrentUserId);
    const supabaseUser = useSupabaseUser();
    const currentUser = supabaseUser.data?.user;
    const setMarketingStatus = useSetMarketingStatus();
    const onboardingStore = useOnboardingStore();

    const onError = (errors: FieldErrors<Schema>) => {
        if (currentUser) {
            onSubmit({
                email: 'stub',
                password: 'stub',
                marketing: true
            });
        }
    };

    const onSubmit = async (form: Schema) => {
        onboardingStore.setAll({
            ...onboardingStore,
            marketingOptIn: form.marketing
        });

        if (currentUser?.confirmed_at) {
            setStoreUserId(currentUser.id);
            return onSuccess?.();
        }

        const { email, password, otp } = form;

        if (!otp) {
            const regRes = await supabase.auth.signUp({ email, password });

            if (regRes.data.user?.id) {
                await setMarketingStatus.mutateAsync({ optIn: form.marketing });
                setStoreUserId(regRes.data.user.id);
                setShowOtp(true);
            }
        } else {
            const otpRes = await supabase.auth.verifyOtp({ email, token: otp, type: 'signup' });
            if (otpRes.error) {
                notifications.show({
                    title: 'Error',
                    message: otpRes.error.message,
                    color: 'red.8'
                });
            } else {
                setStoreUserId(otpRes.data.user?.id ?? null);
                setMarketingStatus.mutate({ optIn: form.marketing });
                onSuccess?.();
            }
        }
    };

    const content = useMemo(() => {
        // a verified user is already signed in
        if (currentUser?.confirmed_at) {
            return <>Welcome back, {currentUser.email}</>;
        }

        // if the user has submitted their email/password
        if (!showOtp) {
            return (
                <>
                    <TextInput {...register('email')} label="Email address" placeholder="you@email.com" type="email" />
                    <PasswordInput
                        {...register('password')}
                        label="Password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                    />
                    <Checkbox
                        {...registerCheckbox('marketing')}
                        label="Keep me up to date with updates relating to Easy Manage."
                        disabled={formState.isSubmitting}
                    />
                </>
            );
        }

        // if the user has submitted nothing
        return (
            <>
                <Text>Enter the code we sent to {watch('email')}</Text>
                <TextInput
                    {...register('otp')}
                    label="Confirmation code"
                    placeholder="123456"
                    disabled={formState.isSubmitting}
                    type="number"
                    inputMode="numeric"
                />
            </>
        );
    }, [currentUser, register, registerCheckbox, showOtp, watch, formState.isSubmitting]);

    const buttonText = useMemo(() => {
        if (currentUser?.confirmed_at) {
            return 'Continue';
        }

        if (showOtp) {
            return 'Submit';
        }

        return 'Sign up';
    }, [currentUser?.confirmed_at, showOtp]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit, onError)}
            css={css`
                width: 100%;
            `}
        >
            <LoadingOverlay overlayBlur={10} visible={currentUser === undefined} />
            <Stack>
                {content}
                <Button {...submitButtonProps} type="submit" loading={formState.isSubmitting} mt="md">
                    {buttonText}
                </Button>
            </Stack>
        </form>
    );
}
