import { Text } from '@mantine/core';
import BannerImage from 'assets/tenant-onboarding/pikaso_reimagine_digital-painting-A-young-hispanic-man-with-short-d.jpeg';
import { routes } from 'routes/router';
import { OnboardingLayout } from 'layouts/Onboarding';
import { useOnboardingStore } from 'store/zustand';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';

const { Root, Header, HeaderTitle, Body, Footer, FooterButton } = OnboardingLayout;

export function TenantOnboardingIntro() {
    const state = useOnboardingStore();
    const navigate = useNavigate();

    const onNext = () => {
        if (state.code) {
            navigate(routes.tenantOnboarding.signUp);
            return;
        }

        navigate(routes.tenantOnboarding.inviteCode);
    };

    return (
        <Root
            bannerImage={<img src={BannerImage} />}
            headerProps={{ backButton: true }}
        >
            <Header>
                <HeaderTitle>
                    Welcome to Easy Manage
                    {state.userName ? (
                        <>
                            ,{' '}
                            <span
                                css={css`
                                    white-space: nowrap;
                                `}
                            >
                                {state.userName}
                            </span>
                        </>
                    ) : null}
                    !
                </HeaderTitle>
            </Header>
            <Body>
                <Text>
                    Easy Manage is where you can pay rent, submit maintenance requests, and more. Let's get started.
                </Text>
            </Body>
            <Footer>
                <FooterButton onClick={onNext}>Get Started</FooterButton>
            </Footer>
        </Root>
    );
}
