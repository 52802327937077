import { NumberInput, TextInput, Textarea } from '@mantine/core';
import { OnboardingLayout } from 'layouts/Onboarding';
import { routes } from 'routes/router';
const { Root, Header, HeaderSubtitle, Body, Section, Footer, FooterButton, AltButton } = OnboardingLayout;

export function OwnerOnboardingPropertyDetails() {
    return (
        <Root headerProps={{ children: 'Property Details', backButton: true }} footerProps={{ atBottom: true }}>
            <Section>
                <Header>
                    <HeaderSubtitle>
                        Providing detailed information about your property is optional, but it can help us give you
                        better data, and help potential tenants make more informed decisions. <br />
                        <br />
                        You can skip this section and return to it at any time.
                    </HeaderSubtitle>
                </Header>
                <Body>
                    <AltButton
                        title="Skip for now"
                        subtitle="Add detailed property info later"
                        dest={routes.ownerOnboarding.tenants}
                    />
                    <TextInput label="Property type" placeholder="House, apartment, etc" />
                    <Textarea label="Amenitites" placeholder="Washing machine, dryer, pool, etc" />
                    <NumberInput label="Purchase price" placeholder="$500,000" />
                    <NumberInput label="Outstanding loan balance" placeholder="$400,000" />
                </Body>
            </Section>
            <Footer>
                <FooterButton dest={routes.ownerOnboarding.tenants}>Next</FooterButton>
            </Footer>
        </Root>
    );
}
