import { css } from '@emotion/react';
import { Stack, TextInput, Textarea, Select, Button } from '@mantine/core';
import { useGetTenancies } from 'hooks/useGetTenancies';
import { AuthedLayout } from 'layouts/authed';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';

export function NewMessage() {
    const navigate = useNavigate();
    const { data, isLoading } = useGetTenancies();

    const onSubmit = async () => {
        // createConversation.mutate({
        //     title: (document.querySelector('#chat-name') as HTMLInputElement).value
        // });

        navigate(routes.carousel.tasks);
    };

    return (
        <form
            css={css`
                width: 100%;
                height: 100%;
                min-height: 100vh;
            `}
        >
            <AuthedLayout showHeader showBack>
                <Stack
                    w="100%"
                    css={css`
                        width: 100%;
                        height: 100%;
                    `}
                >
                    <Select
                        label="Tenancy"
                        placeholder="Pick one..."
                        data={
                            data?.filter(Boolean).map((d) => `${d.Property?.niceName} (${d.Property?.streetName})`) ??
                            []
                        }
                    />
                    <TextInput label="Give this chat a name" placeholder="Burst water pipe!" id="chat-name" />
                    <Textarea
                        label="What's this conversation about?"
                        placeholder="I came home from work and found water everywhere!"
                    />
                    <Select
                        label="Priority"
                        placeholder="Pick one..."
                        data={[
                            { value: 'urgent', label: 'Urgent' },
                            { value: 'normal', label: 'Normal' },
                            { value: 'low', label: 'Low' }
                        ]}
                    />
                    <Button mt="3rem" onClick={onSubmit}>
                        Send
                    </Button>
                </Stack>
            </AuthedLayout>
        </form>
    );
}
