import { css } from '@emotion/react';
import { MantineThemeOverride, px, rem } from '@mantine/core';
import { EMConfig } from 'globals';

export const EmPurple = [
    '#DCCBF8',
    '#AC85F0',
    '#844BEA',
    '#4d12ec',
    '#4E15B4',
    '#3E118E',
    '#310E6F',
    '#270C58',
    '#1F0A45',
    '#171221'
] as const;

export const EmPurpleSecondary = [
    '#A376FB',
    '#612ACA',
    '#452B76',
    '#302447',
    '#160E26',
    '#0A0415',
    '#04000C',
    '#020007',
    '#010004',
    '#000002'
] as const;

interface ThemeConfig {
    safeAreaInsets: EMConfig['safeAreaInsets'];
}

// see App.tsx <Global /> for global mantine styles
export const makeTheme = (config: ThemeConfig): MantineThemeOverride => {
    const { safeAreaInsets } = config;
    const { top, bottom } = safeAreaInsets;

    return {
        components: {
            Button: {
                variants: {
                    danger: (theme) => ({
                        root: {
                            backgroundColor: theme.colors.red[9]
                        }
                    }),
                    secondary: (theme) => ({
                        root: {
                            backgroundColor: theme.colors['em-purple-secondary'][3],
                            color: 'white'
                        }
                    })
                },
                defaultProps: {
                    size: 'md',
                    fw: 'bold',
                    h: rem(48),
                    mih: rem(48),
                    w: '100%',
                    radius: rem(12)
                }
            },
            Text: {
                defaultProps: {
                    color: 'white'
                },
                variants: {
                    secondary: (theme) => ({
                        root: {
                            color: '#A694C7',
                            fontSize: theme.fontSizes.sm,
                            lineHeight: 1.35
                        }
                    })
                }
            },
            TextInput: {
                defaultProps: {
                    radius: 'md',
                    size: 'md',
                    w: '100%'
                    // onFocus: (ev) => ev.target.scrollIntoView({ behavior: 'smooth' })
                }
            },
            PasswordInput: {
                defaultProps: {
                    radius: 'md',
                    size: 'md',
                    w: '100%'
                }
            },
            Textarea: {
                defaultProps: {
                    radius: 'md',
                    size: 'md',
                    minRows: 3
                }
            },
            NumberInput: {
                defaultProps: {
                    radius: 'md',
                    size: 'md'
                }
            },
            Radio: {
                defaultProps: {
                    w: '100%',
                    labelPosition: 'left'
                }
            },
            PinInput: {
                defaultProps: {
                    length: 6
                }
            },
            Grid: {
                defaultProps: {
                    w: '100%',
                    m: 0
                }
            }
        },
        colors: {
            'em-purple': [...EmPurple],
            'em-purple-secondary': [...EmPurpleSecondary]
        },
        primaryColor: 'em-purple',
        primaryShade: 3,
        fontFamily: 'Manrope Variable',
        lineHeight: '24px',
        other: {
            appHeaderHeight: '2.5rem',
            appHeaderBottom: rem(px('2.5rem') + px(top)),
            bottomNavHeight: rem(px('3.5rem') + px(bottom)),
            chatInputTop: rem(px('4rem') + px(bottom)),
            chatInputHeight: '4rem'
        }
    };
};

export const darkTheme1: MantineThemeOverride = {
    colorScheme: 'dark',
    colors: {
        dark: [
            '#d5d7e0',
            '#acaebf',
            '#8c8fa3',
            '#666980',
            '#4d4f66',
            '#34354a',
            '#2b2c3d',
            '#1d1e30',
            '#0c0d21',
            '#01010a'
        ]
    }
};

const inputStyles = css`
    .mantine {
        &-InputWrapper-label {
            color: white;
            margin-bottom: ${rem(6)};
        }

        &-PasswordInput-innerInput {
            background-color: #271a3a;
            border-color: #453366;
            height: 3rem;
            color: white;
            font-size: 1rem;

            ::placeholder {
                color: #a694c7;
            }
        }

        &-TextInput,
        &-Textarea,
        &-NumberInput,
        &-Autocomplete,
        &-Select,
        &-DateInput,
        &-SegmentedControl,
        &-PasswordInput,
        &-Checkbox {
            &-input {
                background-color: #271a3a;
                border-color: #453366;
                height: 3rem;
                color: white;
                font-size: 1rem;

                ::placeholder {
                    color: #a694c7;
                }
            }

            &-rightSection {
                color: #a694c7;
            }

            &-label {
                font-size: 0.9rem;
                margin-bottom: 0.2rem;
            }
        }

        &-Checkbox {
            &-input {
                height: 1.5rem;
                width: 1.5rem;
            }

            &-inner {
                height: auto;
                width: auto;
            }

            &-body {
                height: auto;
                align-items: center;
            }

            &-label {
                margin: 0;
            }
        }

        &-Textarea {
            &-input {
                height: auto;
            }
        }

        &-SegmentedControl {
            &-root {
                background-color: #271a3a;
            }

            &-indicator {
                background-color: #524170;
            }

            &-label {
                margin-bottom: 0;
            }
        }
    }
`;

const radioStyles = css`
    .mantine-Radio {
        &-body {
            height: ${rem(56)};
            align-items: center;
            border: 1px solid #453d54;
            border-radius: ${rem(12)};
            justify-content: space-between;

            padding: 0 1rem;
        }

        &-labelWrapper {
            color: white;
            height: 100%;
            width: 100%;
        }

        &-label {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &-inner {
            align-self: center;
        }
    }
`;

const otherStyles = css`
    .mantine {
        &-Title {
            &-root {
                margin: 0;
            }
        }

        &-PinInput {
            &-root {
                justify-content: center;
            }

            &-wrapper {
                flex: 1;
            }

            &-input {
                height: ${rem(56)};
                width: ${rem(48)};

                background-color: ${EmPurpleSecondary[3]};
                border: 2px solid #453366;

                color: white;
                font-weight: bold;
                font-size: 1.2rem;
            }
        }

        &-Accordion {
            &-icon,
            &-label,
            &-chevron {
                color: white;
            }
        }
    }
`;

export const defaultThemeStyles = [radioStyles, inputStyles, otherStyles].map(css);
