import { css } from '@emotion/react';
import { Grid, GridProps, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'tabler-icons-react';

interface Props extends GridProps {
    children: React.ReactNode;
    sticky?: boolean;
    showGuidedMode?: boolean;
    guidedModeOnClick?: () => void;
    overrideBackLocation?: string;
    showBack?: boolean;
}

const iconClass = css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 5rem;
`;

export function AppHeader(props: Props) {
    const { children, showGuidedMode, guidedModeOnClick, overrideBackLocation, showBack, ...rest } = props;
    const navigate = useNavigate();
    const theme = useMantineTheme();

    return (
        <Grid
            css={css`
                width: 100vw;
                z-index: 1000;
                height: ${theme.other.appHeaderHeight};

                & > * {
                    padding-top: 0;
                }
            `}
            justify="space-between"
            align="center"
            {...rest}
        >
            <Grid.Col span={2}>
                {showBack && (
                    <div
                        css={css`
                            ${iconClass};
                            padding-right: 1rem;
                        `}
                        onClick={() => (overrideBackLocation ? navigate(overrideBackLocation) : navigate(-1))}
                    >
                        <ChevronLeft color="white" />
                    </div>
                )}
            </Grid.Col>
            <Grid.Col span={8} ta="center">
                <Title order={1} size="h4">{children ?? 'Easy Manage'}</Title>
            </Grid.Col>
            <Grid.Col span={2}>
                {showGuidedMode && (
                    <div css={iconClass} onClick={() => !!guidedModeOnClick && guidedModeOnClick()}>
                        <div
                            css={css`
                                font-size: 0.8rem;
                                /* background-color: #1692ff; */
                                padding: 8px 12px;
                                border-radius: 8px;
                                text-align: center;
                                line-height: 16px;
                                padding-right: 2rem;
                            `}
                        >
                            What's
                            <br />
                            this?
                        </div>
                    </div>
                )}
            </Grid.Col>
        </Grid>
    );
}
