import { useUserId } from './useUserId';
import { supabase } from 'utils/supabase';
import { useQuery } from '@tanstack/react-query';

export function useGetTenancies() {
    const userId = useUserId();

    const getTenancies = async () => {
        if (!userId) {
            return;
        }
        
        const { data } = await supabase.from('tenancy').select('*, Property(id, niceName, streetName)').eq('landlord_id', userId);
        return data;
    };

    return useQuery({ queryKey: ['tenancies'], queryFn: getTenancies });
}
