import { css } from '@emotion/react';
import { Group, useMantineTheme } from '@mantine/core';
import { useAccount } from 'hooks/useAccount';
import React from 'react';
import { useMemo } from 'react';
import { useThemeStore } from 'store/zustand';

interface Props {
    scrollPos: number;
    children: React.ReactNode;
}

export function BottomNavRoot(props: Props) {
    const { bottom } = window.emConfig.safeAreaInsets;
    const { scrollPos, children } = props;
    const emTheme = useThemeStore((state) => state.emTheme);
    const theme = useMantineTheme();
    const self = useAccount();

    const highlightBg = useMemo(() => {
        if (emTheme === 'light') {
            return css`
                background: linear-gradient(180deg, ${theme.colors.blue[5]} 0%, ${theme.colors.blue[3]} 65%);
            `;
        }

        if (emTheme === 'dark') {
            return css`
                background: #2c2c2c;
            `;
        }

        if (emTheme === 'dark-plus') {
            return css`
                background: linear-gradient(180deg, #001163 0%, #2c003e 80%);
            `;
        }
    }, [emTheme, theme.colors.blue]);

    const bg = useMemo(() => {
        if (emTheme === 'light') {
            return css`
                background: ${theme.colors.blue[3]};
            `;
        }

        if (emTheme === 'dark') {
            return css`
                background: #000000;
            `;
        }

        if (emTheme === 'dark-plus') {
            return css`
                background: #2c003e;
            `;
        }
    }, [emTheme, theme.colors.blue]);

    const numChildren = React.Children.count(children)

    if (!self?.data) {
        return null;
    }

    return (
        <div
            css={css`
                width: 100vw;
                z-index: 1000;
                position: absolute;
                bottom: 0;
                height: ${theme.other.bottomNavHeight};
                ${bg};
            `}
        >
            <div
                css={css`
                    position: absolute;
                    bottom: 0px;
                    /* background: red; */
                    ${highlightBg}
                    height: ${theme.other.bottomNavHeight};
                    z-index: 1100;
                    opacity: 1;
                    width: calc(100vw / ${numChildren});
                    left: calc(${(scrollPos / numChildren) * (numChildren - 1)} * 100vw);
                `}
            ></div>
            <Group
                grow
                spacing={0}
                css={css`
                    height: 100%;
                    min-height: 100%;
                    width: 100%;
                `}
            >
                {children}
            </Group>
        </div>
    );
}
