import { AddressType } from 'components/AutocompleteAddress';
import { FieldValues, UseFormProps, ValidationMode } from 'react-hook-form';
import { useForm } from 'react-hook-form';

type FormMethods<T extends FieldValues> = ReturnType<typeof useForm<T>>;
type FieldNames<T extends FieldValues> = Parameters<FormMethods<T>['register']>[0];
type Transformer<U, V> = (val: U) => V;

const transformStringValue: Transformer<any, string | undefined> = (val) => (val ? String(val) : undefined);
const transformDateValue: Transformer<any, Date | null> = (val) => (val ? new Date(val) : null);
const transformNumberValue: Transformer<any, number> = (val) => Number(val);
const transformAddressAutocompleteValue: Transformer<AddressType, AddressType> = (val) => val;

const getDescendantProp = (path: string, obj: Record<any, any>) =>
    path.split('.').reduce((acc, part) => acc && acc[part], obj);

type Args = Pick<UseFormProps, 'mode' | 'reValidateMode'>;

const shouldValidate = <T extends FieldValues>(
    args: Args | null | undefined,
    fieldName: FieldNames<T>,
    formMethods: FormMethods<T>
) => {
    console.log({ args, fieldName }, formMethods.formState.isSubmitted);

    if (formMethods.formState.isSubmitted) {
        if (args?.reValidateMode) {
            if (args?.reValidateMode !== 'onChange') {
                return false;
            }

            return true;
        }

        if (args?.mode) {
            if (args.mode === 'onChange' || args.mode === 'all') {
                return true;
            }
        }

        return false;
    } else {
        return args?.mode && ['all', 'onChange'].includes(args.mode);
    }
};

export const makeRegisters = <T extends FieldValues>(formMethods: FormMethods<T>, args: Args | null) => {
    const standardFns = (fieldName: FieldNames<T>, valPath?: string) => ({
        // onChange: formMethods.register(fieldName).onChange,
        //     formMethods.setValue(fieldName, valPath ? getDescendantProp(valPath, val) : val, {
        //         // shouldValidate: shouldValidate(args?.mode, formMethods),
        //         // shouldValidate: formMethods.register()
        //         shouldTouch: true,
        //         shouldDirty: true
        //     }),
        // onChange:
        onChange: (val: any) =>
            formMethods.setValue(fieldName, valPath ? getDescendantProp(valPath, val) : val, {
                shouldValidate: shouldValidate(args, fieldName, formMethods),
                shouldTouch: true,
                shouldDirty: true
            }),
        error: getDescendantProp(fieldName, formMethods.formState.errors)?.message,
        disabled: formMethods.formState.isSubmitting
    });

    const makeTransformer =
        <U, V>(fn: Transformer<U, V>, valPath?: string) =>
        (fieldName: FieldNames<T>) => ({
            value: fn(formMethods.watch(fieldName)),
            ...(valPath?.includes('checked') ? { checked: formMethods.watch(fieldName) === true } : null),
            ...standardFns(fieldName, valPath)
        });

    return {
        register: makeTransformer(transformStringValue, 'target.value'),
        registerSelect: makeTransformer(transformStringValue),
        registerDateInput: makeTransformer(transformDateValue),
        registerSegmentedControl: makeTransformer(transformStringValue),
        registerNumberInput: makeTransformer(transformNumberValue),
        registerAddressAutocomplete: makeTransformer(transformAddressAutocompleteValue),
        registerCheckbox: makeTransformer(transformStringValue, 'currentTarget.checked')
    };
};
