import { OnboardingLayout } from 'layouts/Onboarding';
import BannerImage from 'assets/owner-onboarding/pikaso_texttoimage_modern-flat-Panoramic-shot-of-a-woman-sitting-at-a.jpeg';
import { routes } from 'routes/router';
import { SignUp } from 'components/SignUp';
import { useNavigate } from 'react-router-dom';


export function OwnerOnboardingIntro() {
    const navigate = useNavigate();

    return (
        <OnboardingLayout.Root bannerImage={<img src={BannerImage} />} headerProps={{ backButton: true }}>
            <OnboardingLayout.Header>
                <OnboardingLayout.HeaderTitle>
                    You're just steps away from easily managing your home.
                </OnboardingLayout.HeaderTitle>
                <OnboardingLayout.HeaderSubtitle>
                    We'll walk you through the process, so you can get started with your home.
                </OnboardingLayout.HeaderSubtitle>
            </OnboardingLayout.Header>
            <OnboardingLayout.Section>
                <SignUp onSuccess={() => navigate(routes.ownerOnboarding.address)} />
            </OnboardingLayout.Section>
        </OnboardingLayout.Root>
    );
}
