import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Select, Text, TextInput, Textarea } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useCreateMaintenanceRequest } from 'hooks/tenants/useCreateMaintenanceRequest';
import { AuthedLayout } from 'layouts/authed';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { makeRegisters } from 'utils/form-utils';
import { z } from 'zod';

const schema = z.object({
    title: z.string(),
    contents: z.string(),
    priority: z.string()
});

type Schema = z.infer<typeof schema>;

export function TenantNewMaintenant() {
    const formMethods = useForm<Schema>({
        resolver: zodResolver(schema)
    });
    const { register, handleSubmit, formState, reset } = formMethods;
    const { registerSelect } = makeRegisters<Schema>(formMethods, null);
    const createRequest = useCreateMaintenanceRequest();
    const location = useLocation();
    const tenancyId = location.pathname.split('/').pop();
    const navigate = useNavigate();

    const onSubmit = async (form: Schema) => {
        console.log('submitting', form);
        await createRequest.mutateAsync({
            ...form,
            tenancy_id: Number(tenancyId)
        });

        notifications.show({
            title: 'Success!',
            message: '',
            color: 'green.8',
            onClose: () => navigate(routes.tenant.maintenance)
        });

        reset();
    };

    useEffect(() => () => notifications.clean(), []);

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <AuthedLayout showBack showHeader headerText="New Maintenance">
                <TextInput
                    {...register('title')}
                    disabled={formState.isSubmitting}
                    label="Title"
                    placeholder="Broken pipe"
                />
                <Textarea
                    {...register('contents')}
                    disabled={formState.isSubmitting}
                    label="Describe the request"
                    placeholder="The pipe under the kitchen sink is leaking"
                />
                <Select
                    {...registerSelect('priority')}
                    disabled={formState.isSubmitting}
                    label="Priority"
                    placeholder="Pick one..."
                    data={[
                        { value: 'urgent', label: 'Urgent' },
                        { value: 'normal', label: 'Normal' },
                        { value: 'low', label: 'Low' }
                    ]}
                />
                <Text variant="secondary">
                    If the issue is severe, please contact the property owner through chat or phone call.
                </Text>
                <Button loading={formState.isSubmitting} type="submit">
                    Submit request
                </Button>
            </AuthedLayout>
        </form>
    );
}
