import { useThemeStore } from 'store/zustand';

export function useUserId() {
    const userId = useThemeStore(s => s.currentUserId);

    if (!userId) {
        // throw new Error('no user id')
    }

    return userId;
}