import { RouteMap } from 'routes/router';

export const propertiesSubroutes = {
    carousel: '',
    edit: 'edit',
    detailed: 'detailed',
    manageTenants: 'manage-tenants',
    bills: 'bills',
    lease: 'lease',
    newLeaseGenerator: 'new-lease-generator',
    newLeaseConfirm: 'new-lease-confirm',
    ledger: 'ledger',
    addTenant: 'add-tenant',
    leases: 'leases',
    leaseDetails: 'lease-details',
    tenantDetails: 'tenant-details',
    reminders: 'reminders'
} as const satisfies RouteMap;
