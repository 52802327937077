import { Route, Routes, useNavigate } from 'react-router-dom';
import { workOrdersSubroutes } from './routes';
import { NewWorkOrder } from './new-work-order';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { Camera, Door, Droplet } from 'tabler-icons-react';
import React from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';
import { routes } from 'routes/router';
import { Text } from '@mantine/core';

interface TileProps {
    chatId: number;
    title: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    unreads?: number;
}

function WorkOrderTile(props: TileProps) {
    const { title, icon, children, unreads, chatId } = props;
    const navigate = useNavigate();

    return (
        <div
            css={css`
                display: flex;
                width: 100%;
                border-bottom: 1px solid #8282828b;
                /* border-radius: 8px; */
                align-items: center;
                /* height: 4.25rem; */
                color: white;
                padding: 0.5rem 0;

                &:first-child {
                    border-top: 1px solid #8282828b;
                }
            `}
            onClick={() => navigate(`${routes.tasks.messaging}/${chatId}`)}
        >
            <div
                css={css`
                    /* border-right: 1px solid blue; */
                    height: 100%;
                    width: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;

                    & > * {
                        height: 100%;
                        width: 100%;
                    }
                `}
            >
                {icon}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: space-between;
                    height: 100%;
                    padding: 0.2rem;
                    padding-bottom: 0.4rem;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 4px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                        `}
                    >
                        <div
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            {title}
                        </div>
                    </div>

                    <div
                        css={css`
                            font-size: 0.75rem;
                            line-height: 14px;
                            padding-left: 0.5rem;
                            font-style: italic;
                            display: flex;
                            gap: 2px;
                        `}
                    >
                        <div>{format(new Date(), 'io MMM')}</div>-<div>{format(new Date(), 'h:mmaaa')}</div>
                    </div>
                </div>
                <div
                    css={css`
                        font-size: 0.8rem;
                        line-height: 14px;
                    `}
                >
                    {children}
                </div>
            </div>
            {unreads && (
                <div
                    css={css`
                        height: 1.25rem;
                        width: 1.25rem;
                        border-radius: 1000px;
                        background-color: red;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        font-size: 0.75rem;

                        margin-left: 1rem;
                    `}
                >
                    {unreads}
                </div>
            )}
        </div>
    );
}

export function WorkOrders() {
    const ycWorkOrders = useFeatureFlagEnabled(FeatureFlag.YC.WorkOrders);

    return (
        <div
            css={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                /* gap: 1rem; */
            `}
        >
            {ycWorkOrders ? (
                <>
                    <WorkOrderTile title="Broken Pipe" icon={<Droplet />} unreads={1} chatId={9999}>
                        <b>Plumber</b>: Hey Matt, I can get around to your place some time tomorrow to fix that pipe.
                        Let me if...
                    </WorkOrderTile>
                    <WorkOrderTile title="New Door" icon={<Door />} chatId={9999}>
                        <b>Door Man</b>: Hey Matt, I can get around to your place some time tomorrow to fix that pipe.
                        Let me if...
                    </WorkOrderTile>
                    <WorkOrderTile title="Camera Installation" icon={<Camera />} chatId={9999}>
                        <b>Plumber</b>: Hey Matt, I can get around to your place some time tomorrow to fix that pipe.
                        Let me if...
                    </WorkOrderTile>
                </>
            ) : (
                <Text ta="center">No work orders today!</Text>
            )}
            <Routes>
                <Route path={workOrdersSubroutes.new} element={<NewWorkOrder />} />
            </Routes>
        </div>
    );
}
