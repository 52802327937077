import { RouteMap } from 'routes/router';

export const TenantOnboardingSubroutes = {
    intro: 'intro',
    signUp: 'sign-up',
    inviteCode: 'invite-code',
    confirmAccount: 'confirm-account',
    applicationInvite: 'application-invite',
    reivewLeaseSummary: 'review-lease-summary',
    payment: 'payment',
    done: 'done'
} as const satisfies RouteMap;
