import { OnboardingLayout } from 'layouts/Onboarding';
import BannerImage from 'assets/owner-onboarding/pikaso_texttoimage_modern-flat-A-quaint-log-cabin-with-a-wraparound-p.jpeg';
import { routes } from 'routes/router';
import { AddressType, AutocompleteAddress } from 'components/AutocompleteAddress';
import { useCreateProperty } from 'hooks/useCreateProperty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSetOnboardingSkipped } from 'hooks/owners/useSetOnboardingSkipped';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body, Section, Footer, FooterButton, AltButton } = OnboardingLayout;

export function OwnerOnboardingAddress() {
    const createProperty = useCreateProperty();
    const navigate = useNavigate();
    const [add, setAdd] = useState<AddressType>();
    const setSkipped = useSetOnboardingSkipped();
    const [loading, setLoading] = useState<boolean>(false);

    const onNext = async () => {
        setLoading(true);

        try {
            await createProperty.mutateAsync({
                streetName: add!.street,
                suburb: add!.suburb,
                postcode: add!.postcode,
                state: add!.state,
                niceName: 'My Home',
                updatedDate: new Date().toISOString()
            });
        } catch (error) {
            console.log('error');
        }

        setLoading(false);
        navigate(routes.ownerOnboarding.propertyDetails);
    };

    return (
        <Root
            bannerImage={<img src={BannerImage} />}
            headerProps={{ backButton: true, children: null }}
            footerProps={{ atBottom: true }}
        >
            <Section>
                <Header>
                    <HeaderTitle>Let's get started</HeaderTitle>
                    <HeaderSubtitle>Add your property details to start receiving applications.</HeaderSubtitle>
                </Header>
                <Body>
                    <AutocompleteAddress
                        onChange={setAdd}
                        value={add}
                        textInputProps={{ disabled: setSkipped.isPending }}
                        autocompleteProps={{ disabled: setSkipped.isPending }}
                    />
                </Body>
            </Section>
            <Footer>
                <FooterButton onClick={onNext} loading={loading} disabled={setSkipped.isPending}>
                    Continue
                </FooterButton>
                <AltButton
                    title="Skip for now"
                    subtitle="Add property info later"
                    onClick={() => setSkipped.mutate({ dest: routes.carousel.dashboard, stepSkippedAt: 'address' })}
                    loading={setSkipped.isPending}
                />
            </Footer>
        </Root>
    );
}
