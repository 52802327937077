import { Session } from '@supabase/supabase-js';
import { create } from 'zustand';

export const EMTheme = ['light', 'dark', 'dark-plus'] as const;
export type TEMTheme = (typeof EMTheme)[number];

export function isEmTheme(val: string): val is TEMTheme {
    return EMTheme.includes(val as any);
}

export const UserType = {
    owner: 'owner',
    tenant: 'tenant'
} as const;

export type UserType = keyof typeof UserType;

interface ThemeState {
    currentUserId: string | null;
    setCurrentUserId: (currentUserId: string | null) => void;
    emTheme: TEMTheme;
    setEmTheme: (theme: TEMTheme) => void;
    pageTop: number;
    setPageTop: (pageTop: number) => void;
    oauthSession: Session | null;
    setOAuthSession: (sess: Session) => void;
    userType: UserType;
    setUserType: (userType: UserType) => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
    currentUserId: null,
    setCurrentUserId: (currentUserId) => set({ currentUserId }),
    emTheme: 'dark-plus',
    setEmTheme: (theme: TEMTheme) => set(() => ({ emTheme: theme })),
    pageTop: 0,
    setPageTop: (pageTop: number) => set(() => ({ pageTop })),
    oauthSession: null,
    setOAuthSession: (sess: Session) => set(() => ({ oauthSession: sess })),
    userType: 'owner',
    setUserType: (userType: UserType) => set({ userType })
}));

interface OnboardingState {
    userType?: UserType;
    // setUserType: (userType: UserType) => void;
    referredByName?: string;
    // setReferredByName: (name: string) => void;
    userName?: string;
    // setUserName: (name: string) => void;
    code?: string;
    // setCode: (code: string) => void;
    setAll: ({ userType, userName, code, referredByName }: Omit<OnboardingState, 'setAll'>) => void;
    marketingOptIn?: boolean;
}

export const useOnboardingStore = create<OnboardingState>((set) => ({
    setAll: (args) => set({ ...args })
    // setUserType: (userType) => set({ userType }),
    // setUserName: (userName) => set({ userName }),
    // setReferredByName: (referredByName) => set({ referredByName }),
    // setCode: (code) => set({ code })
}));
