import React from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';

export interface Props {
    children: React.ReactNode;
    rootCss?: ReturnType<typeof css>;
    contentCss?: ReturnType<typeof css>;
    time: Date;
    topAnnotation?: React.ReactNode;
}

export function ChatBubble(props: Props) {
    const { children, rootCss, contentCss, time, topAnnotation } = props;

    return (
        <div
            className="chat-bubble"
            css={css`
                display: flex;
                flex-direction: column;
                padding: 0.35rem 0;
                line-height: 22px;
                width: 80%;
                ${rootCss}
            `}
        >
            <div css={css`
                font-size: 0.65rem;
                margin-bottom: -2px;
                margin-right: 4px;
            `}>
                {format(time, 'hh:mm aaa')}{topAnnotation}
            </div>
            <div css={css`
                padding: 0.35rem 1rem;
                border-radius: 20px;
                width: fit-content;
                ${contentCss}
            `}>
                {children}
            </div>
        </div>

    );
}
