import { PaymentOptions } from 'components/Onboarding/PaymentOptions';
import { OnboardingLayout } from 'layouts/Onboarding';
import { routes } from 'routes/router';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body, Section, Footer, FooterButton, AltButton } = OnboardingLayout;

export function OwnerOnboardingPayment() {
    return (
        <Root headerProps={{ children: 'Rent Payments', backButton: true }} footerProps={{ atBottom: true }}>
            <Section>
                <Header>
                    <HeaderTitle>Choose how you'd like to get paid</HeaderTitle>
                    <HeaderSubtitle>You can choose to receive payments via bank transfer or PayID.</HeaderSubtitle>
                    {/* <HeaderSubtitle>
                        Easy Manage takes a small 2.5% on all rental fees - saving you $3210 in property management fees!
                    </HeaderSubtitle> */}
                </Header>
                <Body>
                    <PaymentOptions showVerificationMessage />
                </Body>
            </Section>
            <Footer>
                <FooterButton dest={routes.ownerOnboarding.leaseSummary}>Continue</FooterButton>
                <AltButton title="Skip for now" subtitle="Add payment info later" dest={routes.ownerOnboarding.done} />
            </Footer>
        </Root>
    );
}
