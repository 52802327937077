import { css } from '@emotion/react';
import { format } from 'date-fns';

interface Props {
    date: Date;
    showYear?: boolean;
}

export function ChatDate(props: Props) {
    const { date, showYear } = props;

    return (
        <div css={css`
            width: 100%;
            text-align: center;
            font-size: 0.65rem;
            margin-top: 1rem;
            margin-bottom: 0.25rem;
        `}>
            { format(date, `ccc do MMM${showYear ? ', yyy' : ''}`) }
        </div>
    )
}