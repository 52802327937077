import { PinInput, Text } from '@mantine/core';
import { OnboardingLayout } from 'layouts/Onboarding';
import { routes } from 'routes/router';

const { Root, Header, HeaderTitle, Body, Footer, FooterButton } = OnboardingLayout;

export function TenantOnboardingConfirmAccount() {
    return (
        <Root headerProps={{ backButton: true, children: 'Verify your account' }}>
            <Header>
                <HeaderTitle>We've sent a 6-digit code to</HeaderTitle>
                <HeaderTitle>johndoe@gmail.com</HeaderTitle>
            </Header>
            <Body>
                <PinInput w="100%" oneTimeCode />

                <Text size="sm" color="#A694C7">
                    Didn't receive a code? Check spam, or resend
                </Text>
            </Body>
            <Footer>
                <FooterButton dest={routes.tenantOnboarding.applicationInvite}>
                    Confirm
                </FooterButton>
            </Footer>
        </Root>
    );
}
