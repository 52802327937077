import { css } from '@emotion/react';
import { ChatBubble, Props as ChatBubbleProps }  from '.';

type Props = ChatBubbleProps

export function RecipientChatBubble(props: Props) {
    return (
        <ChatBubble
            {...props}
            contentCss={css`
                background-color: #c661bf;
                color: white;
            `}
        />
    );
}
