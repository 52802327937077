import confetti from 'canvas-confetti';

export const launchConfetti = (angle: number, delay: number): NodeJS.Timeout => setTimeout(() => {
    confetti({
        zIndex: 2000,
        origin: {
            y: 0.7
        },
        spread: 35,
        angle
    });
}, delay);

export const sideConfetti = (duration: number): void => {
    const end = Date.now() + duration;

    const frame = () => {
        confetti({
            zIndex: 2000,
            particleCount: 2,
            angle: 80,
            spread: 35,
            origin: { x: 0 }
        });

        confetti({
            zIndex: 2000,
            particleCount: 2,
            angle: 100,
            spread: 35,
            origin: { x: 1 }
        });

        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    };

    frame();
};

