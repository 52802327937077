import { useEffect, useState } from 'react';
import { RootLayout } from 'layouts/root';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { Divider, LoadingOverlay, Text, Title, rem } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { ChevronRight } from 'tabler-icons-react';
import OwnerImg from 'assets/splash/pikaso_texttoimage_35mm-film-photography-A-modern-loft-in-the-city-un.jpeg';
import TenantImg from 'assets/splash/pikaso_texttoimage_35mm-film-photography-A-charming-wooden-cabin-surr.jpeg';
import { css } from '@emotion/react';
import { useThemeStore } from 'store/zustand';
import { useSupabaseUser } from 'hooks/useSupabaseUser';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';

export function Splash() {
    const navigate = useNavigate();
    const setUserId = useThemeStore((s) => s.setCurrentUserId);
    const hasEverSignedIn = window.emConfig.launchOptions?.hasSignedIn;
    const location = useLocation();
    const fromSignIn = location.state?.fromSignIn;
    const supabaseUser = useSupabaseUser();
    const skipToSignUp = useFeatureFlagEnabled(FeatureFlag.Onboarding.SkipToSignUp);
    const [skipToSignUpTimerOverride, setSkipToSignUpTimerOverride] = useState<boolean>(false);

    if (skipToSignUp) {
        navigate({
            pathname: routes.signIn,
            search: createSearchParams({
                view: 'signup'
            }).toString()
        });
    }

    useEffect(() => {
        if (hasEverSignedIn && !fromSignIn) {
            navigate(routes.signIn);
        }
    }, [fromSignIn, hasEverSignedIn, navigate]);

    useEffect(() => {
        if (supabaseUser.data?.user) {
            navigate(routes.carousel.dashboard);
        }
    }, [navigate, setUserId, supabaseUser.data?.user]);

    useEffect(() => {
        const t = setTimeout(() => {
            setSkipToSignUpTimerOverride(true);
        }, 6500);

        return () => clearTimeout(t);
    }, []);

    return (
        <RootLayout>
            <LoadingOverlay visible={skipToSignUp === undefined && !skipToSignUpTimerOverride} overlayBlur={10} />
            <Title ta="center" size="h2">
                Welcome to Easy Manage
            </Title>
            <Text ta="center">Select an option below to get started</Text>
            <Divider w="25%" my="sm" />
            <SummaryPill
                onClick={() => navigate(routes.ownerOnboarding.intro)}
                title={
                    <>
                        I am a <b>property owner</b>
                    </>
                }
                overrideLeft={
                    <img
                        src={OwnerImg}
                        css={css`
                            width: ${rem(120)};
                            height: ${rem(75)};
                        `}
                    />
                }
            >
                <ChevronRight />
            </SummaryPill>
            <SummaryPill
                onClick={() => navigate(routes.tenantOnboarding.intro)}
                title={
                    <>
                        I am a <b>tenant</b>
                    </>
                }
                overrideLeft={
                    <img
                        src={TenantImg}
                        css={css`
                            width: ${rem(120)};
                            height: ${rem(75)};
                        `}
                    />
                }
            >
                <ChevronRight />
            </SummaryPill>
            <Text variant="secondary" mt="xl" onClick={() => navigate(routes.signIn, { state: { showBack: true } })}>
                Already have an account?
                <span
                    css={css`
                        padding-left: 2px;
                        color: #85d0ff;
                    `}
                >
                    Sign in
                </span>
            </Text>
        </RootLayout>
    );
}
