import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { defaultThemeStyles, makeTheme } from './theme/theme';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { Global, css } from '@emotion/react';
import { useThemeStore } from 'store/zustand';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
    const safeAreaInsets = window.emConfig.safeAreaInsets;
    const { top, bottom } = safeAreaInsets;
    const device = window.emConfig.device;
    const emTheme = useThemeStore((state) => state.emTheme);
    const testFlagEnabled = useFeatureFlagEnabled(FeatureFlag.TestFlag);

    console.log({ testFlagEnabled });

    useEffect(() => {
        const root = document.getElementById('root');

        if (!root) {
            return;
        }

        if (emTheme === 'light') {
            root.style.backgroundColor = '#FFFFFF';
        } else if (emTheme === 'dark') {
            root.style.backgroundColor = '#1f1f1f';
        } else if (emTheme === 'dark-plus') {
            // root.style.backgroundColor = EmPurple[9];
            root.style.backgroundColor = '#0e0027';
        }

        try {
            // @ts-expect-error native
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'set_theme', data: emTheme }));
        } catch (error) {
            /* empty */
        }
    }, [emTheme]);

    useEffect(() => {
        const run = async () => {
            if (!device) {
                return;
            }

            const { pushToken, uniqueId, deviceType, deviceName, model, osBuildId, osVersion } = device;

            const data = {
                token: pushToken,
                deviceUniqueId: uniqueId,
                deviceType,
                deviceName,
                model,
                osBuildId,
                osVersion
            };

            // setD(JSON.stringify(data, null, 2));

            // if (pushToken) {
            //     try {
            //         await registerPushTokenMutation({
            //             variables: {
            //                 input: data
            //             }
            //         });
            //     } catch (error) {
            //         // setErr(String(error));
            //     }
            // }
        };

        run();
    }, [device]);

    useEffect(() => {
        window.addEventListener('message', (msg) => {
            if (![import.meta.env.VITE_APP_DEMO_URL, window.location.origin].includes(msg.origin)) {
                console.log('got message from bad source, ignoring', msg.origin, msg.source);
                return;
            }
        });
    }, []);

    const theme = makeTheme({ safeAreaInsets });

    return (
        <HelmetProvider>
            <Helmet>
                <title>Easy Manage</title>
            </Helmet>
            <Global styles={defaultThemeStyles} />
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{ ...theme, colorScheme: emTheme === 'light' ? 'light' : 'dark' }}
            >
                <Notifications
                    css={css`
                        margin-top: ${top}px;
                    `}
                    position="top-center"
                    zIndex={999999}
                />
                <QueryClientProvider client={queryClient}>
                    <div
                        css={css`
                            padding-top: ${top}px;
                            padding-bottom: ${bottom}px;
                            min-height: 100vh;
                            height: 100vh;
                            /* height: 200px; */
                            position: relative;
                            overflow: hidden;
                            overscroll-behavior: contain;
                            /* height: 100%; */

                            display: flex;

                            &:focus-within {
                                /* remove padding for bottom safe-zone when keyboard is open */
                                padding-bottom: 0px;
                            }
                        `}
                    >
                        {testFlagEnabled && 'Test Flag Enabled 😎'}
                        <RouterProvider router={router} />
                    </div>
                </QueryClientProvider>
            </MantineProvider>
        </HelmetProvider>
    );
}

export default App;
