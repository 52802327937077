import { useMutation } from '@tanstack/react-query';
import { useSupabaseUser } from 'hooks/useSupabaseUser';
import { TablesInsert } from 'types/supabase-types';
import { supabase } from 'utils/supabase';

export function useCreateTenantInvite() {
    const userId = useSupabaseUser().data?.user?.id;

    const createInvite = async (tenantInfo: Omit<TablesInsert<'tenant_invite'>, 'sent_by'>) => {
        if (!userId) {
            return;
        }

        const query = await supabase.from('tenant_invite').insert({
            sent_by: userId,
            ...tenantInfo
        });
        return query.data;
    };

    return useMutation({ mutationFn: createInvite });
}
