import { zodResolver } from '@hookform/resolvers/zod';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { RootLayout } from 'layouts/root';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { supabase } from 'utils/supabase';
import { z } from 'zod';

const schema = z
    .object({
        email: z.string(),
        otp: z.string(),
        password: z.string(),
        newPassword: z.string()
    })
    .refine((data) => data.password === data.newPassword);

type Schema = z.infer<typeof schema>;

export function ResetPasswordPage() {
    const location = useLocation();
    const emailFromState = location.state?.email;
    const navigate = useNavigate();

    const { register, handleSubmit, formState } = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: { email: emailFromState }
    });

    const showError = (msg: string) => {
        notifications.show({
            title: 'Error',
            message: msg,
            color: 'red.8'
        });
    };

    const onSubmit = async (form: Schema) => {
        const { password, otp, email } = form;
        const sess = await supabase.auth.verifyOtp({ email, type: 'recovery', token: otp });

        if (sess.error) {
            showError(sess.error.message);
            return;
        }

        const { data, error } = await supabase.auth.updateUser({ password });

        if (!error) {
            notifications.show({
                title: 'Password updated',
                message: '',
                color: 'green.8',
                onClose: () => navigate(routes.carousel.dashboard)
            });
        } else {
            showError(error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <RootLayout headerText="Reset Password" showBack>
                <TextInput
                    {...register('email')}
                    label="Email address"
                    placeholder="you@email.com"
                    {...(emailFromState ? { value: emailFromState, readOnly: true } : null)}
                />
                <TextInput {...register('otp')} label="Code from email" />
                <PasswordInput {...register('password')} label="New password" />
                <PasswordInput {...register('newPassword')} label="Confirm new password" />
                <Button mt="lg" loading={formState.isSubmitting} type="submit">
                    Submit
                </Button>
            </RootLayout>
        </form>
    );
}
