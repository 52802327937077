import { css } from '@emotion/react';
import { Flex, FlexProps, Stack, Text, rem } from '@mantine/core';
import React from 'react';

interface Props extends Omit<FlexProps, 'title'> {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    children?: React.ReactNode;
    iconLeft?: React.ReactNode;
    overrideLeft?: React.ReactNode;
    onClick?: () => void;
    small?: boolean;
}

export function SummaryPill(props: Props) {
    const { title, subtitle, children, iconLeft, overrideLeft, onClick, small, ...rest } = props;

    return (
        <Flex w="100%" align="center" gap={small ? 'sm' : 'md'} onClick={() => !!onClick && onClick()} {...rest}>
            {overrideLeft}
            {iconLeft && (
                <Flex
                    justify="center"
                    align="center"
                    css={css`
                        height: ${rem(small ? 32 : 48)};
                        width: ${rem(small ? 32 : 48)};
                        background-color: #302447;
                        border-radius: ${rem(8)};
                        color: white;

                        & > * {
                            height: ${rem(24)};
                            width: auto;
                        }
                    `}
                >
                    {iconLeft}
                </Flex>
            )}
            <Flex
                justify="space-between"
                align="center"
                w="100%"
                css={css`
                    flex: 1 1 0;
                `}
            >
                <Stack
                    spacing={0}
                    css={css`
                        flex: 20 1 auto;
                    `}
                >
                    <Text>{title}</Text>
                    {subtitle && (
                        <Text size="sm" color="#A694C7">
                            {subtitle}
                        </Text>
                    )}
                </Stack>
                {children && (
                    <Text
                        color="white"
                        ta="right"
                        css={css`
                            flex: 1 1 auto;
                        `}
                    >
                        {children}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
}

interface SummaryPillImageProps {
    children: string;
}

export function SummaryPillImage(props: SummaryPillImageProps) {
    const { children } = props;

    return (
        <div
            css={css`
                width: ${rem(115)};
                height: ${rem(65)};
                border-radius: 8px;
            `}
        >
            <img
                css={css`
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                `}
                src={children}
            />
        </div>
    );
}
