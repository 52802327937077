import { useQuery } from '@tanstack/react-query';
import { useUserId } from 'hooks/useUserId';
import { supabase } from 'utils/supabase';

export function useGetTenancy() {
    const userId = useUserId();

    const getTenancy = async () => {
        if (!userId) {
            return;
        }
        
        const { data, error } = await supabase.from('tenancy').select('*, "Property"(*), tenant_account(*)');
        if (error) {
            throw new Error(error.message);
        }

        return data;
    };

    return useQuery({ queryKey: ['tenancy'], queryFn: getTenancy });
}
