import { useMutation } from '@tanstack/react-query';
import { useThemeStore } from 'store/zustand';
import { TablesInsert } from 'types/supabase-types';
import { supabase } from 'utils/supabase';

export function useCreateProperty() {
    const userId = useThemeStore((s) => s.currentUserId);

    const createProperty = async (property: Omit<TablesInsert<'Property'>, 'userId'>) => {
        if (!userId) {
            return null;
        }

        const query = await supabase.from('Property').insert({ ...property, userId });
        return query.data;
    };

    return useMutation({ mutationFn: createProperty });
}
