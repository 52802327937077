import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { tenantSubroutes } from './routes';
import { TenantDashboard } from './Dashboard';
import { TenantDashboardLease } from './Dashboard/lease';
import { TenantManualRentPayment } from './Dashboard/manual-rent-payment';
import { TenantMaintenance } from './Dashboard/maintenance';
import { TenantNewMaintenant } from './Dashboard/new-maintenance';
import { TenantSettings } from './Dashboard/settings';
import { TenantMaintenanceDetailed } from './Dashboard/maintenance-detailed';
import { BottomNav } from 'components/BottomNav';
import { IconHome, IconMessage, IconSettings, IconTool } from '@tabler/icons-react';
import { routes } from 'routes/router';
import { TenantChat } from './Dashboard/chat';

export function Tenant() {
    const location = useLocation();
    const navigate = useNavigate();
    const RoutesWithMenu = [routes.tenant.dashboard, routes.tenant.chat, routes.tenant.maintenance, routes.tenant.settings];

    const scrollPos = () => RoutesWithMenu.indexOf(location.pathname) / 3; // why this

    return (
        <div>
            <Routes>
                <Route path={tenantSubroutes.vetting} element={<Tenant />} />
                <Route path={tenantSubroutes.dashboard} element={<TenantDashboard />} />
                <Route path={`${tenantSubroutes.lease}/*`} element={<TenantDashboardLease />} />
                <Route path={`${tenantSubroutes.manualRentPayment}/*`} element={<TenantManualRentPayment />} />
                <Route path={`${tenantSubroutes.maintenance}/*`} element={<TenantMaintenance />} />
                <Route path={`${tenantSubroutes.newMaintenance}/*`} element={<TenantNewMaintenant />} />
                <Route path={`${tenantSubroutes.maintenanceDetailed}/*`} element={<TenantMaintenanceDetailed />} />
                <Route path={tenantSubroutes.settings} element={<TenantSettings />} />
                <Route path={tenantSubroutes.chat} element={<TenantChat />} />
            </Routes>
            {RoutesWithMenu.includes(location.pathname) && (
                <BottomNav.Root scrollPos={scrollPos()}>
                    <BottomNav.Tile
                        onClick={() => navigate(routes.tenant.dashboard)}
                        active={location.pathname === routes.tenant.dashboard}
                    >
                        <IconHome />
                    </BottomNav.Tile>
                    <BottomNav.Tile
                        onClick={() => navigate(routes.tenant.chat)}
                        active={location.pathname === routes.tenant.chat}
                    >
                        <IconMessage />
                    </BottomNav.Tile>
                    <BottomNav.Tile
                        onClick={() => navigate(routes.tenant.maintenance)}
                        active={location.pathname === routes.tenant.maintenance}
                    >
                        <IconTool />
                    </BottomNav.Tile>
                    <BottomNav.Tile
                        onClick={() => navigate(routes.tenant.settings)}
                        active={location.pathname === routes.tenant.settings}
                    >
                        <IconSettings />
                    </BottomNav.Tile>
                </BottomNav.Root>
            )}
        </div>
    );
}
