import { css } from '@emotion/react';
import { ChatBubble, Props as ChatBubbleProps } from '.';

type Props = ChatBubbleProps;

export function SenderChatBubble(props: Props) {
    return (
        <ChatBubble
            {...props}
            rootCss={css`
                align-items: flex-end;
            `}
            contentCss={css`
                background-color: #525dff;
                color: white;
                text-align: left;
            `}
        />
    );
}
