export enum MessageType {
    set_height = 'set_height',
    keyboard = 'keyboard',
    header = 'header',
    oauth = 'oauth',
    signedIn = 'signInSuccess',
    DEBUG__removeSignedIn = 'DEBUG__removeSignInSuccess'
}

export interface Message {
    type: MessageType;
    body?: string;
}

export interface ReceivedMessage<T = any> {
    type: MessageType;
    body: T;
}

type F<T> = (msg: ReceivedMessage<T>['body']) => void;
type R<T> = Record<MessageType, Set<F<T>>>;
type P<T> = Partial<R<T>>;
const callbacks: P<any> = {};

export const registerListener = <T>(messageType: MessageType, func: F<T>): void => {
    if (!(messageType in callbacks)) {
        callbacks[messageType] = new Set();
    }

    console.log(`Adding ${func.name} as a callback for ${messageType} events`);

    callbacks[messageType]!.add(func);
};

export const unregisterListener = <T>(InputMessageType: MessageType, func: F<T>): void => {
    const cbs = callbacks[InputMessageType];

    if (cbs) {
        cbs.delete(func);
    }
};

const globalWebviewHandler = (message: ReceivedMessage) => {
    const { type, body } = message;

    console.log('=== NATIVE MOBILE DEVICE EVENT START ===');
    console.log('type', type);
    console.log('body', body);
    console.log('=== NATIVE MOBILE DEVICE EVENT END ===');

    const cbs = callbacks[type];
    if (cbs) {
        cbs.forEach((func) => func(body));
    }
};

// @ts-expect-error global
window.globalWebviewHandler = globalWebviewHandler;
