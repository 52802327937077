import { supabase } from 'utils/supabase';
import { useQuery } from '@tanstack/react-query';

export function useGetProperty(id: number) {
    const getPropertyById = async () => {
        const { data } = await supabase.from('Property').select('*').eq('id', id).single();
        return data;
    };

    return useQuery({ queryKey: ['property', id], queryFn: getPropertyById });
}
