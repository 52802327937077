import { supabase } from 'utils/supabase';
import { useUserId } from './useUserId';
import { useMutation } from '@tanstack/react-query';

export function useRequestAccountDeletion() {
    const userId = useUserId();

    const req = async () => {
        if (!userId) {
            return;
        }

        const query = await supabase.from('account_deletion_request').insert({
            user_id: userId
        });

        return query.data;
    };

    return useMutation({ mutationFn: req });
}
