import { Divider, Flex, Progress, Stack, Text, Title } from '@mantine/core';
import { IconCalendar, IconChevronRight, IconReceipt } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { differenceInDays, format } from 'date-fns';
import { TenantHooks } from 'hooks/tenants';
import { AuthedLayout } from 'layouts/authed';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { moneyFormatter } from 'utils/money-formatter';

const formatDate = (date?: string | null) => (date ? format(new Date(date), 'd LLL yyyy') : '');
const daysAsPercent = (start?: string | null, end?: string | null) => {
    if (start && end) {
        const dStart = new Date(start);
        const dEnd = new Date(end);
        const now = new Date();

        const daysInLease = differenceInDays(dEnd, dStart);
        const daysUntilEnd = differenceInDays(dEnd, now);

        console.log({ dStart, dEnd, now, daysInLease, daysUntilEnd });

        return (100 * (daysInLease - daysUntilEnd)) / daysInLease;
    }

    return 0;
};

export function TenantDashboardLease() {
    const navigate = useNavigate();
    const { data, isLoading } = TenantHooks.useGetTenancy();
    const location = useLocation();
    const propertyId = Number(location.pathname.split('/').pop());
    const tenancy = data?.find((p) => p.property_id === propertyId);

    const start = tenancy?.tenant_account?.[0]?.start_date;
    const end = tenancy?.tenant_account?.[0]?.end_date;

    const monthlyRentDollars = (tenancy?.tenant_account?.[0]?.monthly_rent_cents ?? 0) / 100;

    return (
        <AuthedLayout showBack showHeader headerText="Lease">
            <Title size="h3">Overview</Title>
            <Text>
                {tenancy?.Property?.streetNumber} {tenancy?.Property?.streetName}
            </Text>
            <Divider />
            <SummaryPill
                title="1 year lease"
                subtitle={
                    <>
                        {formatDate(start)} - {formatDate(end)}
                    </>
                }
                iconLeft={<IconReceipt />}
            />
            <Stack>
                <Flex justify="space-between">
                    <Text>Progress</Text>
                    <Text>{Math.round(daysAsPercent(start, end))}%</Text>
                </Flex>
                <Progress value={daysAsPercent(start, end)} />
            </Stack>
            <Title size="h3">Next rent payment</Title>
            <SummaryPill
                iconLeft={<IconCalendar />}
                title={moneyFormatter.format(monthlyRentDollars)}
                subtitle="Due on 01/01/2024"
                onClick={() => navigate(routes.tenant.manualRentPayment)}
            >
                <IconChevronRight />
            </SummaryPill>
            <Title size="h3">Lease documents</Title>
            <SummaryPill title="Review lease conditions">
                <IconChevronRight />
            </SummaryPill>
        </AuthedLayout>
    );
}
