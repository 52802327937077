import { Button, Text, Textarea, Title } from '@mantine/core';
import { IconClock } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetMaintenanceRequests } from 'hooks/tenants/useGetMaintenanceRequests';
import { AuthedLayout } from 'layouts/authed';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';

export function TenantMaintenanceDetailed() {
    const location = useLocation();
    const requestId = location.pathname.split('/').pop();
    const { data } = useGetMaintenanceRequests();
    const request = data?.find((r) => r.id === Number(requestId));
    const navigate = useNavigate();

    return (
        <AuthedLayout showBack showHeader headerText={request?.title ?? ''}>
            <Title size="h3">Request details</Title>
            <SummaryPill
                iconLeft={<IconClock />}
                title="Submitted on"
                subtitle={new Date(request?.created_at ?? -1).toLocaleDateString()}
            />
            <Textarea readOnly value={request?.contents ?? ''} />
            <Text variant="secondary">
                Want to get an update from the property owner?
                <span>Go to chat</span> and send them a message.
            </Text>
        </AuthedLayout>
    );
}
