import { css, useTheme } from '@emotion/react';
import { Text, SegmentedControl, SegmentedControlProps } from '@mantine/core';

interface Props extends SegmentedControlProps {
    error?: string;
    label?: React.ReactNode;
    defaultValue?: any;
}

export function EMSegmentedControl(props: Props) {
    const { error, label, ...rest } = props;
    const theme = useTheme();

    return (
        <div>
            {!!label && (
                <Text size="sm" fw={500}>
                    {label}
                </Text>
            )}
            <SegmentedControl
                {...rest}
                w="100%"
                css={css`
                    &.mantine-SegmentedControl-root {
                        border: ${error ? `1px solid ${theme.colors.red[7]} !important` : 'inherit'};
                    }

                    & .mantine-SegmentedControl-label {
                        color: ${error ? theme.colors.red[7] : 'inherit'};
                    }
                `}
            />
            {!!error && (
                <Text size="xs" color="red">
                    {error}
                </Text>
            )}
        </div>
    );
}
