import { QueryData } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { supabase } from 'utils/supabase';

interface Props {
    chatId: number;
}

type QueryReturn = QueryData<ReturnType<typeof query>>;

// issue getting user data for other participants here
const query = () => supabase.from('tenancy_conversation_messages').select('*, sent_by');

export function useSupabaseMessages(props: Props): QueryReturn {
    const { chatId } = props;
    const [messages, setMessages] = useState<QueryReturn>([]);

    useEffect(() => {
        const getData = async () => {
            const { data } = await query().order('created_at', { ascending: true }).eq('tenancy_conversation_id', chatId);
            setMessages(data ?? []);
        };

        const chan = supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public'
                },
                (payload) => {
                    getData(); // this is lazy @tim
                }
            )
            .subscribe();

        getData();

        return () => {
            chan.unsubscribe();
        };
    }, [chatId]);

    console.log({ messages });

    return messages;
}
