import { css } from '@emotion/react';
import { AuthedLayout } from 'layouts/authed';
import { useNavigate } from 'react-router-dom';
import { LeaseGeneratorForm } from 'components/LeaseGenerator';

export function NewLeaseGenerator() {
    return (
        <AuthedLayout
            _css={css`
                overflow: scroll;

                .auth-layout-inner-stack {
                    padding-top: 0;
                }
            `}
            showHeader
            showBack
            headerText="AI Lease Generator"
        >
            <LeaseGeneratorForm  />
        </AuthedLayout>
    );
}
