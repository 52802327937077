import { css } from '@emotion/react';
import { Modal, Text, Divider, rem, Stack, ModalProps } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import OwnerImg from 'assets/splash/pikaso_texttoimage_35mm-film-photography-A-modern-loft-in-the-city-un.jpeg';
import TenantImg from 'assets/splash/pikaso_texttoimage_35mm-film-photography-A-charming-wooden-cabin-surr.jpeg';
import { ChevronRight } from 'tabler-icons-react';
import { supabase } from 'utils/supabase';

export function AuthedSplashModal(props: ModalProps) {
    const navigate = useNavigate();

    const onSignOut = () => {
        supabase.auth.signOut();
        navigate(routes.signIn);
    }

    return (
        <Modal
            {...props}
            withCloseButton={false}
            centered
            overlayProps={{
                blur: 3
            }}
        >
            <Stack align="center">
                <Text ta="center">Select an option below to get started</Text>
                <Divider w="25%" my="sm" />
                <SummaryPill
                    onClick={() => navigate(routes.ownerOnboarding.intro)}
                    title={
                        <>
                            I am a{' '}
                            <b
                                css={css`
                                    white-space: nowrap;
                                `}
                            >
                                property owner
                            </b>
                        </>
                    }
                    overrideLeft={
                        <img
                            src={OwnerImg}
                            css={css`
                                width: ${rem(120)};
                                height: ${rem(75)};
                            `}
                        />
                    }
                >
                    <ChevronRight />
                </SummaryPill>
                <SummaryPill
                    onClick={() => navigate(routes.tenantOnboarding.intro)}
                    title={
                        <>
                            I am a <b>tenant</b>
                        </>
                    }
                    overrideLeft={
                        <img
                            src={TenantImg}
                            css={css`
                                width: ${rem(120)};
                                height: ${rem(75)};
                            `}
                        />
                    }
                >
                    <ChevronRight />
                </SummaryPill>
                <Text mt="lg" variant="secondary" onClick={onSignOut}>
                    Sign out
                </Text>
            </Stack>
        </Modal>
    );
}
