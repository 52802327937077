import { Button, Flex, TextInput } from '@mantine/core';
import BannerImage from 'assets/tenant-onboarding/pikaso_texttoimage_modern-flat-Panoramic-view-of-a-stylish-twostory-a.jpeg';
import { OnboardingLayout } from 'layouts/Onboarding';
import { routes } from 'routes/router';
import { NoCodeModal } from './no-code-modal';
import { useState } from 'react';
import { useOnboardingStore } from 'store/zustand';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { makeRegisters } from 'utils/form-utils';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body, FooterButton } = OnboardingLayout;

const schema = z.object({
    code: z.string()
});

type Schema = z.infer<typeof schema>;

export function TenantOnboardingInviteCcode() {
    const [open, setOpen] = useState<boolean>(false);
    const state = useOnboardingStore();
    const formMethods = useForm<Schema>({ resolver: zodResolver(schema) });
    const { handleSubmit } = formMethods;
    const { register } = makeRegisters(formMethods, null);
    const navigate = useNavigate();

    const onNext = ({ code }: Schema) => {
        state.setAll({ ...state, code });
        navigate(routes.tenantOnboarding.signUp);
    };

    return (
        <form onSubmit={handleSubmit(onNext)}>
            <Root headerProps={{ backButton: true }} bannerImage={<img src={BannerImage} />}>
                <Header>
                    <HeaderTitle ta="center">Got an invite code?</HeaderTitle>
                    <HeaderSubtitle ta="center">
                        If a property owner has invited you to rent their property, enter their invite code here.
                    </HeaderSubtitle>
                </Header>
                <Body>
                    <TextInput {...register('code')} placeholder="Invite Code" />
                </Body>
                <Body>
                    <NoCodeModal opened={open} onClose={() => setOpen(false)} />
                    <Flex gap="sm">
                        <Button variant="secondary" onClick={() => setOpen(true)}>
                            I don't have a code
                        </Button>
                        <FooterButton type="submit">Next</FooterButton>
                    </Flex>
                </Body>
            </Root>
        </form>
    );
}
