import { css } from '@emotion/react';
import { Grid, Skeleton, Stack, Title, rem } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import EasyManageLogo from 'assets/Logo-Purple-Full.svg';
import { useNavigate } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
    headerProps?: {
        hidden?: boolean;
        children?: React.ReactNode;
        backButton?: boolean;
        backAction?: () => void;
    };
    footerProps?: {
        atBottom?: boolean;
    };
    bannerImage?: React.ReactNode;
}

const HEADER_HEIGHT_PX = 56;

export function OnboardingLayoutRoot(props: Props) {
    const { children, headerProps, bannerImage, footerProps } = props;
    const navigate = useNavigate();

    const onBack = () => {
        if (!headerProps?.backButton) {
            return;
        }

        headerProps?.backAction ? headerProps.backAction() : navigate(-1);
    }

    return (
        <Stack
            justify="flex-start"
            align="center"
            w="100%"
            css={css`
                overflow-y: scroll !important;
                overflow-x: hidden !important;
                min-height: 100%;
                gap: 0;
            `}
        >
            {!headerProps?.hidden && (
                <Grid
                    css={css`
                        width: 100%;
                        margin: 0 0.5rem 0.5rem 0.5rem;
                    `}
                >
                    <Grid.Col
                        span={2}
                        onClick={onBack}
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        {headerProps?.backButton && <IconChevronLeft />}
                    </Grid.Col>
                    <Grid.Col
                        span={8}
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: ${rem(HEADER_HEIGHT_PX)};
                            position: relative;
                        `}
                    >
                        {typeof headerProps?.children !== 'undefined' ? (
                            <Title size="h4">{headerProps?.children}</Title>
                        ) : (
                            <>
                                <Skeleton
                                    pos="absolute"
                                    height="100%"
                                    css={css`
                                        background-color: transparent;

                                        &::before,
                                        &::after {
                                            background-color: transparent;
                                        }
                                    `}
                                />
                                <img
                                    src={EasyManageLogo}
                                    css={css`
                                        height: auto;
                                        width: auto;
                                        height: 100%;
                                        position: relative;
                                        z-index: 2;
                                    `}
                                />
                            </>
                        )}
                    </Grid.Col>
                </Grid>
            )}
            {bannerImage && (
                <div
                    css={css`
                        width: 100%;
                        height: 25vh;
                        position: relative;

                        & > * {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }

                        & > img {
                            position: relative;
                            z-index: 2;
                        }
                    `}
                >
                    <Skeleton pos="absolute" />
                    {bannerImage}
                </div>
            )}
            <Stack w="100%" h="100%" p="md" justify={footerProps?.atBottom ? 'space-between' : 'normal'}>
                {children}
            </Stack>
        </Stack>
    );
}
