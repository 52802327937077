import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, AutocompleteItem, TextInput, Text, Stack, TextInputProps, AutocompleteProps } from '@mantine/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { throttle } from 'throttle-debounce';
import { z } from 'zod';

interface Props {
    onChange?: (address: AddressType) => void;
    value?: AddressType;
    label?: string;
    error?: string;
    textInputProps?: TextInputProps;
    autocompleteProps?: Omit<AutocompleteProps, 'data'>;
}

const addressSchema = z.object({
    street: z.string(),
    suburb: z.string(),
    state: z.string(),
    postcode: z.string().optional()
});

export type AddressType = z.infer<typeof addressSchema>;

export function AutocompleteAddress(props: Props) {
    const { onChange, value, label, error, textInputProps, autocompleteProps } = props;

    const [manualAddress, setManualAddress] = useState<boolean>(false);
    const [addressOptions, setAddressOptions] = useState<string[]>([]);
    const [fullAddressData, setFullAddressData] = useState<any[]>([]);
    const { register, watch, setValue, formState } = useForm<AddressType>({
        resolver: zodResolver(addressSchema),
        defaultValues: { ...value }
    });

    const throttledAddressSearch = throttle(1000, async (searchTerm: string) => {
        const addressUrl = new URL('https://maps.googleapis.com/maps/api/place/autocomplete/json');
        addressUrl.searchParams.append('input', searchTerm);
        addressUrl.searchParams.append('components', 'country:au');
        // this is bad, don't do this
        addressUrl.searchParams.append('key', 'AIzaSyB3veoyfITMXpoXRpunCfkhWg6cjj0uz1g');

        // quick hack cause backend isn't set up yet
        const base64AddressUrl = btoa(addressUrl.toString());
        const proxyBase = 'https://2dmasginy7.execute-api.ap-southeast-2.amazonaws.com';
        const url = `${proxyBase}/${base64AddressUrl}`;

        try {
            const res = await fetch(url);
            const json = (await res.json()).data;
            const addresses = json.predictions.map((p: any) => p.description);
            console.log('addresses', addresses, json);

            setAddressOptions(addresses);
            setFullAddressData(json.predictions);
        } catch (error) {
            console.log('error getting addresses', error);
        }
    });

    const handleAutocompleteAddressSubmit = (item: AutocompleteItem) => {
        const address = fullAddressData.find((ad) => ad.description === item.value);

        // just use aus api pls
        const street = address.structured_formatting.main_text;
        const suburb = address.terms[address.terms.length - 3].value;
        const state = address.terms[address.terms.length - 2].value;

        setValue('street', street);
        setValue('suburb', suburb);
        setValue('state', state);

        console.log(watch());
        !!onChange && onChange(watch());
    };

    console.log(JSON.stringify(addressOptions));

    return (
        <div>
            {manualAddress ? (
                <Stack>
                    <TextInput
                        label="Street Address"
                        placeholder="42 Wallaby Way"
                        w="100%"
                        error={formState.errors.street?.message}
                        {...register('street')}
                        {...textInputProps}
                    />
                    <TextInput
                        label="Suburb"
                        placeholder="Syndey"
                        w="100%"
                        error={formState.errors.suburb?.message}
                        {...register('suburb')}
                        {...textInputProps}
                    />
                    <TextInput label="State" placeholder="NSW" w="100%" {...register('state')} {...textInputProps} />
                    <TextInput
                        label="Postcode"
                        placeholder="2000"
                        w="100%"
                        {...register('postcode')}
                        {...textInputProps}
                    />
                    <Text
                        size="sm"
                        color="teal.3"
                        css={css`
                            margin-top: 8px;
                        `}
                        onClick={() => setManualAddress(false)}
                        {...textInputProps}
                    >
                        Tap here to use address autocomplete mode
                    </Text>
                </Stack>
            ) : (
                <>
                    <Autocomplete
                        w="100%"
                        label={label ?? 'Address'}
                        data={addressOptions}
                        onChange={(val) => throttledAddressSearch(val)}
                        onItemSubmit={handleAutocompleteAddressSubmit}
                        error={error ?? formState.errors?.root?.message}
                        placeholder="42 Wallaby Way, Sydney"
                        filter={() => true}
                        {...autocompleteProps}
                    />
                    <Text
                        size="sm"
                        color="teal.3"
                        css={css`
                            margin-top: 8px;
                        `}
                        onClick={() => setManualAddress(true)}
                    >
                        Tap here to enter address manually
                    </Text>
                </>
            )}
        </div>
    );
}
