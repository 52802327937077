import { Button, ButtonProps, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export function OnboardingFooter({ children }: React.PropsWithChildren) {
    return (
        <Stack mt="sm" pb="lg" spacing="lg">
            {children}
        </Stack>
    );
}

type Props = ButtonProps & {
    dest?: string;
    onClick?: () => void;
};

export function OnboardingFooterButton(props: Props) {
    const { dest, onClick, ...rest } = props;
    const navigate = useNavigate();

    if (rest.type === 'submit') {
        return <Button {...rest} />;
    }

    if (dest) {
        return <Button {...rest} onClick={() => navigate(dest)} />;
    }

    return <Button {...rest} onClick={onClick} />;
}
