import BannerImage from 'assets/tenant-onboarding/pikaso_texttoimage_modern-flat-A-panoramic-view-of-a-twostory-house-s.jpeg';
import { SignUp } from 'components/SignUp';
import { OnboardingLayout } from 'layouts/Onboarding';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { useOnboardingStore } from 'store/zustand';

const { Root, Header, HeaderTitle, HeaderSubtitle, Body } = OnboardingLayout;

export function TenantOnboardingSignUp() {
    const state = useOnboardingStore();
    const navigate = useNavigate();

    return (
        <Root headerProps={{ backButton: true }} bannerImage={<img src={BannerImage} />}>
            <Header>
                <HeaderTitle ta="center">
                    {state.referredByName ? `${state.referredByName} has invited you` : "You've been invited"} to rent a
                    property on Easy Manage
                </HeaderTitle>
                <HeaderSubtitle ta="center">
                    Create an account to get started.
                </HeaderSubtitle>
            </Header>
            <Body>
               <SignUp onSuccess={() => navigate(routes.tenantOnboarding.applicationInvite)} />
            </Body>
        </Root>
    );
}
