import { css } from '@emotion/react';
import { Button, Divider, LoadingOverlay, Table } from '@mantine/core';
import { addWeeks, format, subWeeks } from 'date-fns';
import { AuthedLayout } from 'layouts/authed';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AlertCircle, CircleCheck, CircleDashed } from 'tabler-icons-react';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { offset } from '@floating-ui/dom';
import { useLayoutEffect, useState } from 'react';
import { routes } from 'routes/router';
import { useGetAllProperties } from 'hooks/useGetAllProperties';
import { notifications } from '@mantine/notifications';

const formatDate = (date: Date) => format(date, 'dd MMM yyyy');

const startDate = subWeeks(new Date(), 38);

enum Status {
    PAID,
    PENDING,
    FAILED
}

const fakePayments = new Array(36).fill(0).map((_, i) => ({
    date: addWeeks(startDate, i),
    amount: 550,
    status: Status.PAID
}));

fakePayments[fakePayments.length - 1].status = Status.PENDING;
fakePayments[fakePayments.length - 2].status = Status.FAILED;
fakePayments.sort((a, b) => b.date.getTime() - a.date.getTime());

const IconColourMap = {
    [Status.PAID]: '#0ed600',
    [Status.PENDING]: '#fae500',
    [Status.FAILED]: '#e81f00'
};

const StatusIcon = (props: { status: Status }) => {
    const { status } = props;

    return (
        <div
            css={css`
                color: ${IconColourMap[status]};
                display: flex;
                justify-content: center;
                align-items: center;
            `}
        >
            {status === Status.PAID && <CircleCheck />}
            {status === Status.PENDING && <CircleDashed />}
            {status === Status.FAILED && <AlertCircle />}
        </div>
    );
};

const maybePluralise = (word: string, count: number) => (count === 1 ? word : `${word}s`);

const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
        scrollTo: true,
        scrollToHandler: (el: HTMLElement) => {
            const yOffset = -20;
            const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ behavior: 'smooth', top: y });
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 18 })]
        },
        cancelIcon: {
            enabled: true
        },
        when: {
            show() {
                const currentStep = Shepherd.activeTour?.getCurrentStep();
                if (!currentStep) {
                    return;
                }

                const currentStepElement = currentStep?.getElement();

                if (!currentStepElement) {
                    return;
                }

                const footer = currentStepElement?.querySelector('.shepherd-footer');
                const progress = document.createElement('span');
                progress.className = 'shepherd-progress';

                const idx = Shepherd.activeTour?.steps.indexOf(currentStep);
                if (typeof idx !== 'number') {
                    return;
                }

                progress.innerText = `${idx + 1} of ${Shepherd.activeTour?.steps.length}`;
                footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'));
            }
        }
    }
});

tour.addStep({
    id: 'step-1',
    text: `See tenant payment history at a quick glance.`,
    attachTo: {
        element: '.step-1',
        on: 'bottom'
    },
    buttons: [
        {
            text: 'Next',
            action: tour.next
        }
    ]
});

tour.addStep({
    id: 'step-1point5',
    text: `Tap the 'enquire' button to draft a message to the tenant, using AI toolbox, to resolve any missed rental payments.`,
    attachTo: {
        element: '.step-1point5',
        on: 'bottom'
    },
    buttons: [
        {
            text: 'Back',
            action: tour.back
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
});

tour.addStep({
    id: 'step-2',
    text: `Export a full rental ledger for this property. The ledger includes all information required for compliance.`,
    attachTo: {
        element: '.step-2',
        on: 'bottom'
    },
    buttons: [
        {
            text: 'Back',
            action: tour.back
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
});

tour.addStep({
    id: 'step-3',
    text: `Keep track of all rental payments. The status icon in the right-side column indicates whether rent has been paid or not.`,
    attachTo: {
        element: '.step-3',
        on: 'top'
    },
    buttons: [
        {
            text: 'Back',
            action: tour.back
        },
        {
            text: 'Done',
            action: tour.complete
        }
    ]
});

export function ManageTenantsRentLedger() {
    const { data, isLoading } = useGetAllProperties();
    const searchParams = useSearchParams()[0];
    const autoStartTour = searchParams.get('autoStartTour') === 'true';
    const propertyId = searchParams.get('propertyId');
    const property = data?.find((p) => p?.id === Number(propertyId));
    const navigate = useNavigate();

    const failed = fakePayments.filter((p) => p.status === Status.FAILED);
    const pending = fakePayments.filter((p) => p.status === Status.PENDING);
    const cleared = fakePayments.filter((p) => p.status === Status.PAID);

    const [exportLoading, setExportLoading] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (!data?.[0]?.id) {
            return;
        }

        if (autoStartTour) {
            tour.start();
        }
    }, [autoStartTour, data]);

    const onExport = () => {
        setExportLoading(true);
        setTimeout(() => {
            setExportLoading(false);
            notifications.show({
                title: 'Success!',
                message: 'The full ledger export has been sent to your email address.',
                color: 'green.8'
            });
        }, 1250);
    };

    return (
        <AuthedLayout
            showHeader
            showBack
            showGuidedHeader
            guidedHeaderOnClick={() => tour.start()}
            headerText="Rent Ledger"
            overrideBackLocation={autoStartTour ? routes.carousel.manage : undefined}
        >
            <LoadingOverlay visible={isLoading} />
            {!!property && (
                <div
                    css={css`
                        width: 100%;
                        max-height: calc(100vh - 10rem);
                        /* overflow: scroll; */
                    `}
                >
                    <div
                        className="step-1"
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;

                            & > * {
                                height: 2rem;
                                align-items: center;
                            }
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    gap: 0.5rem;
                                `}
                            >
                                <StatusIcon status={Status.FAILED} />
                                {failed.length} failed {maybePluralise('payment', failed.length)}
                            </div>
                            <div className="step-1point5">
                                <Button h="2rem" mih="2rem" fz="0.9rem" onClick={() => navigate(routes.carousel.tasks)}>
                                    Enquire
                                </Button>
                            </div>
                        </div>

                        <div
                            css={css`
                                display: flex;
                                gap: 0.5rem;
                            `}
                        >
                            <StatusIcon status={Status.PENDING} />
                            {pending.length} pending {maybePluralise('payment', pending.length)}
                        </div>
                        <div
                            css={css`
                                display: flex;
                                gap: 0.5rem;
                            `}
                        >
                            <StatusIcon status={Status.PAID} />
                            {cleared.length} cleared {maybePluralise('payment', cleared.length)}
                        </div>
                    </div>
                    <Divider
                        css={css`
                            margin: 1rem 0;
                        `}
                    />
                    <div
                        className="step-2"
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        `}
                    >
                        <Button
                            css={css`
                                margin: 0 auto;
                                width: 75%;
                            `}
                            onClick={onExport}
                            loading={exportLoading}
                        >
                            Export
                        </Button>
                        <div
                            css={css`
                                font-size: 0.75rem;
                                font-style: italic;
                                color: #9b9b9b;
                                margin-top: 0.5rem;
                            `}
                        >
                            Full export contains all information required for a compliant rental ledger. Including date
                            paid, date received, receipt number, amount paid, payment method, and what the payment
                            covers (e.g. rent, water). The export can be a PDF or Excel Spreedsheet, and can be saved to
                            your device or emailed to you.
                        </div>
                    </div>
                    <Divider
                        css={css`
                            margin: 1rem 0;
                        `}
                    />
                    <div
                        className="step-3"
                        css={css`
                            overflow: scroll;
                            height: 100%;
                            padding-bottom: 75vh;
                        `}
                    >
                        <Table
                            css={css`
                                font-family: 'Noto Sans Mono Variable';
                                text-align: center;
                            `}
                        >
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                            {fakePayments.map((p) => (
                                <tr>
                                    <td>{formatDate(p.date)}</td>
                                    <td>${p.amount.toFixed(2)}</td>
                                    <td>
                                        <StatusIcon status={p.status} />
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </div>
            )}
        </AuthedLayout>
    );
}
