import { css } from '@emotion/react';
import { Divider } from '@mantine/core';
import { AuthedLayout } from 'layouts/authed';
import { useLocation } from 'react-router-dom';
import { BuildingBank, BuildingSkyscraper, Droplet, FileCertificate, ReceiptTax } from 'tabler-icons-react';
import { addDays, format } from 'date-fns';

interface BillLineItemProps {
    icon: React.ReactNode;
    amount: number;
    title: string;
    date: Date;
}

function BillLineItem(props: BillLineItemProps) {
    const { icon, amount, title, date } = props;

    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                margin: 0.75rem 0;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {icon}
                </div>
                <div
                    css={css`
                        white-space: nowrap;
                        font-family: 'Noto Sans Mono Variable';
                    `}
                >
                    {formatDate(date)}
                </div>
                -<div>{title}</div>
            </div>

            <div
                css={css`
                    justify-self: flex-end;
                    font-family: 'Noto Sans Mono Variable';
                `}
            >
                ${amount.toFixed(2)}
            </div>
        </div>
    );
}

const formatDate = (date: Date) => format(date, 'dd-LLL');

export function PropertyBills() {
    const location = useLocation();
    const propertyId = location.state.propertyId;

    return (
        <AuthedLayout showHeader showBack>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        margin-bottom: 1rem;
                        font-weight: bold;
                        font-size: 1.2rem;
                    `}
                >
                    Next 14 days
                </div>
                <BillLineItem
                    icon={<FileCertificate />}
                    amount={302.15}
                    title="Landlord Insurance"
                    date={addDays(new Date(), 3)}
                />
                <Divider
                    css={css`
                        margin-top: 1rem;
                    `}
                />
            </div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        margin-bottom: 1rem;
                        font-weight: bold;
                        font-size: 1.2rem;
                    `}
                >
                    Upcoming
                </div>
                <BillLineItem
                    icon={<BuildingSkyscraper />}
                    amount={101.0}
                    title="Body Coporate Fees"
                    date={addDays(new Date(), 18)}
                />
                <BillLineItem icon={<Droplet />} amount={302.14} title="Water" date={addDays(new Date(), 19)} />
                <BillLineItem
                    icon={<BuildingBank />}
                    amount={1424.82}
                    title="Mortgage"
                    date={addDays(new Date(), 22)}
                />
                <BillLineItem
                    icon={<ReceiptTax />}
                    amount={1024.82}
                    title="Council Rates"
                    date={addDays(new Date(), 37)}
                />
                <Divider
                    css={css`
                        margin-top: 1rem;
                    `}
                />
            </div>
        </AuthedLayout>
    );
}
