import { Title } from '@mantine/core';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetTenantAccount } from 'hooks/owners/useGetTenantAccount';
import { AuthedLayout } from 'layouts/authed';
import { useSearchParams } from 'react-router-dom';
import { moneyFormatter } from 'utils/money-formatter';

export function TenantDetails() {
    const tenantId = useSearchParams()[0].get('tenantId');
    const { data: tenant } = useGetTenantAccount(tenantId ? Number(tenantId) : null);

    return (
        <AuthedLayout showHeader showBack headerText="Tenant details">
            <Title size="h2">{tenant?.display_name}</Title>
            <SummaryPill title="Start date">{tenant?.start_date}</SummaryPill>
            <SummaryPill title="End date">{tenant?.end_date}</SummaryPill>
            <SummaryPill title="Rent amount">
                {moneyFormatter.format((tenant?.monthly_rent_cents ?? 0) / 100)}
            </SummaryPill>
            <SummaryPill title="Bond amount">
                {moneyFormatter.format((tenant?.bond_amount_cents ?? 0) / 100)}
            </SummaryPill>
            <SummaryPill title="Next payment date">-</SummaryPill>
            <SummaryPill title="Overdue fees">$0</SummaryPill>
        </AuthedLayout>
    );
}
