import { css } from '@emotion/react';
import { Group, SegmentedControl, SimpleGrid, Text, useMantineTheme } from '@mantine/core';
import { Chart } from 'chart.js';
import { Metric } from 'components/Metric';
import { AuthedLayout } from 'layouts/authed';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'chart.js/auto';
import { useThemeStore } from 'store/zustand';
// import { ME } from 'pages/SignUp';
import { supabase } from 'utils/supabase';
import { AuthedSplashModal } from 'pages/Splash/authed-modal';

const value = (data: string, colour: string) => (
    <Text
        color={colour}
        css={css`
            font-size: 1.8rem;
        `}
    >
        {data}
    </Text>
);

enum TimePeriod {
    WEEK = 'week',
    MONTH = 'month',
    YTD = 'ytd'
}

const sampleMetrics: Record<TimePeriod, { name: string; value: React.ReactNode }[]> = {
    [TimePeriod.WEEK]: [
        {
            name: 'Rental Yield',
            value: value('$193', 'green.6')
        },
        {
            name: 'Repairs',
            value: value('$12', 'red.6')
        },
        {
            name: 'Savings from using Easy Manage',
            value: value('∞', 'green.6')
        }
    ],
    [TimePeriod.MONTH]: [
        {
            name: 'Rental Income',
            value: value('$2,345', 'green.6')
        },
        {
            name: 'Cash Flow',
            value: value('$1,921', 'yellow.6')
        },
        {
            name: 'EMScore™',
            value: value('9/10', 'green.6')
        },
        {
            name: 'Maintenance Requests',
            value: value('6', 'yellow.6')
        },
        {
            name: 'Pending Issues',
            value: value('0', 'green.6')
        },
        {
            name: 'Market Rate Score',
            value: value('2/5', 'yellow.6')
        },
        {
            name: 'Yield',
            value: value('14%', 'green.6')
        },
        {
            name: 'Repairs',
            value: value('$412', 'red.6')
        },
        {
            name: 'Overall LVR',
            value: value('72%', 'green.6')
        }
    ],
    [TimePeriod.YTD]: [
        {
            name: 'Rental Yield',
            value: value('$84,314', 'green.6')
        },
        {
            name: 'Repairs',
            value: value('$9,123', 'red.6')
        },
        {
            name: 'Savings from using Easy Manage',
            value: value('∞', 'green.6')
        }
    ]
};

const randInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);

const randData = (len: number) => {
    const arr = [100];

    for (let i = 0; i < len; i++) {
        arr.push(randInRange(arr[i] - 250, arr[i] + 1000));
    }

    return arr;
};

export function Dashboard() {
    const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.MONTH);
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const theme = useMantineTheme();
    const emTheme = useThemeStore((state) => state.emTheme);

    useEffect(() => {
        try {
            // @ts-expect-error native
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'set_theme', data: emTheme }));
        } catch (error) {
            /* empty */
        }
    }, [emTheme]);

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        console.log('refd', chartRef.current);

        const chart = new Chart(chartRef.current, {
            type: 'line',
            data: {
                labels: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
                datasets: [
                    {
                        label: 'net position',
                        data: randData(6),
                        tension: 0.4,
                        borderColor: theme.colors.green[5]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false
                        },
                        ticks: {
                            maxTicksLimit: 5
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });

        return () => {
            chart.destroy();
        };
    }, [theme.colors.green]);

    const onClickTimePeriod = (timePeriod: string) => {
        setTimePeriod(timePeriod as TimePeriod);
    };

    const bg = useMemo(() => {
        if (emTheme === 'light') {
            return 'default';
        } else if (emTheme === 'dark') {
            return theme.colors.gray[7];
        } else if (emTheme === 'dark-plus') {
            return theme.fn.gradient({ from: '#2a42bb', to: '#440b5a', deg: 45 });
        }
    }, [emTheme, theme.colors.gray, theme.fn]);

    const greeting = useMemo(() => {
        const hour = new Date().getHours();

        if (hour > 4 && hour < 12) {
            return 'Good morning';
        }

        if (hour >= 12 && hour < 17) {
            return 'Good afternoon';
        }

        return 'Good evening';
    }, []);

    return (
        <AuthedLayout>
            <Group spacing={0} position="center" grow w="100%">
                <SegmentedControl
                    data={[
                        { label: 'Week', value: 'week' },
                        { label: 'Month', value: 'month' },
                        { label: 'YTD', value: 'ytd' }
                    ]}
                    onChange={onClickTimePeriod}
                    value={timePeriod}
                    bg={bg}
                    color="default"
                    styles={{
                        label: {
                            color: emTheme === 'light' ? 'default' : 'white'
                        }
                    }}
                    css={(t) => css`
                        .mantine-SegmentedControl-indicator {
                            background-color: ${t.colors['em-purple'][3]};
                        }
                    `}
                />
                {/* <Button
                        onClick={onClickTimePeriod(TimePeriod.WEEK)}
                        // @ts-expect-error emotion/sx
                        sx={theme => css`
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        background-color: ${timePeriod === TimePeriod.WEEK ? '#2a42bb' : '#440b5a'};
                    `}
                    >
                        Week
                    </Button>
                    <Button
                        onClick={onClickTimePeriod(TimePeriod.MONTH)}
                        // @ts-expect-error emotion/sx
                        sx={theme => css`
                        border-radius: 0;
                        background-color: ${timePeriod === TimePeriod.MONTH ? '#2a42bb' : '#440b5a'};
                    `}
                    >
                        Month
                    </Button>
                    <Button
                        onClick={onClickTimePeriod(TimePeriod.YTD)}
                        // @ts-expect-error emotion/sx
                        sx={theme => css`
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        background-color: ${timePeriod === TimePeriod.YTD ? '#2a42bb' : '#440b5a'};
                    `}
                    >
                        YTD
                    </Button> */}
            </Group>
            <Text align="center">
                {greeting},{' '}
                <span
                    css={css`
                        color: ${theme.colors.pink[4]};
                        font-weight: bold;
                    `}
                >
                </span>
            </Text>
            <SimpleGrid cols={3} spacing="sm" mt="0" w="100%">
                {sampleMetrics[timePeriod].slice(0, 3).map((metric) => (
                    <Metric key={metric.name} name={metric.name}>
                        {metric.value}
                    </Metric>
                ))}
            </SimpleGrid>
            <div
                css={css`
                    height: 10rem;
                    position: relative;
                    margin: 2rem 0;
                    width: 100%;
                `}
            >
                <canvas id="chart" ref={chartRef} />
            </div>
            <SimpleGrid cols={3} spacing="sm" w="100%">
                {sampleMetrics[timePeriod].slice(3).map((metric) => (
                    <Metric key={metric.name} name={metric.name}>
                        {metric.value}
                    </Metric>
                ))}
            </SimpleGrid>
        </AuthedLayout>
    );
}
