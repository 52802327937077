import { Text } from '@mantine/core';
import { LeaseGeneratorForm } from 'components/LeaseGenerator';
import { ExistingPropertyLeaseSchema } from 'components/LeaseGenerator/schema';
import { OnboardingLayout } from 'layouts/Onboarding';
import { FormProvider, useForm } from 'react-hook-form';
import { routes } from 'routes/router';

const { Root, Header, HeaderTitle, Body, Section, Footer, FooterButton, AltButton } = OnboardingLayout;

export function OwnerOnboardingLeaseGen() {
    const formMethods = useForm<ExistingPropertyLeaseSchema>();

    return (
        <Root headerProps={{ children: 'Lease generator', backButton: true }} footerProps={{ atBottom: true }}>
            <Section>
                <Header>
                    <Text>
                        Once the sections below are completed, our AI will generate your lease terms automatically.
                    </Text>
                </Header>
                <Body>
                    <FormProvider {...formMethods}>
                        <LeaseGeneratorForm existingProperty />
                    </FormProvider>
                </Body>
            </Section>
            <Footer>
                <FooterButton dest={routes.ownerOnboarding.leaseSummary}>Generate</FooterButton>
            </Footer>
        </Root>
    );
}
