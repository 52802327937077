import { Flex, TextInput, Text, Stack } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { RadioAlt, RadioAltProps } from 'components/RadioAlt';
import { useState } from 'react';

type Option = 'bank' | 'payid';

interface Props {
    value?: Option;
    onChange?: (selectedOption: Option) => void;
    radioAltProps?: Omit<RadioAltProps, 'options'>;
    showVerificationMessage?: boolean;
}

export function PaymentOptions(props: Props) {
    // use props when hooking it up to to form
    // const { value, onChange } = props;
    const { radioAltProps, showVerificationMessage } = props;

    const [value, onChange] = useState<Option>();

    return (
        <Stack>
            <RadioAlt
                {...radioAltProps}
                radioGroupProps={{
                    onChange: (v) => onChange?.(v as 'bank' | 'payid'),
                    value
                }}
                options={[
                    { value: 'bank', label: 'Bank Account' },
                    { value: 'payid', label: 'PayID' }
                ]}
            />
            {value === 'payid' && <TextInput placeholder="PayID Email Address or Phone Number" />}
            {value === 'bank' && (
                <>
                    <TextInput placeholder="Account holder name" rightSection={<IconUser />} />
                    <Flex gap="md">
                        <TextInput placeholder="BSB" />
                        <TextInput placeholder="Account Number" />
                    </Flex>
                    {showVerificationMessage && (
                        <Text color="dimmed" size="sm">
                            We'll make a small deposit to verify this account. It may take up to 2-3 days.
                        </Text>
                    )}
                </>
            )}
        </Stack>
    );
}
