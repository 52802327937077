import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, PasswordInput, Stack, TextInput, rem, Text } from '@mantine/core';
import { Provider } from '@supabase/supabase-js';
import { useForm } from 'react-hook-form';
import { supabase } from 'utils/supabase';
import { z } from 'zod';
import GoogleIcon from 'assets/google-icon.svg';
import AppleIcon from 'assets/apple-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/router';
import { notifications } from '@mantine/notifications';
import { useThemeStore } from 'store/zustand';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'utils/feature-flags';
import { useState } from 'react';
import { SignUp } from 'components/SignUp';
import { makeRegisters } from 'utils/form-utils';

const schema = z.object({
    email: z.string(),
    password: z.string()
});

type Schema = z.infer<typeof schema>;

const doNativeOAuth = (provider: Provider) => {
    console.log('posting message');

    try {
        // @ts-expect-error native
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'oauth',
                data: {
                    provider
                }
            })
        );
    } catch (error) {
        // alert(error);
    }
};

export function SignInForm() {
    const isNativeApp = window.emConfig.isNative;
    const navigate = useNavigate();
    const setUserId = useThemeStore((s) => s.setCurrentUserId);
    const apple = useFeatureFlagEnabled(FeatureFlag.SignIn.WithApple);
    const onboardingSkipToSignUp = useFeatureFlagEnabled(FeatureFlag.Onboarding.SkipToSignUp);
    const viewFromSearch = useSearchParams()[0].get('view') as 'signup' | 'signin';
    const [view, setView] = useState<'signup' | 'signin'>(viewFromSearch ?? 'signin');

    const formMethods = useForm<Schema>({
        resolver: zodResolver(schema)
    });
    const { handleSubmit, formState } = formMethods;
    const { register } = makeRegisters(formMethods, {
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const onSignIn = async (form: Schema) => {
        if (view === 'signin') {
            const { data, error } = await supabase.auth.signInWithPassword({ ...form });

            if (error) {
                notifications.show({
                    title: 'Error',
                    message: error.message,
                    color: 'red.8'
                });
            } else {
                setUserId(data.user.id);
                navigate(routes.carousel.dashboard);
            }
        }
    };

    const makeOnOauth = (provider: Provider) => () => {
        if (isNativeApp) {
            doNativeOAuth(provider);
        } else {
            supabase.auth.signInWithOAuth({ provider });
        }
    };

    const onChangeView = () => {
        if (!onboardingSkipToSignUp) {
            navigate(routes.root, { state: { fromSignIn: true } });
        } else {
            view === 'signin' ? setView('signup') : setView('signin');
        }
    };

    return (
        <>
            <Button
                mt="xl"
                css={css`
                    border-radius: 8px;
                    background-color: #171221;
                    font-weight: 500;
                    font-size: 0.85rem;
                    height: ${rem(44)};
                    border: 1px solid #3c3c3c;
                `}
                onClick={makeOnOauth('google')}
            >
                <Flex gap="sm" align="center" justify="center" h={rem(44)}>
                    <img
                        src={GoogleIcon}
                        css={css`
                            height: 1.6rem;
                            width: auto;
                        `}
                    />
                    Sign in with Google
                </Flex>
            </Button>
            {apple && (
                <Button
                    css={css`
                        border-radius: 8px;
                        background-color: #171221;
                        font-weight: 500;
                        font-size: 0.85rem;
                        height: ${rem(44)};
                        border: 1px solid #3c3c3c;
                    `}
                    onClick={makeOnOauth('apple')}
                >
                    <Flex gap="sm" align="center" justify="center" h={rem(44)}>
                        <img
                            src={AppleIcon}
                            css={css`
                                height: 1.6rem;
                                width: auto;
                            `}
                        />
                        Sign in with Apple
                    </Flex>
                </Button>
            )}
            <Divider w="100%" />

            {view === 'signin' ? (
                <form
                    onSubmit={handleSubmit(onSignIn)}
                    css={css`
                        width: 100%;
                    `}
                >
                    <Stack w="100%" align="center">
                        <TextInput
                            {...register('email')}
                            type="email"
                            label="Email address"
                            placeholder="you@email.com"
                        />
                        <PasswordInput
                            {...register('password')}
                            label="Password"
                            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        />
                        <Button type="submit" mt="lg" loading={formState.isSubmitting}>
                            {view === 'signin' ? 'Sign in' : 'Sign up'}
                        </Button>
                    </Stack>
                </form>
            ) : (
                <SignUp onSuccess={() => navigate(routes.carousel.dashboard)} />
            )}
            <Text variant="secondary" size="xs" mt="md" onClick={onChangeView}>
                {view === 'signin' ? "Don't have an account?" : 'Already have an account?'}{' '}
                <span
                    css={css`
                        padding-left: 2px;
                        color: #85d0ff;
                    `}
                >
                    {view === 'signin' ? 'Sign up' : 'Sign in'}
                </span>
            </Text>
            {view === 'signin' && (
                <Text variant="secondary" size="xs" mt="sm" onClick={() => navigate(routes.forgotPassword)}>
                    Forgot your password?
                </Text>
            )}
        </>
    );
}
