import { useQuery } from '@tanstack/react-query';
import { useUserId } from 'hooks/useUserId';
import { supabase } from 'utils/supabase';

export function useGetMaintenanceRequests() {
    const userId = useUserId();

    const getRequests = async () => {
        if (!userId) {
            return;
        }

        const { data } = await supabase.from('tenant_maintenance_request').select('*').eq('requester_id', userId);
        return data;
    };

    return useQuery({ queryKey: ['maintenance_requests'], queryFn: getRequests });
}
