import { RootLayout } from 'layouts/root';
import { useNavigate } from 'react-router-dom';
import { Text, Button, useMantineTheme } from '@mantine/core';
import { routes } from 'routes/router';
import { useEffect } from 'react';
import { launchConfetti, sideConfetti } from 'utils/confetti';
// import { useQuery } from '@apollo/client';
// import { ME } from '.';
import { css } from '@emotion/react';

export function SignUpThankyou() {
    const navigate = useNavigate();
    // const { data } = useQuery(ME);
    const theme = useMantineTheme();

    useEffect(() => {
        launchConfetti(70, 400);
        launchConfetti(90, 200);
        launchConfetti(100, 300);
        sideConfetti(1000)
    }, []);

    return (
        <RootLayout>
            <Text align="center">
                Thanks for joining EasyMange{' '}
                <span css={css`color: ${theme.colors.cyan[3]}; font-weight: bold;`}>
                    {/* {data?.me?.firstName} */}
                </span>
            </Text>
            <Text>
                May your managing be easy.
            </Text>
            <Button onClick={() => navigate(routes.carousel.dashboard)}>
                Let's go!
            </Button>
        </RootLayout>
    );
}
