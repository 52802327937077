import { OnboardingLayout } from 'layouts/Onboarding';
import BannerImage from 'assets/owner-onboarding/pikaso_texttoimage_modern-flat-panoramic-shot-of-a-woman-standing-out.jpeg';
import { routes } from 'routes/router';
import { useNavigate } from 'react-router-dom';
import { useSetOnboardingDone } from 'hooks/useSetOnboardingDone';

const { Root, Header, HeaderTitle, HeaderSubtitle, Footer, FooterButton } = OnboardingLayout;

export function OwnerOnboardingDone() {
    const navigate = useNavigate();
    const setOnboardingDone = useSetOnboardingDone();

    const onDone = async () => {
        await setOnboardingDone.mutateAsync(true);
        navigate(routes.carousel.dashboard);
    }

    return (
        <Root bannerImage={<img src={BannerImage} />} headerProps={{ hidden: true }}>
            <Header>
                <HeaderTitle>Congrats, you're all set!</HeaderTitle>
                <HeaderSubtitle>Your property has been added and is now ready to be managed.</HeaderSubtitle>
            </Header>
            <Footer>
                <FooterButton loading={setOnboardingDone.isPending} onClick={onDone}>Start managing</FooterButton>
            </Footer>
        </Root>
    );
}
