import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as Sentry from '@sentry/react';
import '@fontsource-variable/noto-sans-mono';
import '@fontsource-variable/manrope';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

const posthogEnabled = window.location.host !== 'localhost:5173';
console.log({ posthogEnabled });

const options: Partial<PostHogConfig> = {
    autocapture: posthogEnabled,
    disable_session_recording: !posthogEnabled,
    ui_host: 'https://us.i.posthog.com',
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_PROXY
};

if (!window.emConfig) {
    window.emConfig = {
        isNative: false,
        safeAreaInsets: {
            top: 0,
            bottom: 0,
            h: 100
        }
    };
}

if (import.meta.env.VITE_ENV !== 'local') {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost',
                    import.meta.env.VITE_BACKEND_BASE_URL,
                    import.meta.env.VITE_BACKEND_SUBSCRIPTION_BASE_URL
                ]
            }),
            new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <PostHogProvider apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY} options={options}>
            <App />
        </PostHogProvider>
    </React.StrictMode>
);
