import { useMutation } from '@tanstack/react-query';
import { useAccount } from 'hooks/useAccount';
import { useUserId } from 'hooks/useUserId';
import { useNavigate } from 'react-router-dom';
import { supabase } from 'utils/supabase';

interface Args {
    stepSkippedAt: string;
    dest: string;
}

export function useSetOnboardingSkipped() {
    const userId = useUserId();
    const navigate = useNavigate();
    const account = useAccount();

    const setSkipped = async (args: Args) => {
        console.log('[useSetOnboardingSkipped]', { userId });
        if (!userId) {
            return null;
        }

        const { stepSkippedAt, dest } = args;

        const query = await supabase.from('user_updateable').update({
            onboarding_skipped_at: stepSkippedAt
        }).eq('user_id', userId);

        await account.refetch();

        navigate(dest);

        return query.data;
    };

    return useMutation({ mutationFn: setSkipped });
}
