import { Button, Select } from '@mantine/core';
import { supabase } from 'utils/supabase';
import { AuthedLayout } from 'layouts/authed';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/router';
import { isEmTheme, useThemeStore } from 'store/zustand';
import { FeatureFlag } from 'utils/feature-flags';
import { MessageType } from 'utils/webview';
import { TestButton } from 'components/TestButton';
import { css } from '@emotion/react';
import { useSupabaseUser } from 'hooks/useSupabaseUser';
import { MarketingOptInCheckbox } from 'components/MarketingOptIn';

export function Settings() {
    const emTheme = useThemeStore((state) => state.emTheme);
    const setEmTheme = useThemeStore((state) => state.setEmTheme);
    const navigate = useNavigate();
    const [userType, setUserType] = useThemeStore((state) => [state.userType, state.setUserType]);
    const ycUserModeSetting = useFeatureFlagEnabled(FeatureFlag.YC.UserModeSetting);
    const bottom = window.emConfig.safeAreaInsets.bottom;
    const supabaseUserQuery = useSupabaseUser();
    const u = supabaseUserQuery.data?.user;
   
    const handleChange = (val: string | null) => {
        if (!val || !isEmTheme(val)) {
            setEmTheme('light');
            return;
        }

        setEmTheme(val);
    };

    const onLogOut = () => {
        // localStorage.removeItem('token');
        supabase.auth
            .signOut()
            .then((res) => console.log('res', res))
            .catch((err) => console.log('err', err));
    };

    const onRestartOnboarding = () => {
        // navigate(routes.properties.addNewMultiStep, { state: { onboarding: true } });
        navigate(routes.ownerOnboarding.intro);
    };

    const onSwitchMode = () => {
        if (userType === 'owner') {
            setUserType('tenant');
            navigate(routes.tenant.dashboard);
        } else {
            setUserType('owner');
            navigate(routes.carousel.dashboard);
        }
    };

    const resetSplash = () => {
        try {
            // @ts-expect-error native
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: MessageType.DEBUG__removeSignedIn
                })
            );
            alert('done - you must log out and take app out of multitasking for changes to take effect');
        } catch (error) {
            console.log(error);
            alert('error');
        }
    };

    return (
        <AuthedLayout showHeader headerText="Settings">
            <Select
                w="100%"
                label="Theme"
                data={[
                    { value: 'light', label: 'Light' },
                    { value: 'dark', label: 'Dark' },
                    { value: 'dark-plus', label: 'Dark+ (default)' }
                ]}
                value={emTheme}
                onChange={handleChange}
            />

            {ycUserModeSetting && (
                <TestButton onClick={onSwitchMode}>
                    Switch to {userType === 'owner' ? 'tenant' : 'owner'} profile
                </TestButton>
            )}
            <Button onClick={() => navigate(routes.deleteAccount)}>Request account deletion</Button>
           <MarketingOptInCheckbox />
            <Button onClick={onLogOut}>Log Out</Button>
            <TestButton mt="lg" onClick={onRestartOnboarding}>
                Restart landlord onboarding
            </TestButton>
            <TestButton onClick={() => navigate(routes.tenantOnboarding.intro)}>Restart tenant onboarding</TestButton>
            <TestButton onClick={resetSplash}>Reset splash</TestButton>
            {/* <Button onClick={() => navigate(routes.resetPassword)}>
                Change password
            </Button> */}
            <div
                css={(t) => css`
                    text-align: center;
                    font-size: 0.75rem;
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    padding-bottom: calc(0.5rem + ${t.other.bottomNavHeight} - ${bottom}px);
                `}
            >
                <pre>
                    {u?.email}
                    <br />
                    {u?.id}
                    <br />[{import.meta.env.VITE_GIT_SHA}-{import.meta.env.VITE_STAGE}][
                    {window.emConfig.isNative
                        ? `${window.emConfig.device?.nativeAppVersion}-${window.emConfig.device?.nativeBuildVersion}-${window.emConfig.device?.easEnv}`
                        : 'not_native'}
                    ]
                </pre>
            </div>
        </AuthedLayout>
    );
}
