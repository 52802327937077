import { Button, Stack, Title } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { SummaryPill } from 'components/Onboarding/SummaryPill';
import { useGetTenantAccounts } from 'hooks/owners/useGetTenantAccounts';
import { useGetProperty } from 'hooks/useProperty';
import { AuthedLayout } from 'layouts/authed';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/router';

export function ManageTenantsTenants() {
    const { data } = useGetProperty(1);
    const navigate = useNavigate();
    const propertyId = useSearchParams()[0].get('propertyId');
    const { data: tenants } = useGetTenantAccounts(propertyId ? Number(propertyId) : null);

    if (!propertyId) {
        throw new Error('missing prop id');
    }

    return (
        <AuthedLayout showHeader headerText={data?.niceName ?? ''} showBack>
            <Stack>
                <Title size="h3">Tenants</Title>
                {tenants?.map((t) => (
                    <SummaryPill title={t.display_name} onClick={() => navigate({
                        pathname: routes.properties.tenantDetails,
                        search: createSearchParams({ tenantId: String(t.id) }).toString()
                    })}>
                        <IconArrowRight />
                    </SummaryPill>
                ))}
                <Button
                    mt="lg"
                    onClick={() =>
                        navigate({
                            pathname: routes.properties.addTenant,
                            search: createSearchParams({ propertyId }).toString()
                        })
                    }
                >
                    Add tenant
                </Button>
            </Stack>
        </AuthedLayout>
    );
}
