import { RouterError } from 'pages/Error';
import { OwnerOnboarding } from 'pages/OwnerOnboarding';
import { ownerOnboardingSubroutes } from 'pages/OwnerOnboarding/routes';
import { SignInPage } from 'pages/SignIn';
import { createBrowserRouter } from 'react-router-dom';
import { makeFullRoutes } from './util';
import { Splash } from 'pages/Splash';
import { SignUpThankyou } from 'pages/SignUp/thankyou';
import { AppCarousel } from 'pages/AppCarousel';
import { propertiesSubroutes } from 'pages/Properties/routes';
import { carouselRoutes } from 'pages/AppCarousel/routes';
import { Properties } from 'pages/Properties';
import { Chat } from 'pages/Chat';
import { chatSubroutes } from 'pages/Chat/routes';
import { workOrdersSubroutes } from 'pages/WorkOrders/routes';
import { WorkOrders } from 'pages/WorkOrders';
import { tenantSubroutes } from 'pages/Tenant/routes';
import { Tenant } from 'pages/Tenant';
import { TenantOnboarding } from 'pages/Tenant/Onboarding';
import { TenantOnboardingSubroutes } from 'pages/Tenant/Onboarding/routes';
import { AccountNotEnabledPage } from 'pages/AccountNotEnabled';
import { ForgotPasswordPage } from 'pages/ForgotPassword';
import { ResetPasswordPage } from 'pages/ResetPassword';
import { DeleteAccountPage } from 'pages/Settings/delete-account';

const rootRoutes = {
    root: '/',
    signIn: '/sign-in',
    signUp: '/sign-in',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    signUpThankyou: '/sign-up/thankyou',
    properties: '/properties',
    settings: '/settings',
    deleteAccount: '/delete-account',
    carousel: '/carousel',
    tasks: '/tasks',
    workOrders: '/work-orders',
    tenant: '/tenant',
    tenantOnboarding: '/tenant-onboarding',
    notEnabled: '/not-enabled'
} satisfies RouteMap;

export const routes = {
    ...rootRoutes,
    ownerOnboarding: makeFullRoutes('/owner-onboarding', ownerOnboardingSubroutes),
    properties: makeFullRoutes(rootRoutes.properties, propertiesSubroutes),
    carousel: makeFullRoutes(rootRoutes.carousel, carouselRoutes),
    tasks: makeFullRoutes(rootRoutes.tasks, chatSubroutes),
    workOrders: makeFullRoutes(rootRoutes.workOrders, workOrdersSubroutes),
    tenant: makeFullRoutes(rootRoutes.tenant, tenantSubroutes),
    tenantOnboarding: makeFullRoutes(rootRoutes.tenantOnboarding, TenantOnboardingSubroutes)
} satisfies RouteMap;

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Splash />,
        errorElement: <RouterError />
    },
    {
        path: '/sign-in',
        element: <SignInPage />
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />
    },
    // {
    //     path: '/sign-up',
    //     element: <SignUpPage />
    // },
    {
        path: '/sign-up/thankyou',
        element: <SignUpThankyou />
    },
    {
        path: '/owner-onboarding/*',
        element: <OwnerOnboarding />
    },
    {
        path: `${rootRoutes.tenantOnboarding}/*`,
        element: <TenantOnboarding />
    },
    // {
    //     path: rootRoutes.dashboard,
    //     element: <AppCarousel startingRoute={routes.dashboard} />
    // },
    // {
    //     path: rootRoutes.properties,
    //     element: <AppCarousel />
    // },
    // {
    //     path: rootRoutes.tasks,
    //     element: <AppCarousel />
    // },
    // {
    //     path: rootRoutes.alerts,
    //     element: <AppCarousel />
    // },
    // {
    //     path: rootRoutes.settings,
    //     element: <AppCarousel />
    // },
    {
        path: '/carousel/*',
        element: <AppCarousel />
    },
    {
        path: '/properties/*',
        element: <Properties />
    },
    {
        path: `${rootRoutes.tasks}/*`,
        element: <Chat />
    },
    {
        path: `${rootRoutes.workOrders}/*`,
        element: <WorkOrders />
    },
    {
        path: `${rootRoutes.tenant}/*`,
        element: <Tenant />
    },
    {
        path: rootRoutes.notEnabled,
        element: <AccountNotEnabledPage />
    },
    {
        path: rootRoutes.deleteAccount,
        element: <DeleteAccountPage />
    }
]);

export type RouteGenerator = (...args: any[]) => string;

type StringOrRouteGenerator<T = string> = Record<string, string | RouteGenerator | T>;
export type RouteMap = StringOrRouteGenerator<StringOrRouteGenerator>;
