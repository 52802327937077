import { Checkbox } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useAccount } from 'hooks/useAccount';
import { useSetMarketingStatus } from 'hooks/useSetMarketingStatus';

export function MarketingOptInCheckbox() {
    const acc = useAccount();
    const setMarketing = useSetMarketingStatus();

    return (
        <Checkbox
            label="Keep me up to date with updates relating to Easy Manage."
            disabled={!acc.data || setMarketing.isPending || acc.isPending}
            checked={acc.data?.user_updateable?.[0]?.marketing_opt_in}
            onClick={async (e) => {
                await setMarketing.mutateAsync({ optIn: e.currentTarget.checked });
                await acc.refetch();
                notifications.show({
                    title: 'Success!',
                    message: 'Marketing status updated',
                    color: 'green.8'
                });
            }}
        />
    );
}
