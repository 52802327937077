import { RouteMap } from 'routes/router';

export const tenantSubroutes = {
    vetting: 'vetting',
    dashboard: 'dashboard',
    lease: 'lease',
    manualRentPayment: 'manual-rent-payment',
    maintenance: 'maintenance',
    newMaintenance: 'new-maintenance',
    settings: 'settings',
    maintenanceDetailed: 'maintenant-detailed',
    chat: 'chat'
} as const satisfies RouteMap;
