import { css } from '@emotion/react';
import { ChatBubble, Props as ChatBubbleProps } from '.';
import { Button } from '@mantine/core';

type Props = ChatBubbleProps;

export function PaymentSystemBubble(props: Props) {
    const { children, ...rest } = props;

    return (
        <ChatBubble
            {...rest}
            rootCss={css`
                width: 100%;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    border: 2px solid #525dff;
                    border-radius: 8px;
                    padding: 0.5rem 1rem;
                `}
            >
                Payment Request
                <div>{children}</div>
                <div
                    css={css`
                        display: flex;
                        gap: 0.5rem;
                        width: 100%;
                    `}
                >
                    <Button
                        color="green.6"
                        css={css`
                            padding: 0.5rem 1rem;
                            border-radius: 20px;
                            margin-top: 0.5rem;
                            flex: 1;
                        `}
                    >
                        Approve
                    </Button>
                    <Button
                        color="red.6"
                        css={css`
                            padding: 0.5rem 1rem;
                            border-radius: 20px;
                            margin-top: 0.5rem;
                            flex: 1;
                        `}
                    >
                        Decline
                    </Button>
                </div>
            </div>
        </ChatBubble>
    );
}
